
// This constants file is a representation of how data for object dropdown sets will be recieved by the ProHelper database once linked.

// Schedule
import VisitsDropdownSetsData from './dropdown_sets/VisitsDropdownSetsData';

// Work
import RequestDropdownSetsData from './dropdown_sets/RequestDropdownSetsData';
import EstimateDropdownSetsData from './dropdown_sets/EstimateDropdownSetsData';
import TaskDropdownSetsData from './dropdown_sets/TaskDropdownSetsData';
import JobDropdownSetsData from './dropdown_sets/JobDropdownSetsData';
import RouteDropdownSetsData from './dropdown_sets/RouteDropdownSetsData';

// Clients
import AccountDropdownSetsData from './dropdown_sets/AccountDropdownSetsData';
import ContactDropdownSetsData from './dropdown_sets/ContactDropdownSetsData';
import PropertyDropdownSetsData from './dropdown_sets/PropertyDropdownSetsData';

// Audit
import LedgerDropdownSetsData from './dropdown_sets/LedgerDropdownSetsData';
import ReportDropdownSetsData from './dropdown_sets/ReportDropdownSetsData';

// Billing
import InvoiceDropdownSetsData from './dropdown_sets/InvoiceDropdownSetsData';
import PaymentDropdownSetsData from './dropdown_sets/PaymentDropdownSetsData';
import ItemDropdownSetsData from './dropdown_sets/ItemDropdownSetsData';
import SubscriptionDropdownSetsData from './dropdown_sets/SubscriptionDropdownSetsData';
import PurchaseOrderDropdownSetsData from './dropdown_sets/PurchaseOrderDropdownSetsData';

// Team
import UserDropdownSetsData from './dropdown_sets/UserDropdownSetsData';
import VehicleDropdownSetsData from './dropdown_sets/VehicleDropdownSetsData';

// Security
import RoleDropdownSetsData from './dropdown_sets/RoleDropdownSetsData';
import PermissionSetDropdownSetsData from './dropdown_sets/PermissionSetDropdownSetsData';

// Other
import LineItemDropdownSetsData from './dropdown_sets/LineItemDropdownSetsData';

// Global
import GlobalDropdownSetsData from './dropdown_sets/GlobalDropdownSetsData';
import StaticDropdownSetsData from './dropdown_sets/StaticDropdownSetsData';
import NoteDropdownSetsData from './dropdown_sets/NoteDropdownSetsData';

// Add (concact) all the individual object dropdown sets data into one big array
const DropdownSetsData = [].concat(
    VisitsDropdownSetsData,
    RequestDropdownSetsData,
    EstimateDropdownSetsData,
    TaskDropdownSetsData,
    JobDropdownSetsData,
    RouteDropdownSetsData,
    AccountDropdownSetsData, 
    ContactDropdownSetsData, 
    PropertyDropdownSetsData,
    LedgerDropdownSetsData,
    ReportDropdownSetsData,
    InvoiceDropdownSetsData,
    PaymentDropdownSetsData,
    SubscriptionDropdownSetsData,
    ItemDropdownSetsData,
    PurchaseOrderDropdownSetsData,
    UserDropdownSetsData,
    VehicleDropdownSetsData,
    RoleDropdownSetsData,
    PermissionSetDropdownSetsData,
    LineItemDropdownSetsData,
    GlobalDropdownSetsData, 
    StaticDropdownSetsData,
    NoteDropdownSetsData
);

export default DropdownSetsData;

// Import Modules
import uniqid from 'uniqid';
import { identifier } from 'safe-identifier';

// Import Custom Modules
import * as SortingMethods from '../../sorting/SortingMethods';
import * as SortingDirectionIdentifiers from '../../sorting/SortDataTypeIdentifiers';


const PurchaseOrderListViewsData = [
    // All Purchase Orders
    {
        object: null,
        object_identifier: 'purchase_orders',
        public_id: uniqid('view_'),
        sync_token: 1,
        type: "list_view",
        identifier: identifier('All Purchase Orders').toLowerCase(),
        name: 'All Purchase Orders',
        description: 'All purchase orders with the company.',
        sort_order: 1,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        read_access: "all_users",
        edit_access: "private",
        created_at: new Date(),
        created_by: null,
        last_updated_at: new Date(),
        last_updated_by: null,
        last_viewed_at: new Date(),
        last_viewed_by: null,
        sort_field: "purchase_orders.subject",
        sql_sort_field: ["purchase_orders.subject"],
        sort_direction: SortingDirectionIdentifiers.ASCENDING,
        sort_join: null,
        sort_method: SortingMethods.ALPHANUMERIC,
        filter_by_viewer: false,
        filters: [ ],
        search_value: "",
        visible_fields: [
            {
                label: 'Subject',
                identifier: 'purchase_orders.subject',
                display_fields: ['purchase_orders.subject'],
                display_format: null,
                width: 180,
                is_locked: true
            },
            {
                label: 'Type',
                identifier: 'purchase_orders.type',
                display_fields: ['purchase_orders.type', 'object_types.label'],
                display_format: null,
                width: 140,
                is_locked: false
            },
            {
                label: 'Status',
                identifier: 'purchase_orders.status',
                display_fields: ['purchase_orders.status', 'status.label'],
                display_format: null,
                width: 180,
                is_locked: false
            },
            {
                label: 'Total',
                identifier: 'purchase_orders.total',
                display_fields: ['purchase_orders.total'],
                display_format: null,
                width: 130,
                is_locked: false
            },
            {
                label: 'Vendor',
                identifier: 'purchase_orders.vendor',
                display_fields: ['purchase_orders.vendor', 'accounts.name', 'name.display'],
                display_format: null,
                width: 180,
                is_locked: false
            },
            {
                label: 'Issued Date',
                identifier: 'purchase_orders.issued_date',
                display_fields: ['purchase_orders.issued_date'],
                display_format: null,
                width: 180,
                is_locked: false
            },
            
        ],
        hidden_fields: [
            "purchase_orders.id",
            "purchase_orders.purchase_order_id",
            "purchase_orders.type",
            "purchase_orders.sync_token",
            "purchase_orders.company",
            "purchase_orders.suggested_action"
        ],
        batch_actions: [
            "purchase_orders.batch_edit",
            "purchase_orders.batch_archive"
        ],
        item_actions: [
            "purchase_orders.archive"
        ],
        more_actions: [
            "purchase_orders.delete"
        ],
        pagination_amount: 25
    },
    
];

export default PurchaseOrderListViewsData;
// This constants file is a representation of how data for invoice fields will be recieved by the ProHelper database once linked.
import uniqid from 'uniqid';
import * as FieldTypes from '../../types/FieldTypes';
import * as DatabaseTypes from '../../types/DatabaseTypes';
import * as SpecialTypes from '../../types/SpecialTypes';
import * as FilterOperatorTypeIdenifiers from '../../filtering/operators/FilterOperatorTypeIdentifiers';
import * as LookupRelationshipTypes from '../../types/LookupRelationshipTypes';
import * as SortingMethods from '../../sorting/SortingMethods';

const InvoiceFieldsData = [
    // Database ID
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Database ID",
        identifier: "id",
        field_type: FieldTypes.ID,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "The ID of this Invoice in the ProHelper Database.",
        help_text: "The ID of this Invoice in the ProHelper Database.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: true,
        is_editable: false,
        is_searchable: false,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Company
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Company",
        identifier: "company",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "The organization in ProHelper that this Invoice is apart of.",
        help_text: "The organization for this Invoice in ProHelper.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: true,
        is_editable: false,
        is_searchable: false,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Subject
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Subject",
        identifier: "subject",
        field_type: FieldTypes.LONG_TEXT,
        data_type: DatabaseTypes.TEXT,
        special_type: SpecialTypes.MAIN_LABEL,
        description: "The primary subject/label for a Invoice.",
        help_text: "The primary subject/label of a Invoice.",
        default_value: null,
        placeholder_text: 'Enter a Invoice subject/title...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 1,
        is_required: true,
        is_editable: true,
        is_searchable: true,
        search_priority: 'A',
        is_filterable: false,
        filter_order: null,
        is_sortable: true,
        sort_order: 1,
        sort_method: SortingMethods.ALPHANUMERIC,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Description
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Description",
        identifier: "description",
        field_type: FieldTypes.LONG_TEXT,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        description: "A description for the Invoice.",
        help_text: "Information about this Invoice.",
        default_value: null,
        placeholder_text: 'Add a Invoice description...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 2,
        is_required: false,
        is_editable: true,
        is_searchable: true,
        search_priority: 'B',
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: 2,
        sort_method: SortingMethods.ALPHANUMERIC,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Type
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Type",
        identifier: "type",
        field_type: FieldTypes.TYPE_DROPDOWN,
        data_type: DatabaseTypes.BIGINT,
        special_type: SpecialTypes.MAIN_TYPE,
        description: "Represents the object type of this Invoice.",
        help_text: "A custom type to categorize an Invoice.",
        default_value: null,
        placeholder_text: 'Select a Invoice Type...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 3,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 1,
        is_sortable: true,
        sort_order: 3,
        sort_method: SortingMethods.CUSTOM_SORT_ORDER,
        is_multi: false,
        is_async: true,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Due Date
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Due Date",
        identifier: "due_date",
        field_type: FieldTypes.DATE,
        data_type: DatabaseTypes.TIMESTAMPTZ,
        special_type: null,
        description: "The date when the Invoice should be completed by.",
        help_text: "The date when the Invoice should be completed by.",
        default_value: null,
        placeholder_text: 'Select a Due Date...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 4,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 2,
        is_sortable: true,
        sort_order: 4,
        sort_method: SortingMethods.CHRONOLOGICAL,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Issued Date
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Issued Date",
        identifier: "issued_date",
        field_type: FieldTypes.DATE,
        data_type: DatabaseTypes.TIMESTAMPTZ,
        special_type: null,
        description: "The date an Invoice was issued. May be edited.",
        help_text: "The date an Invoice was issued. May be edited.",
        default_value: null,
        placeholder_text: 'Select a Issued Date...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 5,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 3,
        is_sortable: true,
        sort_order: 5,
        sort_method: SortingMethods.CHRONOLOGICAL,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Account
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Account",
        identifier: "account",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Reference to the client account that is linked to this Invoice.",
        help_text: "The account related to this Invoice.",
        default_value: null,
        placeholder_text: 'Select an Account...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 6,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 4,
        is_sortable: true,
        sort_order: 6,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: LookupRelationshipTypes.INDEPENDENT,
            object_identifier: "accounts",
            filters: [
                {
                    fields: ["accounts.is_active"],
                    operator: "equal",
                    values: [
                        {
                            type: "value",
                            label: 'True',
                            input: true,
                            data: true,
                            data_type: 'boolean',
                            status: 'valid'
                        }
                    ]
                },
                {
                    fields: ["accounts.type"],
                    operator: "equal",
                    values: [
                        {
                            type: "value",
                            label: 'Customer',
                            input: 'customer',
                            data: null,
                            data_type: 'bigint',
                            status: 'valid'
                        }
                    ]
                },
            ],
            display_field: "name",
            strict_filter_lookups: false,
            is_related_record_editable: false,
            is_related_record_creatable: false
        },
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Primary Contact
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Primary Contact",
        identifier: "primary_contact",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Reference to the primary contact to reach out to in relation to the invoice.",
        help_text: "Reference to the primary contact to reach out to in relation to the invoice.",
        default_value: null,
        placeholder_text: 'Select a Primary Contact...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 7,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 5,
        is_sortable: true,
        sort_order: 7,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "contacts",
            filters: [
                {
                    fields: ["contacts.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            label: "True",
                            type: "value",
                            data: true,
                            data_type: 'boolean',
                            field_type: "checkbox",
                            input: true
                        }
                    ]
                }
            ],
            display_field: "name",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Primary Property
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Primary Property",
        identifier: "primary_property",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Reference to the primary Property for the Invoice.",
        help_text: "Reference to the primary Property for the Invoice.",
        default_value: null,
        placeholder_text: 'Select a Property...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 8,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 6,
        is_sortable: true,
        sort_order: 8,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "properties",
            filters: [
                {
                    fields: ["properties.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            label: "True",
                            type: "value",
                            data: true,
                            data_type: 'boolean',
                            field_type: "checkbox",
                            input: true
                        }
                    ]
                }
            ],
            display_field: "address",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Billing Contact
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Billing Contact",
        identifier: "billing_contact",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Reference to the billing contact to reach out to in relation to the invoice.",
        help_text: "Reference to the billing contact to reach out to in relation to the invoice.",
        default_value: null,
        placeholder_text: 'Select a Contact for Billing...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 9,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 7,
        is_sortable: true,
        sort_order: 9,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "contacts",
            filters: [
                {
                    fields: ["contacts.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            label: "True",
                            type: "value",
                            data: true,
                            data_type: 'boolean',
                            field_type: "checkbox",
                            input: true
                        }
                    ]
                }
            ],
            display_field: "name",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Billing Property
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Billing Property",
        identifier: "billing_property",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Reference to the billing property for the Invoice.",
        help_text: "Reference to the billing property for the Invoice.",
        default_value: null,
        placeholder_text: 'Select a Property for Billing...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 10,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 8,
        is_sortable: true,
        sort_order: 10,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "properties",
            filters: [
                {
                    fields: ["properties.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            label: "True",
                            type: "value",
                            data: true,
                            data_type: 'boolean',
                            field_type: "checkbox",
                            input: true
                        }
                    ]
                }
            ],
            display_field: "address",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Service Contact
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Service Contact",
        identifier: "service_contact",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Reference to the service contact to reach out to in relation to the invoice.",
        help_text: "Reference to the service contact to reach out to in relation to the invoice.",
        default_value: null,
        placeholder_text: 'Select a Contact for Billing...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 11,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 9,
        is_sortable: true,
        sort_order: 11,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "contacts",
            filters: [
                {
                    fields: ["contacts.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            label: "True",
                            type: "value",
                            data: true,
                            data_type: 'boolean',
                            field_type: "checkbox",
                            input: true
                        }
                    ]
                }
            ],
            display_field: "name",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Service Property
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Service Property",
        identifier: "service_property",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Reference to the service property for the Invoice.",
        help_text: "Reference to the service property for the Invoice.",
        default_value: null,
        placeholder_text: 'Select a Property for Service...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 12,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 10,
        is_sortable: true,
        sort_order: 12,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "properties",
            filters: [
                {
                    fields: ["properties.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            label: "True",
                            type: "value",
                            data: true,
                            data_type: 'boolean',
                            field_type: "checkbox",
                            input: true
                        }
                    ]
                }
            ],
            display_field: "address",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Status
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Status",
        identifier: "status",
        field_type: FieldTypes.CUSTOM_DROPDOWN,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "The status indicates its current step/stage in the invoice process; for tracking of progress.",
        help_text: "The status indicates its current step/stage in the invoice process; for tracking of progress.",
        default_value: null,
        placeholder_text: 'Select a Invoice Status...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 13,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 11,
        is_sortable: true,
        sort_order: 13,
        sort_method: SortingMethods.CUSTOM_SORT_ORDER,
        is_multi: false,
        is_async: true,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: {
            identifier: "invoices.statuses",
            object_identifier: "invoices",
            type: "custom_dropdown"
        },
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Tags
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Tags",
        identifier: "tags",
        field_type: FieldTypes.CUSTOM_DROPDOWN,
        data_type: DatabaseTypes.TEXT_ARRAY,
        special_type: null,
        description: "Label(s) attached to a Invoice for extra identification.",
        help_text: "Tag(s) are labels attached to a Invoice for extra identification.",
        default_value: null,
        placeholder_text: 'Add some Invoice Tags...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 14,
        is_required: false,
        is_editable: true,
        is_searchable: true,
        search_priority: 'A',
        is_filterable: true,
        filter_order: 12,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: true,
        is_async: true,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: {
            identifier: "invoices.tags",
            object_identifier: "invoices",
            type: "text_dropdown"
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Invoice ID
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Invoice ID",
        identifier: "invoice_id",
        field_type: FieldTypes.PUBLIC_ID,
        data_type: DatabaseTypes.TEXT,
        special_type: SpecialTypes.PUBLIC_ID,
        description: "The unique ID for a Invoice shown to publically to invoices. Invoice Prefix is 'invoice_'.",
        help_text: "The unique ID for a Invoice shown to publically to invoices.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 15,
        is_required: true,
        is_editable: false,
        is_searchable: true,
        search_priority: 'A',
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Invoice Number
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Invoice Number",
        identifier: "invoice_number",
        field_type: FieldTypes.INTEGER,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        description: "A sequentially generated integer representing the number of Invoices this organization has created.",
        help_text: "A sequentially generated integer representing the number of Invoices this organization has created.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 16,
        is_required: true,
        is_editable: false,
        is_searchable: true,
        is_filterable: true,
        filter_order: 13,
        is_sortable: true,
        sort_order: 14,
        sort_method: SortingMethods.NUMERIC,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: 'A',
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Invoicer
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Invoicer",
        identifier: "invoicer",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "The employee responsible for managing the invoice and performing it.",
        help_text: "The employee responsible for managing the invoice and performing it.",
        default_value: null,
        placeholder_text: 'Enter an employee conducting this invoice...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 17,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 14,
        is_sortable: true,
        sort_order: 15,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Extra Information",
            identifier: "extra_information",
            order: 2
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: LookupRelationshipTypes.INDEPENDENT,
            object_identifier: "users",
            filters: [
                {
                    fields: ["users.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            type: "value",
                            label: 'True',
                            input: true,
                            data: true,
                            data_type: 'boolean',
                            status: 'valid'
                        }
                    ]
                }
            ],
            display_field: "name",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true
        },
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Request
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Request",
        identifier: "request",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "The request that the Invoice was converted from originally.",
        help_text: "The request that this Invoice was converted from originally.",
        default_value: null,
        placeholder_text: 'Enter a Request that this Invoice originated from...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 18,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 15,
        is_sortable: true,
        sort_order: 16,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Extra Information",
            identifier: "extra_information",
            order: 2
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "requests",
            filters: [
                {
                    fields: ["requests.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            label: "True",
                            type: "value",
                            data: true,
                            data_type: 'boolean',
                            field_type: "checkbox",
                            input: true
                        }
                    ]
                }
            ],
            display_field: "subject",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Estimate 
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Estimate",
        identifier: "estimate",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "The Estimate that the Invoice was converted from originally.",
        help_text: "The Estimate that this Invoice was converted from originally.",
        default_value: null,
        placeholder_text: 'Enter a Estimate that this Invoice originated from...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 19,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 16,
        is_sortable: true,
        sort_order: 17,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Extra Information",
            identifier: "extra_information",
            order: 2
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "estimates",
            filters: [
                {
                    fields: ["estimates.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            label: "True",
                            type: "value",
                            data: true,
                            data_type: 'boolean',
                            field_type: "checkbox",
                            input: true
                        }
                    ]
                }
            ],
            display_field: "subject",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Jobs
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Jobs",
        identifier: "jobs",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "The Jobs that this Invoice is connected to.",
        help_text: "The Jobs that this Invoice is connected to.",
        default_value: null,
        placeholder_text: 'Enter Jobs connected to this Invoice...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 20,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 17,
        is_sortable: true,
        sort_order: 18,
        sort_method: null,
        is_multi: true,
        is_async: true,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Extra Information",
            identifier: "extra_information",
            order: 2
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "jobs",
            filters: [
                {
                    fields: ["jobs.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            label: "True",
                            type: "value",
                            data: true,
                            data_type: 'boolean',
                            field_type: "checkbox",
                            input: true
                        }
                    ]
                }
            ],
            display_field: "subject",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Subscription
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Subscription",
        identifier: "subscription",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "A subscription that is linked to the Invoice and will become active if the Invoice is accepted.",
        help_text: "A subscription that is linked to the Invoice and will become active if the Invoice is accepted.",
        default_value: null,
        placeholder_text: 'Link a Subscription to this Invoice...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 21,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 18,
        is_sortable: true,
        sort_order: 19,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Extra Information",
            identifier: "extra_information",
            order: 2
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "subscriptions",
            filters: [
                {
                    fields: ["subscriptions.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            label: "True",
                            type: "value",
                            data: true,
                            data_type: 'boolean',
                            field_type: "checkbox",
                            input: true
                        }
                    ]
                }
            ],
            display_field: "subject",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Footer Message
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Footer Message",
        identifier: "footer_message",
        field_type: FieldTypes.LONG_TEXT,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        description: "A short message to include in the Footer in your Invoices when printed or emailed with ProHelper.",
        help_text: "A short message to include in the Footer in your Invoices when printed or emailed with ProHelper.",
        default_value: null,
        placeholder_text: 'Enter a footer message on the invoice...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 22,
        is_required: false,
        is_editable: true,
        is_searchable: true,
        search_priority: 'D',
        is_filterable: false,
        filter_order: null,
        is_sortable: true,
        sort_order: 20,
        sort_method: SortingMethods.ALPHANUMERIC,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Extra Information",
            identifier: "extra_information",
            order: 2
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Customer Message
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Customer Message",
        identifier: "customer_message",
        field_type: FieldTypes.LONG_TEXT,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        description: "Customer entered message to show to employees before starting the invoice. Publically visible.",
        help_text: "Customer entered message to show to employees before starting the invoice. Publically visible.",
        default_value: null,
        placeholder_text: "Enter the customer's message...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 23,
        is_required: false,
        is_editable: true,
        is_searchable: true,
        search_priority: 'B',
        is_filterable: false,
        filter_order: null,
        is_sortable: true,
        sort_order: 21,
        sort_method: SortingMethods.ALPHANUMERIC,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Extra Information",
            identifier: "extra_information",
            order: 2
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Customer Response
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Customer Response",
        identifier: "customer_response",
        field_type: FieldTypes.LONG_TEXT,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        description: "Client entered response/feedback to show to employees. Publically visible.",
        help_text: "Client entered response/feedback to show to employees . Publically visible.",
        default_value: null,
        placeholder_text: "Enter the customer's response...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 24,
        is_required: false,
        is_editable: true,
        is_searchable: true,
        search_priority: 'B',
        is_filterable: false,
        filter_order: null,
        is_sortable: true,
        sort_order: 22,
        sort_method: SortingMethods.ALPHANUMERIC,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Extra Information",
            identifier: "extra_information",
            order: 2
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Revenue Ledger
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Revenue Ledger",
        identifier: "revenue_ledger",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Reference to the accounting ledger for the revenue from the invoice.",
        help_text: "Reference to the accounting ledger for the revenue from the invoice.",
        default_value: null,
        placeholder_text: 'Select an Accounting Ledger for Revenue...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 25,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 19,
        is_sortable: true,
        sort_order: 23,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Financial Information",
            identifier: "financial_information",
            order: 3
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "ledgers",
            filters: [
                {
                    fields: ["ledgers.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            label: "True",
                            type: "value",
                            data: true,
                            data_type: 'boolean',
                            field_type: "checkbox",
                            input: true
                        }
                    ]
                }
            ],
            display_field: "title",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Expense Ledger
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Expense Ledger",
        identifier: "expense_ledger",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Reference to the accounting ledger for the expenses from the invoice.",
        help_text: "Reference to the accounting ledger for the expenses from the invoice.",
        default_value: null,
        placeholder_text: 'Select an Accounting Ledger for Expenses...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 26,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 20,
        is_sortable: true,
        sort_order: 24,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Financial Information",
            identifier: "financial_information",
            order: 3
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "ledgers",
            filters: [
                {
                    fields: ["ledgers.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            label: "True",
                            type: "value",
                            data: true,
                            data_type: 'boolean',
                            field_type: "checkbox",
                            input: true
                        }
                    ]
                }
            ],
            display_field: "title",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Billing Reason
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Billing Reason",
        identifier: "billing_reason",
        field_type: FieldTypes.CUSTOM_DROPDOWN,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "The reason why the invoice was created.",
        help_text: "The reason why the invoice was created.",
        default_value: null,
        placeholder_text: 'Enter a Payment Method...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 27,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 21,
        is_sortable: true,
        sort_order: 25,
        sort_method: SortingMethods.CUSTOM_SORT_ORDER,
        is_multi: false,
        is_async: true,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Financial Information",
            identifier: "financial_information",
            order: 3
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: {
            identifier: "invoices.billing_reasons",
            object_identifier: "invoices",
            type: "custom_dropdown"
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Payment Method
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Payment Method",
        identifier: "payment_method",
        field_type: FieldTypes.CUSTOM_DROPDOWN,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "The customer selected choice of payment for the Invoice.",
        help_text: "The customer selected choice of payment for this Invoice.",
        default_value: null,
        placeholder_text: 'Enter a Payment Method...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 28,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 22,
        is_sortable: true,
        sort_order: 26,
        sort_method: SortingMethods.CUSTOM_SORT_ORDER,
        is_multi: false,
        is_async: true,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Financial Information",
            identifier: "financial_information",
            order: 3
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: {
            identifier: "global.payment_methods",
            object_identifier: "global",
            type: "custom_dropdown"
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Send Method
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Send Method",
        identifier: "send_method",
        field_type: FieldTypes.CUSTOM_DROPDOWN,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "The customer selected choice of sending the Invoice for the potential Job.",
        help_text: "The customer selected choice of sending the Invoice for the potential Job.",
        default_value: null,
        placeholder_text: 'Enter a Send Method...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 29,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 23,
        is_sortable: true,
        sort_order: 27,
        sort_method: SortingMethods.CUSTOM_SORT_ORDER,
        is_multi: false,
        is_async: true,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Financial Information",
            identifier: "financial_information",
            order: 3
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: {
            identifier: "invoices.send_methods",
            object_identifier: "invoices",
            type: "custom_dropdown"
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Auto Advance
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Auto Advance",
        identifier: "auto_advance",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "Controls whether ProHelper will perform automatic collection of the invoice.",
        help_text: "Controls whether ProHelper will perform automatic collection of the invoice.",
        default_value: {
            data_type: DatabaseTypes.BOOLEAN,
            data: false
        },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 30,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        is_filterable: true,
        filter_order: 24,
        is_sortable: true,
        sort_order: 28,
        sort_method: SortingMethods.BOOLEAN,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Financial Information",
            identifier: "financial_information",
            order: 3
        },
        checkbox_labels: { true: "Auto Advance", false: 'Manual Advance' },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Payment Source
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Payment Source",
        identifier: "payment_source",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "The payment source for this Invoice.",
        help_text: "The payment source for this Invoice.",
        default_value: null,
        placeholder_text: 'Enter a Payment Source...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 31,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 25,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Financial Information",
            identifier: "financial_information",
            order: 3
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "payment_sources",
            filters: [
                {
                    fields: ["payment_sources.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            label: "True",
                            type: "value",
                            data: true,
                            data_type: 'boolean',
                            field_type: "checkbox",
                            input: true
                        }
                    ]
                }
            ],
            display_field: "title",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Tax ID
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Tax ID",
        identifier: "tax_id",
        field_type: FieldTypes.ENCRYPTED_STRING,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        description: "A Tax Identification Number to be used in tax reports. Encyrpted in the ProHelper Database.",
        help_text: "The Tax ID for an Invoice. Encrypted until access requested.",
        default_value: null,
        placeholder_text: "Enter a tax id... (Encrypted)",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 32,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: true,
        is_sensitive: true,
        group: {
            label: "Financial Information",
            identifier: "financial_information",
            order: 3
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Period Start
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Period Start",
        identifier: "period_start",
        field_type: FieldTypes.DATE,
        data_type: DatabaseTypes.TIMESTAMPTZ,
        special_type: null,
        description: "Start of the usage period during which invoice items were added to this invoice.",
        help_text: "Start of the usage period during which invoice items were added to this invoice.",
        default_value: null,
        placeholder_text: 'Select a Period Start Date...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 33,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 26,
        is_sortable: true,
        sort_order: 29,
        sort_method: SortingMethods.CHRONOLOGICAL,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Financial Information",
            identifier: "financial_information",
            order: 3
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Period End
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Period End",
        identifier: "period_end",
        field_type: FieldTypes.DATE,
        data_type: DatabaseTypes.TIMESTAMPTZ,
        special_type: null,
        description: "End of the usage period during which invoice items were added to this invoice.",
        help_text: "End of the usage period during which invoice items were added to this invoice.",
        default_value: null,
        placeholder_text: 'Select a Period End Date...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 34,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 27,
        is_sortable: true,
        sort_order: 30,
        sort_method: SortingMethods.CHRONOLOGICAL,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Financial Information",
            identifier: "financial_information",
            order: 3
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Deposit
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Deposit",
        identifier: "deposit",
        field_type: FieldTypes.CURRENCY,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "A currency amount representing the deposit total for the customer to pay to start the Invoice.",
        help_text: "A currency amount representing the deposit total for the customer to pay to start the Invoice.",
        default_value: null,
        placeholder_text: 'Enter a deposit amount...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 35,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        is_filterable: true,
        filter_order: 28,
        is_sortable: true,
        sort_order: 31,
        sort_method: SortingMethods.NUMERIC,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Financial Information",
            identifier: "financial_information",
            order: 3
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Sub Total
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Sub Total",
        identifier: "subtotal",
        field_type: FieldTypes.CURRENCY,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Total of all subscriptions, invoice items, and prorations on the invoice before any invoice level discount or exclusive tax is applied. Item discounts are already incorporated",
        help_text: "Total of all subscriptions, invoice items, and prorations on the invoice before any invoice level discount or exclusive tax is applied. Item discounts are already incorporated",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 36,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        is_filterable: true,
        filter_order: 29,
        is_sortable: true,
        sort_order: 32,
        sort_method: SortingMethods.NUMERIC,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Financial Information",
            identifier: "financial_information",
            order: 3
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Sub Total Excluding Tax
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Sub Total Excluding Tax",
        identifier: "subtotal_excluding_tax",
        field_type: FieldTypes.CURRENCY,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "The integer amount in cents representing the subtotal of the invoice before any invoice level discount or tax is applied. Item discounts are already incorporated",
        help_text: "The integer amount in cents representing the subtotal of the invoice before any invoice level discount or tax is applied. Item discounts are already incorporated",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 37,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        is_filterable: true,
        filter_order: 30,
        is_sortable: true,
        sort_order: 33,
        sort_method: SortingMethods.NUMERIC,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Financial Information",
            identifier: "financial_information",
            order: 3
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Discounts
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Discounts",
        identifier: "discounts",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT_ARRAY,
        special_type: null,
        description: "Reference to the discounts for the invoice.",
        help_text: "Reference to the discounts for the invoice.",
        default_value: null,
        placeholder_text: 'Select Discounts...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 38,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 31,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: true,
        is_async: true,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Financial Information",
            identifier: "financial_information",
            order: 3
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "discounts",
            filters: [
                {
                    fields: ["discounts.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            label: "True",
                            type: "value",
                            data: true,
                            data_type: 'boolean',
                            field_type: "checkbox",
                            input: true
                        }
                    ]
                }
            ],
            display_field: "title",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Discount Amount
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Discount Amount",
        identifier: "discount_amount",
        field_type: FieldTypes.CURRENCY,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Represents the discount amount for an invoice.",
        help_text: "Represents the discount amount for an invoice.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 39,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        is_filterable: true,
        filter_order: 32,
        is_sortable: true,
        sort_order: 34,
        sort_method: SortingMethods.NUMERIC,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Financial Information",
            identifier: "financial_information",
            order: 3
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Is Taxable
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Is Taxable",
        identifier: "is_taxable",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "If true, this Invoice is taxable and uses the tax rates data.",
        help_text: "If true, this Invoice is taxable and uses the tax rates data.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 40,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        is_filterable: true,
        filter_order: 33,
        is_sortable: true,
        sort_order: 35,
        sort_method: SortingMethods.BOOLEAN,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Financial Information",
            identifier: "financial_information",
            order: 3
        },
        checkbox_labels: { true: "Taxable", false: 'No Taxable' },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Is Tax Applied After Discount
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Is Tax Applied After Discount",
        identifier: "is_tax_applied_after_discount",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "If true, the tax rates are applied after discounts are applied to the subtotal.",
        help_text: "If true, the tax rates are applied after discounts are applied to the subtotal.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 41,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        is_filterable: true,
        filter_order: 34,
        is_sortable: true,
        sort_order: 36,
        sort_method: SortingMethods.BOOLEAN,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Financial Information",
            identifier: "financial_information",
            order: 3
        },
        checkbox_labels: { true: "Tax applied after discounts", false: 'Tax applied before discounts' },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Tax Rates
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Tax Rates",
        identifier: "tax_rates",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT_ARRAY,
        special_type: null,
        description: "Reference to the tax rates for the invoice.",
        help_text: "Reference to the tax rates for the invoice.",
        default_value: null,
        placeholder_text: 'Select Tax Rates...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 42,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 35,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: true,
        is_async: true,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Financial Information",
            identifier: "financial_information",
            order: 3
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "tax_rates",
            filters: [
                {
                    fields: ["tax_rates.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            label: "True",
                            type: "value",
                            data: true,
                            data_type: 'boolean',
                            field_type: "checkbox",
                            input: true
                        }
                    ]
                }
            ],
            display_field: "title",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Tax Amount
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Tax Amount",
        identifier: "tax_amount",
        field_type: FieldTypes.CURRENCY,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "The total tax amount for a Invoice represents as a currency unit.",
        help_text: "The total tax amount for a Invoice represents as a currency unit.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 43,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        is_filterable: true,
        filter_order: 36,
        is_sortable: true,
        sort_order: 37,
        sort_method: SortingMethods.NUMERIC,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Financial Information",
            identifier: "financial_information",
            order: 3
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Total Excluding Tax
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Total Excluding Tax",
        identifier: "total_excluding_tax",
        field_type: FieldTypes.CURRENCY,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "The total sum of the line items for this invoice excluding taxes.",
        help_text: "The total sum of the line items for this invoice excluding taxes.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 44,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        is_filterable: true,
        filter_order: 37,
        is_sortable: true,
        sort_order: 38,
        sort_method: SortingMethods.NUMERIC,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Financial Information",
            identifier: "financial_information",
            order: 3
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Total
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Total",
        identifier: "total",
        field_type: FieldTypes.CURRENCY,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "The total sum of the line items for this invoice after tax and discounts are applied.",
        help_text: "The total sum of the line items for this invoice after tax and discounts are applied.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 45,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        is_filterable: true,
        filter_order: 38,
        is_sortable: true,
        sort_order: 39,
        sort_method: SortingMethods.NUMERIC,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Financial Information",
            identifier: "financial_information",
            order: 3
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Amount Due
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Amount Due",
        identifier: "amount_due",
        field_type: FieldTypes.CURRENCY,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Final amount due at this time for this invoice. If the invoice’s total is smaller than the minimum charge amount, for example, or if there is account credit that can be applied to the invoice, the amount_due may be 0. If there is a positive starting_balance for the invoice (the customer owes money), the amount_due will also take that into account. The charge that gets generated for the invoice will be for the amount specified in amount_due.",
        help_text: "Final amount due at this time for this invoice. If the invoice’s total is smaller than the minimum charge amount, for example, or if there is account credit that can be applied to the invoice, the amount_due may be 0. If there is a positive starting_balance for the invoice (the customer owes money), the amount_due will also take that into account. The charge that gets generated for the invoice will be for the amount specified in amount_due.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 46,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        is_filterable: true,
        filter_order: 39,
        is_sortable: true,
        sort_order: 40,
        sort_method: SortingMethods.NUMERIC,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Financial Information",
            identifier: "financial_information",
            order: 3
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Amount Paid
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Amount Paid",
        identifier: "amount_paid",
        field_type: FieldTypes.CURRENCY,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "The amount, in cents, that was paid of the Invoice.",
        help_text: "The amount, in cents, that was paid of this Invoice.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 47,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        is_filterable: true,
        filter_order: 40,
        is_sortable: true,
        sort_order: 41,
        sort_method: SortingMethods.NUMERIC,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Financial Information",
            identifier: "financial_information",
            order: 3
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Amount Remaining
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Amount Remaining",
        identifier: "amount_remaining",
        field_type: FieldTypes.CURRENCY,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "The difference between the amount due and the amount paid, in cents.",
        help_text: "The difference between the amount due and the amount paid, in cents.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 48,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        is_filterable: true,
        filter_order: 41,
        is_sortable: true,
        sort_order: 42,
        sort_method: SortingMethods.NUMERIC,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Financial Information",
            identifier: "financial_information",
            order: 3
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Starting Balance
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Starting Balance",
        identifier: "starting_balance",
        field_type: FieldTypes.CURRENCY,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Starting customer balance before the invoice is finalized. If the invoice has not been finalized yet, this will be the current customer balance.",
        help_text: "Starting customer balance before the invoice is finalized. If the invoice has not been finalized yet, this will be the current customer balance.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 49,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        is_filterable: true,
        filter_order: 42,
        is_sortable: true,
        sort_order: 43,
        sort_method: SortingMethods.NUMERIC,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Financial Information",
            identifier: "financial_information",
            order: 3
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Ending Balanace
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Ending Balance",
        identifier: "ending_balance",
        field_type: FieldTypes.CURRENCY,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Ending customer balance after the invoice is finalized. Invoices are finalized approximately an hour after successful webhook delivery or when payment collection is attempted for the invoice. If the invoice has not been finalized yet, this will be null.",
        help_text: "Ending customer balance after the invoice is finalized. Invoices are finalized approximately an hour after successful webhook delivery or when payment collection is attempted for the invoice. If the invoice has not been finalized yet, this will be null.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 50,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        is_filterable: true,
        filter_order: 43,
        is_sortable: true,
        sort_order: 44,
        sort_method: SortingMethods.NUMERIC,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Financial Information",
            identifier: "financial_information",
            order: 3
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Payments
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Payments",
        identifier: "payments",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Represents payments made from the customer for the invoice.",
        help_text: "Represents payments made from the customer for this invoice.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 51,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: true,
        is_async: true,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Financial Information",
            identifier: "financial_information",
            order: 3
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "payments",
            filters: [
                {
                    fields: ["payments.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            label: "True",
                            type: "value",
                            data: true,
                            data_type: 'boolean',
                            field_type: "checkbox",
                            input: true
                        }
                    ]
                }
            ],
            display_field: "subject",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Number of Attempts
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Number of Attempts",
        identifier: "num_of_attempts",
        field_type: FieldTypes.INTEGER,
        data_type: DatabaseTypes.INTEGER,
        special_type: null,
        description: "The number of payment collection attempts for a Invoice.",
        help_text: "The number of payment collection attempts for this Invoice.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 52,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        is_filterable: true,
        filter_order: 44,
        is_sortable: true,
        sort_order: 45,
        sort_method: SortingMethods.NUMERIC,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Financial Information",
            identifier: "financial_information",
            order: 3
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Next Payment Attempt
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Next Payment Attempt",
        identifier: "next_payment_attempt",
        field_type: FieldTypes.DATE_TIME,
        data_type: DatabaseTypes.TIMESTAMPTZ,
        special_type: null,
        description: "The time at which payment will next be attempted.",
        help_text: "The time at which payment will next be attempted.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 53,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        is_filterable: true,
        filter_order: 45,
        is_sortable: true,
        sort_order: 46,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Financial Information",
            identifier: "financial_information",
            order: 3
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Statement Descriptor
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Statement Descriptor",
        identifier: "statement_descriptor",
        field_type: FieldTypes.SHORT_TEXT,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        description: "Extra information about an invoice for the customer's credit card statement.",
        help_text: "Extra information about an invoice for the customer's credit card statement.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 54,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        is_filterable: true,
        filter_order: 46,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Financial Information",
            identifier: "financial_information",
            order: 3
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Subscription Proration Date
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Subscription Proration Date",
        identifier: "subscription_proration_date",
        field_type: FieldTypes.DATE_TIME,
        data_type: DatabaseTypes.TIMESTAMPTZ,
        special_type: null,
        description: "Only set for upcoming invoices that preview prorations. The time used to calculate prorations.",
        help_text: "Only set for upcoming invoices that preview prorations. The time used to calculate prorations.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 55,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        is_filterable: true,
        filter_order: 47,
        is_sortable: true,
        sort_order: 47,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Financial Information",
            identifier: "financial_information",
            order: 3
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Application Fee Amount
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Application Fee Amount",
        identifier: "next_payment_attempt",
        field_type: FieldTypes.CURRENCY,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "The fee in cents that will be applied to the invoice when the invoice is paid.",
        help_text: "The fee in cents that will be applied to the invoice when the invoice is paid.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 56,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Financial Information",
            identifier: "financial_information",
            order: 3
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Primary Contact's Name
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Primary Contact's Name",
        identifier: "primary_name",
        field_type: FieldTypes.NAME,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "The name of the Primary Contact. Used for full-text search.",
        help_text: "The name of the Primary Contact.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 57,
        is_required: false,
        is_editable: true,
        is_editable_inline: true,
        is_searchable: true,
        search_priority: 'A',
        is_filterable: true,
        filter_order: 48,
        is_sortable: true,
        sort_order: 48,
        sort_method: SortingMethods.NESTED_FIELD,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Contact Information",
            identifier: "contact_information",
            order: 4
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Primary Contact's Phone
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Primary Contact's Phone",
        identifier: "primary_phone",
        field_type: FieldTypes.PHONE,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "The phone number of the Primary Contact. Used for full-text search.",
        help_text: "The phone number of the Primary Contact.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 58,
        is_required: false,
        is_editable: true,
        is_editable_inline: true,
        is_searchable: true,
        search_priority: 'A',
        is_filterable: true,
        filter_order: 49,
        is_sortable: true,
        sort_order: 49,
        sort_method: SortingMethods.NESTED_FIELD,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Contact Information",
            identifier: "contact_information",
            order: 4
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Primary Contact's Email
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Primary Contact's Email",
        identifier: "primary_email",
        field_type: FieldTypes.EMAIL,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "The email address of the Primary Contact. Used for full-text search.",
        help_text: "The email address of the Primary Contact.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 59,
        is_required: false,
        is_editable: true,
        is_editable_inline: true,
        is_searchable: true,
        search_priority: 'A',
        is_filterable: false,
        filter_order: null,
        is_sortable: true,
        sort_order: 50,
        sort_method: SortingMethods.NESTED_FIELD,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Contact Information",
            identifier: "contact_information",
            order: 4
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Primary Property's Address
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Primary Property's Address",
        identifier: "primary_address",
        field_type: FieldTypes.ADDRESS,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "The address of the Primary Property. Used for full-text search.",
        help_text: "The address of the Primary Property.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 60,
        is_required: false,
        is_editable: true,
        is_editable_inline: true,
        is_searchable: true,
        search_priority: 'A',
        is_filterable: true,
        filter_order: 50,
        is_sortable: true,
        sort_order: 51,
        sort_method: SortingMethods.NESTED_FIELD,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Contact Information",
            identifier: "contact_information",
            order: 4
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Billing Contact's Name
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Billing Contact's Name",
        identifier: "billing_name",
        field_type: FieldTypes.NAME,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "The name of the Billing Contact. Used for full-text search.",
        help_text: "The name of the Billing Contact.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 61,
        is_required: false,
        is_editable: true,
        is_editable_inline: true,
        is_searchable: true,
        search_priority: 'A',
        is_filterable: true,
        filter_order: 51,
        is_sortable: true,
        sort_order: 52,
        sort_method: SortingMethods.NESTED_FIELD,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Contact Information",
            identifier: "contact_information",
            order: 4
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Billing Contact's Phone Number
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Billing Contact's Phone",
        identifier: "billing_phone",
        field_type: FieldTypes.PHONE,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "The phone number of the Billing Contact. Used for full-text search.",
        help_text: "The phone number of the Billing Contact.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 62,
        is_required: false,
        is_editable: true,
        is_editable_inline: true,
        is_searchable: true,
        search_priority: 'A',
        is_filterable: true,
        filter_order: 52,
        is_sortable: true,
        sort_order: 53,
        sort_method: SortingMethods.NESTED_FIELD,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Contact Information",
            identifier: "contact_information",
            order: 4
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Billing Contact's Email Address
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Billing Contact's Email",
        identifier: "billing_email",
        field_type: FieldTypes.EMAIL,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "The email address of the Billing Contact. Used for full-text search.",
        help_text: "The email address of the Billing Contact.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 63,
        is_required: false,
        is_editable: true,
        is_editable_inline: true,
        is_searchable: true,
        search_priority: 'A',
        is_filterable: false,
        filter_order: null,
        is_sortable: true,
        sort_order: 54,
        sort_method: SortingMethods.NESTED_FIELD,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Contact Information",
            identifier: "contact_information",
            order: 4
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Billing Property's Address
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Billing Property's Address",
        identifier: "billing_address",
        field_type: FieldTypes.ADDRESS,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "The address of the Billing Property. Used for full-text search.",
        help_text: "The address of the Billing Property.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 64,
        is_required: false,
        is_editable: true,
        is_editable_inline: true,
        is_searchable: true,
        search_priority: 'A',
        is_filterable: true,
        filter_order: 53,
        is_sortable: true,
        sort_order: 55,
        sort_method: SortingMethods.NESTED_FIELD,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Contact Information",
            identifier: "contact_information",
            order: 4
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Service Contact's Name
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Service Contact's Name",
        identifier: "service_name",
        field_type: FieldTypes.NAME,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "The name of the Service Contact. Used for full-text search.",
        help_text: "The name of the Service Contact.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 65,
        is_required: false,
        is_editable: true,
        is_editable_inline: true,
        is_searchable: true,
        search_priority: 'A',
        is_filterable: true,
        filter_order: 54,
        is_sortable: true,
        sort_order: 56,
        sort_method: SortingMethods.NESTED_FIELD,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Contact Information",
            identifier: "contact_information",
            order: 4
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Service Contact's Phone Number
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Service Contact's Phone",
        identifier: "service_phone",
        field_type: FieldTypes.PHONE,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "The phone number of the Service Contact. Used for full-text search.",
        help_text: "The phone number of the Service Contact.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 66,
        is_required: false,
        is_editable: true,
        is_editable_inline: true,
        is_searchable: true,
        search_priority: 'A',
        is_filterable: true,
        filter_order: 55,
        is_sortable: true,
        sort_order: 57,
        sort_method: SortingMethods.NESTED_FIELD,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Contact Information",
            identifier: "contact_information",
            order: 4
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Service Contact's Email Address
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Service Contact's Email",
        identifier: "service_email",
        field_type: FieldTypes.EMAIL,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "The email address of the Service Contact. Used for full-text search.",
        help_text: "The email address of the Service Contact.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 67,
        is_required: false,
        is_editable: true,
        is_editable_inline: true,
        is_searchable: true,
        search_priority: 'A',
        is_filterable: false,
        filter_order: null,
        is_sortable: true,
        sort_order: 58,
        sort_method: SortingMethods.NESTED_FIELD,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Contact Information",
            identifier: "contact_information",
            order: 4
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Service Property's Address
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Service Property's Address",
        identifier: "service_address",
        field_type: FieldTypes.ADDRESS,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "The address of the Service Property. Used for full-text search.",
        help_text: "The address of the Service Property.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 68,
        is_required: false,
        is_editable: true,
        is_editable_inline: true,
        is_searchable: true,
        search_priority: 'A',
        is_filterable: true,
        filter_order: 56,
        is_sortable: true,
        sort_order: 59,
        sort_method: SortingMethods.NESTED_FIELD,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Contact Information",
            identifier: "contact_information",
            order: 4
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Is Sent
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Is Sent",
        identifier: "is_sent",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "Represents if this Invoice has been sent to the customer.",
        help_text: "Represents if this Invoice has been sent to the customer.",
        default_value: {
            data_type: DatabaseTypes.BOOLEAN,
            data: false
        },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 69,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 57,
        is_sortable: true,
        sort_order: 60,
        sort_method: SortingMethods.BOOLEAN,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Invoice Checks",
            identifier: "invoice_checks",
            order: 5
        },
        checkbox_labels: { true: "Active", false: "Not Active" },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Is Attempted
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Is Attempted",
        identifier: "is_attempted",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "Represents if this Invoice payment has been attempted.",
        help_text: "Represents if this Invoice payment has been attempted.",
        default_value: {
            data_type: DatabaseTypes.BOOLEAN,
            data: false
        },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 70,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 58,
        is_sortable: true,
        sort_order: 61,
        sort_method: SortingMethods.BOOLEAN,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Invoice Checks",
            identifier: "invoice_checks",
            order: 5
        },
        checkbox_labels: { true: "Attempted", false: "Not Attempted" },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Is Paid
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Is Paid",
        identifier: "is_paid",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "Represents if this Invoice payment has been paid fully.",
        help_text: "Represents if this Invoice payment has been paid fully.",
        default_value: {
            data_type: DatabaseTypes.BOOLEAN,
            data: false
        },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 71,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 59,
        is_sortable: true,
        sort_order: 62,
        sort_method: SortingMethods.BOOLEAN,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Invoice Checks",
            identifier: "invoice_checks",
            order: 5
        },
        checkbox_labels: { true: "Paid", false: "Not Paid" },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Out of Band
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Paid Out of Band",
        identifier: "paid_out_of_band",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "Represents if this Invoice payment has been paid out of band. Meaning the invoice has been paid outside of ProHelper.",
        help_text: "Represents if this Invoice payment has been paid out of band / paid outside of ProHelper.",
        default_value: {
            data_type: DatabaseTypes.BOOLEAN,
            data: false
        },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 72,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 60,
        is_sortable: true,
        sort_order: 63,
        sort_method: SortingMethods.BOOLEAN,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Invoice Checks",
            identifier: "invoice_checks",
            order: 5
        },
        checkbox_labels: { true: "Paid Out of Band", false: "Paid through ProHelper" },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Is Active
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Is Active",
        identifier: "is_active",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "Represents if this Invoice is currently active; able for users to use it.",
        help_text: "Represents if this Invoice is currently active; able for users to use it.",
        default_value: {
            data_type: DatabaseTypes.BOOLEAN,
            data: true
        },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 73,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 61,
        is_sortable: true,
        sort_order: 64,
        sort_method: SortingMethods.BOOLEAN,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Invoice Checks",
            identifier: "invoice_checks",
            order: 5
        },
        checkbox_labels: { true: "Active", false: "Not Active" },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Is On Hold
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Is On Hold",
        identifier: "is_on_hold",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "If true, this invoice is on hold and all work should be temporarily stopped. ",
        help_text: "If true, this invoice is on hold and all work should be temporarily stopped. ",
        default_value: {
            data_type: DatabaseTypes.BOOLEAN,
            data: false
        },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 74,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 62,
        is_sortable: true,
        sort_order: 65,
        sort_method: SortingMethods.BOOLEAN,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Invoice Checks",
            identifier: "invoice_checks",
            order: 5
        },
        checkbox_labels: { true: "On Hold", false: "Not On Hold" },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Is Closed
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Is Closed",
        identifier: "is_closed",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "Represents if this invoice is closed",
        help_text: "Represents if this invoice is closed",
        default_value: {
            data_type: DatabaseTypes.BOOLEAN,
            data: false
        },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 75,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 63,
        is_sortable: true,
        sort_order: 66,
        sort_method: SortingMethods.BOOLEAN,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Invoice Checks",
            identifier: "invoice_checks",
            order: 5
        },
        checkbox_labels: { true: "Closed", false: "Not Closed" },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Is Completed
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Is Completed",
        identifier: "is_completed",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "Represents if this invoice is completed",
        help_text: "Represents if this invoice is completed",
        default_value: {
            data_type: DatabaseTypes.BOOLEAN,
            data: true
        },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 76,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 64,
        is_sortable: true,
        sort_order: 67,
        sort_method: SortingMethods.BOOLEAN,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Invoice Checks",
            identifier: "invoice_checks",
            order: 5
        },
        checkbox_labels: { true: "Completed", false: "Not Completed" },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Is Archived
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Is Archived",
        identifier: "is_archived",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "If true, this Invoice is archived and not shown in normal use; only able to recover in the trash.",
        help_text: "If true, this Invoice is archived and not shown in normal use; only able to recover in the trash.",
        default_value: {
            data_type: DatabaseTypes.BOOLEAN,
            data: false
        },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: { true: "Archived", false: "Not Archived" },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Is Deleted
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Is Deleted",
        identifier: "is_deleted",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "If true, this Invoice is deleted and non-recoverable after 180 days.",
        help_text: "If true, this Invoice is deleted and non-recoverable after 180 days.",
        default_value: {
            data_type: DatabaseTypes.BOOLEAN,
            data: false
        },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: { true: "Deleted", false: "Not Deleted" },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Is Locked
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Is Locked",
        identifier: "is_locked",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "If true, an Invoice is locked permanently and it's data cannot be edited or deleted even by admin users.",
        help_text: "If true, an Invoice is locked permanently and it's data cannot be edited or deleted even by admin users.",
        default_value: {
            data_type: DatabaseTypes.BOOLEAN,
            data: false
        },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 77,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 65,
        is_sortable: true,
        sort_order: 68,
        sort_method: SortingMethods.BOOLEAN,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Invoice Checks",
            identifier: "invoice_checks",
            order: 5
        },
        checkbox_labels: { true: "Locked", false: "Not Locked" },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Is Draft
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Is Draft",
        identifier: "is_draft",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "If true, this Invoice is a draft Invoice; Saved details, but not ready for release yet.",
        help_text: "If true, this Invoice is a draft Invoice; Saved details, but not ready for release yet.",
        default_value: {
            data_type: DatabaseTypes.BOOLEAN,
            data: false
        },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: { true: "Draft", false: "Public" },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Is Template
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Is Template",
        identifier: "is_template",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "If true, this Invoice is a template for creating future Invoices faster.",
        help_text: "A template is used for creating future Invoices faster.",
        default_value: {
            data_type: DatabaseTypes.BOOLEAN,
            data: false
        },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: { true: "Template", false: "Not Template" },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Is Live
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Is Live",
        identifier: "is_live",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "If true, this Invoice is live for the organization. Not a record in testing mode.",
        help_text: "If true, this Invoice is live for the organization. Not a record in testing mode.",
        default_value: {
            data_type: DatabaseTypes.BOOLEAN,
            data: true
        },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: { true: "Live Data", false: "Test Data" },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Is Sub Invoice
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Is Sub Invoice",
        identifier: "is_sub_invoice",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "If true, this Invoice is a sub Invoice and as a parent Invoice associated with it. If true, the parent Invoice should be filled in.",
        help_text: "Represents whether an Invoice is a child Invoice to another Invoice in the system.",
        default_value: {
            data_type: DatabaseTypes.BOOLEAN,
            data: false
        },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 78,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 66,
        is_sortable: true,
        sort_order: 69,
        sort_method: SortingMethods.BOOLEAN,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Invoice Checks",
            identifier: "invoice_checks",
            order: 5
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: { true: "Sub Invoice", false: "Not Sub Invoice" },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Level
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Level",
        identifier: "level",
        field_type: FieldTypes.INTEGER,
        data_type: DatabaseTypes.INTEGER,
        special_type: null,
        description: "Represents the depth in the overall Invoice structure. (Parent Invoice, Sub Invoices) (Max Level: 3)",
        help_text: "The depth of the Invoice in the overall parent-child Invoice structure.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 79,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 67,
        is_sortable: true,
        sort_order: 70,
        sort_method: SortingMethods.NUMERIC,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 6
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Parent Invoice
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Parent Invoice",
        identifier: "parent",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "If this Invoice is a sub-Invoice, then this represents a link to the parent Invoice.",
        help_text: "The parent Invoice of this sub-Invoice.",
        default_value: null,
        placeholder_text: "Select a parent Invoice...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 80,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 68,
        is_sortable: true,
        sort_order: 71,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 6
        },
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "invoices",
            filters: [
                {
                    fields: ["invoices.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            label: "True",
                            type: "value",
                            data: true,
                            data_type: 'boolean',
                            field_type: "checkbox",
                            input: true
                        }
                    ]
                }
            ],
            display_field: "subject",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Bill With Parent
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Bill With Parent",
        identifier: "bill_with_parent",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "If this invoice is a sub-invoice and bill with parent is true, this invoice's total should be added with the parent invoice.",
        help_text: "If this invoice is a sub-invoice and bill with parent is true, this invoice's total should be added with the parent invoice.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 81,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 69,
        is_sortable: true,
        sort_order: 72,
        sort_method: SortingMethods.BOOLEAN,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 6
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: { true: "Bill With Parent", false: "Do Not Bill With Parent" },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Currency
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Currency",
        identifier: "currency",
        field_type: FieldTypes.TEXT_DROPDOWN,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        description: "The preferred currency for a Invoice.",
        help_text: "The preferred currency for a Invoice.",
        default_value: { data_type: 'text', data: 'usd' },
        placeholder_text: "Select a currency...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 82,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 70,
        is_sortable: true,
        sort_order: 73,
        sort_method: SortingMethods.LINKED_ALPHANUMERIC,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 6
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: {
            identifier: "static.currencies",
            object_identifier: "static",
            type: "text_dropdown"
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Language
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Language",
        identifier: "language",
        field_type: FieldTypes.TEXT_DROPDOWN,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        description: "The preferred language for a Invoice.",
        help_text: "The preferred language for a Invoice.",
        default_value: { data_type: 'text', data: 'en' },
        placeholder_text: "Select a language...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 83,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 71,
        is_sortable: true,
        sort_order: 74,
        sort_method: SortingMethods.LINKED_ALPHANUMERIC,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 6
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: {
            identifier: "static.languages",
            object_identifier: "static",
            type: "text_dropdown"
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Time Zone
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Time Zone",
        identifier: "time_zone",
        field_type: FieldTypes.TEXT_DROPDOWN,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        description: "The Time Zone that the Invoice is based in.",
        help_text: "What time zone this invoice is based in.",
        default_value: { data_type: 'text', data: 'America/Los_Angeles' },
        placeholder_text: "Select a time zone...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 84,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 72,
        is_sortable: true,
        sort_order: 75,
        sort_method: SortingMethods.LINKED_ALPHANUMERIC,
        is_multi: false,
        is_async: true,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 6
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: {
            identifier: "static.time_zones",
            object_identifier: "static",
            type: "text_dropdown"
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Country
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Country",
        identifier: "country",
        field_type: FieldTypes.TEXT_DROPDOWN,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        description: "The Country that the Invoice is based in.",
        help_text: "What time zone this invoice is based in.",
        default_value: { data_type: 'text', data: 'America/Los_Angeles' },
        placeholder_text: "Select a time zone...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 85,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 73,
        is_sortable: true,
        sort_order: 76,
        sort_method: SortingMethods.LINKED_ALPHANUMERIC,
        is_multi: false,
        is_async: true,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 6
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: {
            identifier: "static.countries",
            object_identifier: "static",
            type: "text_dropdown"
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Hosted Invoice URL
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Hosted Invoice URL",
        identifier: "hosted_invoice_url",
        field_type: FieldTypes.URL,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        description: "Represents a public link to an invoice which can be viewed by the customer.",
        help_text: "Represents a public link to an invoice which can be viewed by the customer.",
        default_value: null,
        placeholder_text: "Enter a Invoice URL...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 86,
        is_required: false,
        is_editable: false,
        is_searchable: true,
        search_priority: 'B',
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 6
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Domain
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Domain",
        identifier: "domain",
        field_type: FieldTypes.SHORT_TEXT,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        description: "Represents the platform/domain the Invoice was created on. Can be created by ProHelper or other platforms through integrations.",
        help_text: "Represents the platform/domain the Invoice was created on. Can be created by ProHelper or other platforms through integrations.",
        default_value: null,
        placeholder_text: "Enter a Domain...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 87,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 6
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Popup Alert
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Popup Alert",
        identifier: "popup_alert",
        field_type: FieldTypes.ALERT,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "Shows an important message as a banner or dialog when viewing this record in detail.",
        help_text: "An important alert message shown when someone views an invoice.",
        default_value: null,
        placeholder_text: "Enter a popup message to show to invoices...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 88,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 74,
        is_sortable: true,
        sort_order: 77,
        sort_method: SortingMethods.NESTED_FIELD,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Invoice Alerts",
            identifier: "invoice_alerts",
            order: 7
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Completed Alert
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Completed Alert",
        identifier: "completed_alert",
        field_type: FieldTypes.ALERT,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "Shows the completed alert message as a banner or dialog when viewing the Invoice record in detail.",
        help_text: "An alert message detailing the completion of this Invoice.",
        default_value: null,
        placeholder_text: "Enter an completed alert message...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 89,
        is_required: false,
        is_editable: true,
        is_searchable: true,
        search_priority: 'B',
        is_filterable: true,
        filter_order: 75,
        is_sortable: true,
        sort_order: 78,
        sort_method: SortingMethods.NESTED_FIELD,
        sort_field: null,
        sort_join: null,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Invoice Alerts",
            identifier: "invoice_alerts",
            order: 7
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Closed Alert
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Closed Alert",
        identifier: "closed_alert",
        field_type: FieldTypes.ALERT,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "Shows the closed alert message as a banner or dialog when viewing the Invoice record in detail.",
        help_text: "An alert message detailing why this Invoice is closed.",
        default_value: null,
        placeholder_text: "Enter an closed alert message...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 90,
        is_required: false,
        is_editable: true,
        is_searchable: true,
        search_priority: 'B',
        is_filterable: true,
        filter_order: 76,
        is_sortable: true,
        sort_order: 79,
        sort_method: SortingMethods.NESTED_FIELD,
        sort_field: null,
        sort_join: null,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Invoice Alerts",
            identifier: "invoice_alerts",
            order: 7
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Locked Alert
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Locked Alert",
        identifier: "locked_alert",
        field_type: FieldTypes.ALERT,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "Shows the locked alert message as a banner or dialog when viewing the Invoice record in detail.",
        help_text: "An alert message detailing why this Invoice is locked.",
        default_value: null,
        placeholder_text: "Enter an locked alert message...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 91,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 77,
        is_sortable: true,
        sort_order: 80,
        sort_method: SortingMethods.NESTED_FIELD,
        sort_field: null,
        sort_join: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Invoice Alerts",
            identifier: "invoice_alerts",
            order: 7
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // On Hold Alert
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "On Hold Alert",
        identifier: "on_hold_alert",
        field_type: FieldTypes.ALERT,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "An optional text area to show the reasoning why \"is_on_hold\" is true for this invoice.",
        help_text: "An optional text area to show the reasoning why \"is_on_hold\" is true for this invoice.",
        default_value: null,
        placeholder_text: "Enter an on hold alert message...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 92,
        is_required: false,
        is_editable: true,
        is_searchable: true,
        search_priority: 'B',
        is_filterable: true,
        filter_order: 78,
        is_sortable: true,
        sort_order: 81,
        sort_method: SortingMethods.NESTED_FIELD,
        sort_field: null,
        sort_join: null,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Invoice Alerts",
            identifier: "invoice_alerts",
            order: 7
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Archived Alert
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Archived Alert",
        identifier: "archived_alert",
        field_type: FieldTypes.ALERT,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "Shows an alert why the Invoice was archived when someone views an archived record.",
        help_text: "Shows an alert why the Invoice was archived when someone views an archived record.",
        default_value: null,
        placeholder_text: "Enter a reason for this Invoice's archival...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Created At
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Created At",
        identifier: "created_at",
        field_type: FieldTypes.DATE_TIME,
        data_type: DatabaseTypes.TIMESTAMPTZ,
        special_type: null,
        description: "Represents the date/time the Invoice was created. System generated timestamp.",
        help_text: "The date/time when the Invoice was created.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 93,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 79,
        is_sortable: true,
        sort_order: 82,
        sort_method: SortingMethods.CHRONOLOGICAL,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 6
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Created By
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Created By",
        identifier: "created_by",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Represents the User who created the Invoice. System generated.",
        help_text: "The User who created an Invoice.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 94,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 80,
        is_sortable: true,
        sort_order: 83,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 6
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "users",
            filters: [
                {
                    fields: ["users.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            label: "True",
                            type: "value",
                            data: true,
                            data_type: 'boolean',
                            field_type: "checkbox",
                            input: true
                        }
                    ]
                }
            ],
            display_field: "name",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Last Updated At
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Last Updated At",
        identifier: "last_updated_at",
        field_type: FieldTypes.DATE_TIME,
        data_type: DatabaseTypes.TIMESTAMPTZ,
        special_type: null,
        description: "Represents the date/time when the Invoice was last updated. System generated timestamp.",
        help_text: "The date/time when the Invoice was last updated.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 95,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 81,
        is_sortable: true,
        sort_order: 84,
        sort_method: SortingMethods.CHRONOLOGICAL,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 6
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Last Updated By
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Last Updated By",
        identifier: "last_updated_by",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Represents the User who last updated the Invoice. System generated.",
        help_text: "The User who last updated the Invoice.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 96,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 82,
        is_sortable: true,
        sort_order: 85,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 6
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "users",
            filters: [
                {
                    fields: ["users.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            label: "True",
                            type: "value",
                            data: true,
                            data_type: 'boolean',
                            field_type: "checkbox",
                            input: true
                        }
                    ]
                }
            ],
            display_field: "name",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Last Viewed At
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Last Viewed At",
        identifier: "last_viewed_at",
        field_type: FieldTypes.DATE_TIME,
        data_type: DatabaseTypes.TIMESTAMPTZ,
        special_type: null,
        description: "Represents the date/time the Invoice was last viewed. System generated timestamp.",
        help_text: "The date/time when the Invoice was last viewed.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 97,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 83,
        is_sortable: true,
        sort_order: 86,
        sort_method: SortingMethods.CHRONOLOGICAL,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 6
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Last Viewed By
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Last Viewed By",
        identifier: "last_viewed_by",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Represents the User who last viewed the Invoice. System generated.",
        help_text: "The User who last viewed the Invoice.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 98,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 84,
        is_sortable: true,
        sort_order: 87,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 6
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "users",
            filters: [
                {
                    fields: ["users.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            label: "True",
                            type: "value",
                            data: true,
                            data_type: 'boolean',
                            field_type: "checkbox",
                            input: true
                        }
                    ]
                }
            ],
            display_field: "name",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Last Activity At
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Last Activity At",
        identifier: "last_activity_at",
        field_type: FieldTypes.DATE_TIME,
        data_type: DatabaseTypes.TIMESTAMPTZ,
        special_type: null,
        description: "Represent the last timestamp when a related item to this invoice was created, updated, or deleted.",
        help_text: "Represent the last timestamp when a related item to this invoice was created, updated, or deleted.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 99,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 85,
        is_sortable: true,
        sort_order: 88,
        sort_method: SortingMethods.CHRONOLOGICAL,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 6
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Last Activity By
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Last Activity By",
        identifier: "last_activity_by",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Represent the last user who created, updated, or deleted a related item to this invoice.",
        help_text: "Represent the last user who created, updated, or deleted a related item to this invoice.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 100,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 86,
        is_sortable: true,
        sort_order: 89,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 6
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "users",
            filters: [
                {
                    fields: ["users.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            label: "True",
                            type: "value",
                            data: true,
                            data_type: 'boolean',
                            field_type: "checkbox",
                            input: true
                        }
                    ]
                }
            ],
            display_field: "name",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Completed At
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Completed At",
        identifier: "completed_at",
        field_type: FieldTypes.DATE_TIME,
        data_type: DatabaseTypes.TIMESTAMPTZ,
        special_type: null,
        description: "Represents the date/time the Invoice was completed at. System generated timestamp.",
        help_text: "The date/time when the Invoice was completed at.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 101,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 87,
        is_sortable: true,
        sort_order: 90,
        sort_method: SortingMethods.CHRONOLOGICAL,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 6
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Completed By
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Completed By",
        identifier: "completed_by",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Represents the User who completed the Invoice. System generated.",
        help_text: "The User who completed an Invoice.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 102,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 88,
        is_sortable: true,
        sort_order: 91,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 6
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "users",
            filters: [
                {
                    fields: ["users.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            label: "True",
                            type: "value",
                            data: true,
                            data_type: 'boolean',
                            field_type: "checkbox",
                            input: true
                        }
                    ]
                }
            ],
            display_field: "name",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Closed At
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Closed At",
        identifier: "closed_at",
        field_type: FieldTypes.DATE_TIME,
        data_type: DatabaseTypes.TIMESTAMPTZ,
        special_type: null,
        description: "Represents the date/time the Invoice was closed at. System generated timestamp.",
        help_text: "The date/time when the Invoice was closed at.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 103,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 89,
        is_sortable: true,
        sort_order: 92,
        sort_method: SortingMethods.CHRONOLOGICAL,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 6
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Closed By
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Closed By",
        identifier: "closed_by",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Represents the User who closed the Invoice. System generated.",
        help_text: "The User who closed an Invoice.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 104,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 89,
        is_sortable: true,
        sort_order: 92,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 6
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "users",
            filters: [
                {
                    fields: ["users.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            label: "True",
                            type: "value",
                            data: true,
                            data_type: 'boolean',
                            field_type: "checkbox",
                            input: true
                        }
                    ]
                }
            ],
            display_field: "name",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Archived At
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Archived At",
        identifier: "archived_at",
        field_type: FieldTypes.DATE_TIME,
        data_type: DatabaseTypes.TIMESTAMPTZ,
        special_type: null,
        description: "Represents the timestamp the User archived this Invoice. System generated timestamp.",
        help_text: "Represents the timestamp the User archived this Invoice. System generated timestamp.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Archived By
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Archived By",
        identifier: "archived_by",
        field_type: FieldTypes.DATE_TIME,
        data_type: DatabaseTypes.TIMESTAMPTZ,
        special_type: null,
        description: "Represents the User who archived this Invoice. System generated timestamp.",
        help_text: "Represents the User who archived this Invoice. System generated timestamp.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "users",
            filters: [
                {
                    fields: ["users.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            label: "True",
                            type: "value",
                            data: true,
                            data_type: 'boolean',
                            field_type: "checkbox",
                            input: true
                        }
                    ]
                }
            ],
            display_field: "name",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Suggested Item Action
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Suggested Item Action",
        identifier: "suggested_action",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "The first shown item action for this Invoice.",
        help_text: "The first shown item action for this Invoice.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: null,
        lookup_relationship_options: {
            type: LookupRelationshipTypes.INDEPENDENT,
            object_identifier: "actions",
            filters: [
                {
                    fields: ["actions.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            type: "value",
                            label: 'True',
                            input: true,
                            data: true,
                            data_type: 'boolean',
                            status: 'valid'
                        }
                    ]
                },
                {
                    fields: ["actions.object_identifier"],
                    field_types: ["id"],
                    operator: "equal",
                    values: [
                        {
                            type: "value",
                            label: 'Invoices',
                            input: 'invoices',
                            data: 'invoices',
                            data_type: 'text',
                            status: 'valid'
                        }
                    ]
                }
            ],
            display_field: "label",
            strict_filter_lookups: false,
            is_related_record_editable: false,
            is_related_record_creatable: false
        },
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Sync Token
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Sync Token",
        identifier: "sync_token",
        field_type: FieldTypes.INTEGER,
        data_type: DatabaseTypes.INTEGER,
        special_type: null,
        description: "The current sync token for this Invoice; Only the most current sync token can be used to write data to avoid conflits.",
        help_text: null,
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Metadata
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Metadata",
        identifier: "metadata",
        field_type: FieldTypes.JSON,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "Some extra metadata that can be added to a Invoice.",
        help_text: null,
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Payment Settings
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Payment Settings",
        identifier: "payment_settings",
        field_type: FieldTypes.JSON,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "Represents some payment settings for the Invoice.",
        help_text: null,
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Notifications
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Notifications",
        identifier: "notifications",
        field_type: FieldTypes.JSON,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "Represents some notifcations for the Invoice.",
        help_text: null,
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Custom Fields
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Custom Fields",
        identifier: "custom_fields",
        field_type: FieldTypes.JSON,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "Custom fields for the Invoice.",
        help_text: null,
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Settings
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'invoices',
        sync_token: 1,
        label: "Settings",
        identifier: "settings",
        field_type: FieldTypes.JSON,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "Represents some settings for the Invoice.",
        help_text: null,
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
];

export default InvoiceFieldsData;

// This constants file is a representation of how data for object fields will be recieved by the ProHelper database once linked.
import uniqid from 'uniqid';
import * as FieldTypes from '../../types/FieldTypes';
import * as DatabaseTypes from '../../types/DatabaseTypes';

const VehicleDropdownSetsData = [
    // Vehicles - Statuses
    {
        id: null,
        company: null,
        public_id: uniqid('dropset_'),
        object: null,
        object_identifier: 'vehicles',
        sync_token: 1,
        label: "Vehicle Statuses",
        identifier: 'vehicles.statuses',
        dropdown_plural_label: 'Statuses',
        dropdown_singular_label: "Status",
        description: "A set of all the different statuses for an vehicle.",
        field_type: FieldTypes.TEXT_DROPDOWN,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_locked: false,
        is_live: true,
        is_editable: true,
        is_editable_inline: false,
        is_sortable: true,
        is_filterable: true,
        is_custom: false,
        is_colored: false,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        linked_fields: [],
        on_dropdown_delete: 'restrict',
        on_dropdown_set_delete: 'restrict',
        dropdowns: [
            // Available
            {
                id: null,
                company: null,
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "vehicles",
                sync_token: 1,
                label: "Available",
                identifier: "available",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "available"
                },
                color: null,
                sort_order: 1,
                is_active: true,
                is_archived: false,
                is_deleted: false,
                is_locked: true,
                is_default: true,
                is_custom: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Active
            {
                id: null,
                company: null,
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "vehicles",
                sync_token: 1,
                label: "Active",
                identifier: "active",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "active"
                },
                color: null,
                sort_order: 2,
                is_active: true,
                is_archived: false,
                is_deleted: false,
                is_locked: true,
                is_default: true,
                is_custom: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Maintenance
            {
                id: null,
                company: null,
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "vehicles",
                sync_token: 1,
                label: "Maintenance",
                identifier: "maintenance",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "Mmintenance"
                },
                color: null,
                sort_order: 3,
                is_active: true,
                is_archived: false,
                is_deleted: false,
                is_locked: true,
                is_default: true,
                is_custom: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Under Repair
            {
                id: null,
                company: null,
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "vehicles",
                sync_token: 1,
                label: "Under Repair",
                identifier: "under_repair",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "under_repair"
                },
                color: null,
                sort_order: 4,
                is_active: true,
                is_archived: false,
                is_deleted: false,
                is_locked: true,
                is_default: true,
                is_custom: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Out of Service
            {
                id: null,
                company: null,
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "vehicles",
                sync_token: 1,
                label: "Out of Service",
                identifier: "out_of_service",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "out_of_service"
                },
                color: null,
                sort_order: 5,
                is_active: true,
                is_archived: false,
                is_deleted: false,
                is_locked: true,
                is_default: true,
                is_custom: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // On Standby
            {
                id: null,
                company: null,
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "vehicles",
                sync_token: 1,
                label: "On Standby",
                identifier: "on_standby",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "on_standby"
                },
                color: null,
                sort_order: 6,
                is_active: true,
                is_archived: false,
                is_deleted: false,
                is_locked: true,
                is_default: true,
                is_custom: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Retired
            {
                id: null,
                company: null,
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "vehicles",
                sync_token: 1,
                label: "Retired",
                identifier: "retired",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "retired"
                },
                color: null,
                sort_order: 6,
                is_active: true,
                is_archived: false,
                is_deleted: false,
                is_locked: true,
                is_default: true,
                is_custom: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
        ]
    },
    // Vehicles - Fuel Type
    {
        id: null,
        company: null,
        public_id: uniqid('dropset_'),
        object: null,
        object_identifier: 'vehicles',
        sync_token: 1,
        label: "Fuel Types",
        identifier: 'vehicles.fuel_types',
        dropdown_plural_label: 'Fuel Types',
        dropdown_singular_label: "Fuel Type",
        description: "A set of all the different fuel types for an vehicle.",
        field_type: FieldTypes.TEXT_DROPDOWN,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_locked: false,
        is_live: true,
        is_editable: true,
        is_editable_inline: false,
        is_sortable: true,
        is_filterable: true,
        is_custom: false,
        is_colored: false,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        linked_fields: [],
        on_dropdown_delete: 'restrict',
        on_dropdown_set_delete: 'restrict',
        dropdowns: [
            // Gas
            {
                id: null,
                company: null,
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "vehicles",
                sync_token: 1,
                label: "Gas",
                identifier: "gas",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "gas"
                },
                color: null,
                sort_order: 1,
                is_active: true,
                is_archived: false,
                is_deleted: false,
                is_locked: true,
                is_default: true,
                is_custom: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Diesel
            {
                id: null,
                company: null,
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "vehicles",
                sync_token: 1,
                label: "Diesel",
                identifier: "diesel",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "diesel"
                },
                color: null,
                sort_order: 2,
                is_active: true,
                is_archived: false,
                is_deleted: false,
                is_locked: true,
                is_default: true,
                is_custom: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
        ]
    },
    // Vehicles - Tags
    {
        id: null,
        company: null,
        public_id: uniqid('dropset_'),
        object: null,
        object_identifier: 'vehicles',
        sync_token: 1,
        label: "Vehicle Tags",
        identifier: 'vehicles.tags',
        description: "A set of all the different tags for an vehicle.",
        dropdown_plural_label: 'Tags',
        dropdown_singular_label: "Tag",
        field_type: FieldTypes.TEXT_DROPDOWN,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_locked: false,
        is_live: true,
        is_editable: true,
        is_editable_inline: true,
        is_sortable: false,
        is_filterable: true,
        is_custom: false,
        is_colored: false,
        linked_fields: [],
        on_dropdown_delete: 'restrict',
        on_dropdown_set_delete: 'restrict',
        dropdowns: [
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "vehicles",
                sync_token: 1,
                label: "New",
                identifier: "new",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "new"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "vehicles",
                sync_token: 1,
                label: "Special",
                identifier: "special",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "special"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "vehicles",
                sync_token: 1,
                label: "Temporary",
                identifier: "temporary",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "temporary"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            }
        ]
    },
    
]

export default VehicleDropdownSetsData;

// Import Modules
import uniqid from 'uniqid';
import { identifier } from 'safe-identifier';
import * as ROUTES from '../../../../navigation/routes';
import * as ScreenTypes from '../../../../types/ScreenTypes';
import * as ScreenIdentifiers from '../../../../screens/ScreenIdentifiers';
import * as ComponentTypes from '../../../../types/ProHelperComponentTypes';


const PurchaseOrderPageLayoutsData = [
    // Default
    {
        public_id: uniqid('page_'),
        sync_token: 1,
        identifier: identifier('Default').toLowerCase(),
        label: 'Default',
        description: 'View a Purchase Order in detail.',
        status: 'published',
        revisions: 1,
        route: ROUTES.INVOICE_DETAIL_PREFIX,
        type: ScreenTypes.OBJECT_PAGE_LAYOUT,
        screen_identifier: ScreenIdentifiers.INVOICE_PAGE_LAYOUT,
        template: null,
        object: null,
        object_identifier: 'purchase_orders',
        object_type: null,
        group: {
            label: 'Billing',
            identifier: 'billing',
            order: 5
        },
        sub_group: {
            label: 'Purchase Orders',
            identifier: 'purchase_orders',
            order: 3
        },
        components: {
            component_100: {
                id: "component_1",
                label: 'Header',
                object_identifier: 'purchase_orders',
                connection_type: 'parent',
                type: ComponentTypes.OBJECT_HEADER,
                header_type: 'action_bar',
                special_type: 'header',
                is_editable: true,
                visibility: ['mobile', 'tablet', 'desktop'],
                metadata: {
                    fields: [
                        {
                            label: "Subject",
                            identifier: "purchase_orders.subject",
                            is_required: true,
                            is_read_only: false,
                            is_shown_if_empty: true
                        },
                        {
                            label: "Type",
                            identifier: "purchase_orders.type",
                            is_required: false,
                            is_read_only: false,
                            is_shown_if_empty: true
                        },
                        {
                            label: "Status",
                            identifier: "purchase_orders.status",
                            is_required: false,
                            is_read_only: false,
                            is_shown_if_empty: true
                        },
                        {
                            label: "Amount Due",
                            identifier: "purchase_orders.amount_due",
                            is_required: false,
                            is_read_only: false,
                            is_shown_if_empty: true
                        },
                        {
                            label: "Total",
                            identifier: "purchase_orders.total",
                            is_required: false,
                            is_read_only: false,
                            is_shown_if_empty: true
                        },
                        {
                            label: "Description",
                            identifier: "purchase_orders.description",
                            is_required: false,
                            is_read_only: false,
                            is_shown_if_empty: true
                        },
                        {
                            label: "Vendor",
                            identifier: "purchase_orders.vendor",
                            is_required: false,
                            is_read_only: false,
                            is_shown_if_empty: true
                        },
                        {
                            label: "Issued Date",
                            identifier: "purchase_orders.issued_date",
                            is_required: false,
                            is_read_only: false,
                            is_shown_if_empty: true
                        },
                    ],
                    first_visible_amount: {
                        mobile: 4,
                        tablet: 4,
                        desktop: 2
                    }
                }
            },
            component_200: {
                id: "component_2",
                label: 'Line Items',
                object_identifier: 'purchase_orders',
                connection_type: 'parent',
                type: ComponentTypes.LINE_ITEMS,
                header_type: 'normal',
                special_type: null,
                visibility: ['mobile', 'tablet', 'desktop'],
                is_editable: true,
                metadata: {
                    summary_section: [
                        {
                            label: "Vendor Message",
                            identifier: "purchase_orders.vendor_message",
                            type: "long_text",
                            display_fields: ["purchase_orders.vendor_message"],
                            display_format: null
                        }
                    ],
                    fields: [
                        {
                            label: "Product/Service",
                            identifier: "line_items.item",
                            type: "lookup_relationship",
                            display_fields: ["line_items.item", "items.label"],
                            display_format: null,
                            flex_size: 1
                        },
                        {
                            label: "Description",
                            identifier: "line_items.description",
                            type: "long_text",
                            display_fields: ["line_items.description"],
                            display_format: null,
                            flex_size: 1
                        },
                        {
                            label: "Type",
                            identifier: "line_items.type",
                            type: "type_dropdown",
                            display_fields: ["line_items.type", "object_types.label"],
                            display_format: null,
                            flex_size: 1
                        },
                        {
                            label: "Quantity",
                            identifier: "line_items.quantity",
                            type: "decimal",
                            display_fields: ["line_items.quantity"],
                            display_format: null,
                            flex_size: 1
                        },
                        {
                            label: "Price",
                            identifier: "line_items.price",
                            type: "currency",
                            display_fields: ["line_items.price"],
                            display_format: null,
                            flex_size: 1
                        },
                        {
                            label: "Total",
                            identifier: "line_items.total",
                            type: "currency",
                            display_fields: ["line_items.total"],
                            display_format: null,
                            flex_size: 1
                        },


                    ]
                }
            },
            component_300: {
                id: "component_3",
                label: 'Details',
                object_identifier: 'purchase_orders',
                connection_type: 'parent',
                type: ComponentTypes.OBJECT_DETAILS,
                special_type: null,
                header_type: 'normal',
                visibility: ['mobile', 'tablet', 'desktop'],
                is_editable: true,
                metadata: {
                    sections: [
                        // General Information
                        {
                            label: "General Information",
                            id: 'general_info',
                            tab_order: 'left-right',
                            visibility: ['mobile', 'tablet', 'desktop'],
                            is_locked: false,
                            show_on_first_load: {
                                mobile: false,
                                tablet: true,
                                desktop: true
                            },
                            first_visible_amount: {
                                mobile: 6,
                                tablet: 4,
                                desktop: 8
                            },
                            fields: [
                                {
                                    label: "Vendor",
                                    identifier: "purchase_orders.vendor",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Primary Contact",
                                    identifier: "purchase_orders.primary_contact",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Primary Property",
                                    identifier: "purchase_orders.primary_property",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Tags",
                                    identifier: "purchase_orders.tags",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                }
                            ],
                        },
                        // Order Information
                        {
                            label: "Order Information",
                            id: 'order_info',
                            tab_order: 'left-right',
                            visibility: ['mobile', 'tablet', 'desktop'],
                            is_locked: false,
                            show_on_first_load: {
                                mobile: false,
                                tablet: true,
                                desktop: true
                            },
                            first_visible_amount: {
                                mobile: 6,
                                tablet: 4,
                                desktop: 8
                            },
                            fields: [
                                {
                                    label: "Purchase Order Number",
                                    identifier: "purchase_orders.purchase_order_number",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Purchase Orderer",
                                    identifier: "purchase_orders.purchase_orderer",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Billing Reason",
                                    identifier: "purchase_orders.billing_reason",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Payment Method",
                                    identifier: "purchase_orders.payment_method",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                }
                            ],
                        },
                        // System Information
                        {
                            label: "System Information",
                            id: 'system_info',
                            tab_order: 'left-right',
                            visibility: ['mobile', 'tablet', 'desktop'],
                            is_locked: false,
                            show_on_first_load: {
                                mobile: false,
                                tablet: false,
                                desktop: true
                            },
                            first_visible_amount: {
                                mobile: 6,
                                tablet: 4,
                                desktop: 8
                            },
                            fields: [
                                {
                                    label: "Popup Alert",
                                    identifier: "purchase_orders.popup_alert",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Purchase Order ID",
                                    identifier: "purchase_orders.purchase_order_id",
                                    is_required: true,
                                    is_read_only: true,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Created At",
                                    identifier: "purchase_orders.created_at",
                                    is_required: true,
                                    is_read_only: true,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Created By",
                                    identifier: "purchase_orders.created_by",
                                    is_required: true,
                                    is_read_only: true,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Last Updated At",
                                    identifier: "purchase_orders.last_updated_at",
                                    is_required: true,
                                    is_read_only: true,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Last Updated By",
                                    identifier: "purchase_orders.last_updated_by",
                                    is_required: true,
                                    is_read_only: true,
                                    is_shown_if_empty: true
                                },

                            ],
                        },
                    ]
                }
            },
            component_400: {
                id: "component_400",
                label: 'Activity',
                object_identifier: 'purchase_orders',
                connection_type: 'parent',
                type: ComponentTypes.ACTIVITY,
                special_type: null,
                header_type: 'normal',
                is_editable: false,
                visibility: ['mobile', 'tablet', 'desktop'],
                metadata: {
                    activities: [
                        // Tasks
                        {
                            label: 'Tasks',
                            identifier: 'tasks_0',
                            object_identifier: 'tasks',
                            display_field: 'tasks.subject',
                            show_records_container_on_first_load: {
                                mobile: false,
                                tablet: true,
                                desktop: true
                            },
                            record_cells_first_visible_amount: {
                                mobile: 3,
                                tablet: 2,
                                desktop: 2
                            },
                            filters: [
                                {
                                    fields: ["tasks.is_active"],
                                    field_types: ["checkbox"],
                                    operator: "equal",
                                    values: [
                                        {
                                            type: "value",
                                            label: 'True',
                                            input: true,
                                            data: true,
                                            data_type: 'boolean',
                                            status: 'valid'
                                        }
                                    ]
                                }
                            ],
                            sort_field: "tasks.last_updated_at",
                            sort_by: ["tasks.last_updated_at"],
                            sort_method: "chronological",
                            sort_direction: "DESC",
                            sort_nulls_last: true,
                            sort_join: [],
                            search_value: '',
                            item_actions: [
                                "tasks.edit",
                                "tasks.mark_completed",
                                "tasks.mark_closed",
                                "tasks.convert_to_job",
                                "tasks.archive",
                                "tasks.delete"
                            ],
                            fields: [
                                {
                                    label: 'Subject',
                                    identifier: 'tasks.subject',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["tasks.subject"],
                                    display_format: null
                                },
                                {
                                    label: 'Description',
                                    identifier: 'tasks.description',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["tasks.description"],
                                    display_format: null
                                },
                                {
                                    label: 'Type',
                                    identifier: 'tasks.type',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["tasks.type"],
                                    display_format: null
                                },
                                {
                                    label: 'Status',
                                    identifier: 'tasks.status',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["tasks.status", "dropdowns.label"],
                                    display_format: null
                                },
                                {
                                    label: 'Start Date',
                                    identifier: 'tasks.start_date',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["tasks.start_date"],
                                    display_format: "MMMM Do YYYY, h:mm a"
                                },
                                {
                                    label: 'Due Date',
                                    identifier: 'tasks.due_date',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["tasks.due_date"],
                                    display_format: "MMMM Do YYYY, h:mm a"
                                },
                                {
                                    label: 'Assigned To',
                                    identifier: 'tasks.assigned_to',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["tasks.assigned_to", "users.name", "name.display"],
                                    display_format: null
                                },
                            ]
                        },
                        // Notes
                        {
                            label: 'Notes',
                            identifier: 'notes_3',
                            object_identifier: 'notes',
                            display_field: 'notes.title',
                            show_records_container_on_first_load: {
                                mobile: false,
                                tablet: true,
                                desktop: true
                            },
                            record_cells_first_visible_amount: {
                                mobile: 3,
                                tablet: 2,
                                desktop: 2
                            },
                            filters: [
                                {
                                    fields: ["notes.is_active"],
                                    field_types: ["checkbox"],
                                    operator: "equal",
                                    values: [
                                        {
                                            type: "value",
                                            label: 'True',
                                            input: true,
                                            data: true,
                                            data_type: 'boolean',
                                            status: 'valid'
                                        }
                                    ]
                                }
                            ],
                            sort_field: "notes.created_at",
                            sort_by: ["notes.created_at"],
                            sort_method: "chronological",
                            sort_direction: "DESC",
                            sort_nulls_last: true,
                            sort_join: [],
                            search_value: '',
                            item_actions: [],
                            fields: [
                                {
                                    label: 'Title',
                                    identifier: 'notes.title',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["notes.title"],
                                    display_format: null
                                },
                                {
                                    label: 'Status',
                                    identifier: 'notes.status',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["notes.status","dropdowns.label"],
                                    display_format: null
                                },
                                {
                                    label: 'Created At',
                                    identifier: 'notes.created_at',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["notes.created_at"],
                                    display_format: "MMMM Do YYYY, h:mm a"
                                },
                                {
                                    label: 'Created By',
                                    identifier: 'notes.created_by',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["notes.created_by","users.name","name.display"],
                                    display_format: null
                                },
                                {
                                    label: 'Body',
                                    identifier: 'notes.body',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["notes.body"],
                                    display_format: null
                                }
                            ]
                        }
                    ]
                }
            },
            component_500: {
                id: "component_500",
                label: 'Timeline',
                object_identifier: 'purchase_orders',
                connection_type: 'parent',
                type: ComponentTypes.TIMELINE,
                header_type: 'normal',
                special_type: null,
                is_editable: false,
                visibility: ['mobile', 'tablet', 'desktop'],
                metadata: {
                    notifcations: {

                    }
                }
            }
        },
        sections: [
            {
                id: uniqid('section_'),
                anchor_name: 'header',
                visibility: ['mobile', 'tablet', 'desktop'],
                template_label: 'Full',
                template_identifier: 'full',
                shrink_method: 'seperate',
                columns: {
                    mobile: [{ id: '100', flexSize: 1, components: ["component_100"] }],
                    tablet: [{ id: '100', flexSize: 1, components: ["component_100"] }],
                    desktop: [{ id: '100', flexSize: 1, components: ["component_100"] }]
                }
            },
            {
                id: uniqid('section_'),
                anchor_name: 'line_items',
                visibility: ['mobile', 'tablet', 'desktop'],
                template_label: 'Full',
                template_identifier: 'full',
                shrink_method: 'tabs',
                columns: {
                    mobile: [{ id: '200', flexSize: 1, components: ["component_200"] }],
                    tablet: [{ id: '200', flexSize: 1, components: ["component_200"] }],
                    desktop: [{ id: '200', flexSize: 1, components: ["component_200"] }]
                }
            },
            {
                id: uniqid('section_'),
                anchor_name: 'details_activity',
                visibility: ['mobile', 'tablet', 'desktop'],
                template_label: 'Full',
                template_identifier: 'full',
                shrink_method: 'tabs',
                columns: {
                    mobile: [{ id: '300', flexSize: 1, components: [["component_300", "component_400", "component_500"]] }],
                    tablet: [{ id: '300', flexSize: 2, components: ["component_300"] }, { id: '400', flexSize: 1, components: [["component_400", "component_500"]] }],
                    desktop: [{ id: '300', flexSize: 2, components: ["component_300"] }, { id: '400', flexSize: 1, components: [["component_400", "component_500"]] }]
                }
            },
        ],
        item_actions: [
            "purchase_orders.edit",
            "purchase_orders.archive"
        ],
        more_actions: [
            "purchase_orders.duplicate",
            "purchase_orders.delete"
        ],
        sort_order: 1,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        read_access: "all_users",
        edit_access: "private",
        created_at: new Date(),
        created_by: null,
        last_updated_at: new Date(),
        last_updated_by: null,
        last_viewed_at: new Date(),
        last_viewed_by: null,
        published_at: new Date(),
        published_by: null,
        
    }
];

export default PurchaseOrderPageLayoutsData;
// This constants file is a representation of how data for item fields will be recieved by the ProHelper database once linked.
import uniqid from 'uniqid';
import * as FieldTypes from '../../types/FieldTypes';
import * as DatabaseTypes from '../../types/DatabaseTypes';
import * as SpecialTypes from '../../types/SpecialTypes';
import * as FilterOperatorTypeIdenifiers from '../../filtering/operators/FilterOperatorTypeIdentifiers';
import * as LookupRelationshipTypes from '../../types/LookupRelationshipTypes';
import * as SortingMethods from '../../sorting/SortingMethods';

const ItemFieldsData = [
    // Database ID
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'items',
        sync_token: 1,
        label: "Database ID",
        identifier: "id",
        field_type: FieldTypes.ID,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "The ID of this Item in the ProHelper Database.",
        help_text: "The ID of this Item in the ProHelper Database.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: true,
        is_editable: false,
        is_searchable: false,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Company
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'items',
        sync_token: 1,
        label: "Company",
        identifier: "company",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "The organization in ProHelper that this Item is apart of.",
        help_text: "The organization for this Item in ProHelper.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: true,
        is_editable: false,
        is_searchable: false,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Label
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'items',
        sync_token: 1,
        label: "Label",
        identifier: "label",
        field_type: FieldTypes.LONG_TEXT,
        data_type: DatabaseTypes.TEXT,
        special_type: SpecialTypes.MAIN_LABEL,
        description: "The main product or service label.",
        help_text: "The main product or service label.",
        default_value: null,
        placeholder_text: 'Enter a label...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 1,
        is_required: true,
        is_editable: true,
        is_searchable: true,
        search_priority: 'A',
        is_filterable: false,
        filter_order: null,
        is_sortable: true,
        sort_order: 1,
        sort_method: SortingMethods.ALPHANUMERIC,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Short Label
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'items',
        sync_token: 1,
        label: "Short Label",
        identifier: "short_label",
        field_type: FieldTypes.SHORT_TEXT,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        description: "A short product or service label.",
        help_text: "A short product or service label.",
        default_value: null,
        placeholder_text: 'Enter a short label...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 2,
        is_required: false,
        is_editable: true,
        is_searchable: true,
        search_priority: 'A',
        is_filterable: false,
        filter_order: null,
        is_sortable: true,
        sort_order: 2,
        sort_method: SortingMethods.ALPHANUMERIC,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Description
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'items',
        sync_token: 1,
        label: "Description",
        identifier: "description",
        field_type: FieldTypes.LONG_TEXT,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        description: "A description for the Item.",
        help_text: "Information about this Item.",
        default_value: null,
        placeholder_text: 'Add a description...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 3,
        is_required: false,
        is_editable: true,
        is_searchable: true,
        search_priority: 'B',
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: 3,
        sort_method: SortingMethods.ALPHANUMERIC,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Default Price
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'items',
        sync_token: 1,
        label: "Default Price",
        identifier: "default_price",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Represents a default price for the product or service.",
        help_text: "Represents a default price for this product or service.",
        default_value: null,
        placeholder_text: 'Select an default price...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 4,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 1,
        is_sortable: true,
        sort_order: 4,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "prices",
            filters: [
                {
                    fields: ["prices.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            label: "True",
                            type: "value",
                            data: true,
                            data_type: 'boolean',
                            field_type: "checkbox",
                            input: true
                        }
                    ]
                }
            ],
            display_field: "label",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Images
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'items',
        sync_token: 1,
        label: "Images",
        identifier: "images",
        field_type: FieldTypes.IMAGE,
        data_type: DatabaseTypes.JSONB_ARRAY,
        special_type: null,
        description: "Represents an image(s) of a product or service. Can be used to print or email to customers.",
        help_text: "Represents an image(s) of a product or service. Can be used to print or email to customers.",
        default_value: null,
        placeholder_text: 'Select some images...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 5,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 3,
        is_sortable: true,
        sort_order: 5,
        sort_method: null,
        is_multi: true,
        is_async: true,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Type
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'items',
        sync_token: 1,
        label: "Type",
        identifier: "type",
        field_type: FieldTypes.TYPE_DROPDOWN,
        data_type: DatabaseTypes.BIGINT,
        special_type: SpecialTypes.MAIN_TYPE,
        description: "Represents the object type of a product or service.",
        help_text: "Represents the object type for this product or service.",
        default_value: null,
        placeholder_text: 'Select a Type...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 6,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 2,
        is_sortable: true,
        sort_order: 5,
        sort_method: SortingMethods.CUSTOM_SORT_ORDER,
        is_multi: false,
        is_async: true,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Sub Type
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'items',
        sync_token: 1,
        label: "Sub Type",
        identifier: "sub_type",
        field_type: FieldTypes.TYPE_DROPDOWN,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Represents the object type of a product or service.",
        help_text: "Represents the object type for this product or service.",
        default_value: null,
        placeholder_text: 'Select a Type...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 7,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 3,
        is_sortable: true,
        sort_order: 6,
        sort_method: SortingMethods.CUSTOM_SORT_ORDER,
        is_multi: false,
        is_async: true,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Tags
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'items',
        sync_token: 1,
        label: "Tags",
        identifier: "tags",
        field_type: FieldTypes.CUSTOM_DROPDOWN,
        data_type: DatabaseTypes.TEXT_ARRAY,
        special_type: null,
        description: "Label(s) attached to a Item for extra identification.",
        help_text: "Tag(s) are labels attached to this Item for extra identification.",
        default_value: null,
        placeholder_text: 'Add some Item Tags...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 8,
        is_required: false,
        is_editable: true,
        is_searchable: true,
        search_priority: 'A',
        is_filterable: true,
        filter_order: 4,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: true,
        is_async: true,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: {
            identifier: "items.tags",
            object_identifier: "items",
            type: "text_dropdown"
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Item ID
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'items',
        sync_token: 1,
        label: "Item ID",
        identifier: "item_id",
        field_type: FieldTypes.PUBLIC_ID,
        data_type: DatabaseTypes.TEXT,
        special_type: SpecialTypes.PUBLIC_ID,
        description: "The unique ID for a Item shown to publically to items. Item Prefix is 'item_'.",
        help_text: "The unique ID for a Item shown to publically to items.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 9,
        is_required: true,
        is_editable: false,
        is_searchable: true,
        search_priority: 'A',
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Preferred Vendor
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'items',
        sync_token: 1,
        label: "Preferred Vendor",
        identifier: "preferred_vendor",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Represents a preferred vendor's account to purchase a product or service from.",
        help_text: "Represents a preferred vendor's account to purchase a product or service from.",
        default_value: null,
        placeholder_text: 'Select a Vendor Account...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 10,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 5,
        is_sortable: true,
        sort_order: 7,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Inventory Information",
            identifier: "inventory_information",
            order: 2
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "accounts",
            filters: [
                {
                    fields: ["accounts.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            label: "True",
                            type: "value",
                            data: true,
                            data_type: 'boolean',
                            field_type: "checkbox",
                            input: true
                        }
                    ]
                }
            ],
            display_field: "name",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Default Quantity
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'items',
        sync_token: 1,
        label: "Default Quantity",
        identifier: "default_quantity",
        field_type: FieldTypes.DECIMAL,
        data_type: DatabaseTypes.DECIMAL,
        special_type: null,
        description: "Represents a default quantity for a product or service.",
        help_text: "Represents a default quantity for this product or service.",
        default_value: null,
        placeholder_text: 'Select a default quantity...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 11,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 6,
        is_sortable: true,
        sort_order: 8,
        sort_method: SortingMethods.NUMERIC,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Inventory Information",
            identifier: "inventory_information",
            order: 2
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Track Quantity on Hand
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'items',
        sync_token: 1,
        label: "Track Quantity on Hand",
        identifier: "track_quantity_on_hand",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "If true, the item's quantity will be tracked with an inventory ledger.",
        help_text: "If true, this item's quantity will be tracked with an inventory ledger.",
        default_value: {
            data_type: DatabaseTypes.BOOLEAN,
            data: false
        },
        placeholder_text: 'Select a default quantity...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 12,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 7,
        is_sortable: true,
        sort_order: 9,
        sort_method: SortingMethods.BOOLEAN,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Inventory Information",
            identifier: "inventory_information",
            order: 2
        },
        checkbox_labels: { true: "Track Quantity", false: "Do Not Track Quantity" },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Quantity on Hand
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'items',
        sync_token: 1,
        label: "Quantity on Hand",
        identifier: "quantity_on_hand",
        field_type: FieldTypes.DECIMAL,
        data_type: DatabaseTypes.DECIMAL,
        special_type: null,
        description: "Represents the quantity of a product or service in inventory.",
        help_text: "Represents the quantity of this product or service in inventory.",
        default_value: null,
        placeholder_text: 'Select the quantity on hand...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 13,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 8,
        is_sortable: true,
        sort_order: 10,
        sort_method: SortingMethods.NUMERIC,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Inventory Information",
            identifier: "inventory_information",
            order: 2
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Is Quantity Editable
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'items',
        sync_token: 1,
        label: "Is Quantity Editable",
        identifier: "is_quantity_editable",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "Represents if users can edit the quantity on a Line Item. If True, users can edit.",
        help_text: "Represents if users can edit the quantity on a Line Item. If True, users can edit.",
        default_value: null,
        placeholder_text: 'Is the quantity editable...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 14,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 9,
        is_sortable: true,
        sort_order: 11,
        sort_method: SortingMethods.BOOLEAN,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Inventory Information",
            identifier: "inventory_information",
            order: 2
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Inventory Start Date
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'items',
        sync_token: 1,
        label: "Inventory Start Date",
        identifier: "inventory_start_date",
        field_type: FieldTypes.DATE_TIME,
        data_type: DatabaseTypes.TIMESTAMPTZ,
        special_type: null,
        description: "Date of opening balance for the inventory transaction. Required for inventory items.",
        help_text: "Date of opening balance for the inventory transaction. Required for inventory items.",
        default_value: null,
        placeholder_text: 'When is the inventory tracking start date...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 15,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 10,
        is_sortable: true,
        sort_order: 12,
        sort_method: SortingMethods.CHRONOLOGICAL,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Inventory Information",
            identifier: "inventory_information",
            order: 2
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Unit Label
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'items',
        sync_token: 1,
        label: "Unit Label",
        identifier: "unit_label",
        field_type: FieldTypes.SHORT_TEXT,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        description: "A label that represents units of this product.",
        help_text: "A label that represents units of this product.",
        default_value: null,
        placeholder_text: 'Enter a unit label...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 16,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        is_filterable: false,
        filter_order: null,
        is_sortable: true,
        sort_order: 13,
        sort_method: SortingMethods.ALPHANUMERIC,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Inventory Information",
            identifier: "inventory_information",
            order: 2
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // SKU
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'items',
        sync_token: 1,
        label: "SKU",
        identifier: "sku",
        field_type: FieldTypes.SHORT_TEXT,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        description: "The stock keeping unit (SKU) for this Item. This is a company-defined identifier for an item or product used in tracking inventory.",
        help_text: "The stock keeping unit (SKU) for this Item. This is a company-defined identifier for an item or product used in tracking inventory.",
        default_value: null,
        placeholder_text: 'Enter a sku...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 17,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        is_filterable: false,
        filter_order: null,
        is_sortable: true,
        sort_order: 14,
        sort_method: SortingMethods.ALPHANUMERIC,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Inventory Information",
            identifier: "inventory_information",
            order: 2
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Barcode Photo
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'items',
        sync_token: 1,
        label: "Barcode Photo",
        identifier: "barcode_photo",
        field_type: FieldTypes.IMAGE,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "Represents a bar code image for a product or service.",
        help_text: "Represents a bar code image for this product or service.",
        default_value: null,
        placeholder_text: 'Enter a barcode image...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 18,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Inventory Information",
            identifier: "inventory_information",
            order: 2
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // QR Code Photo
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'items',
        sync_token: 1,
        label: "QR Code Photo",
        identifier: "qr_code_photo",
        field_type: FieldTypes.IMAGE,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "Represents a QR Code image for a product or service.",
        help_text: "Represents a QR Code image for this product or service.",
        default_value: null,
        placeholder_text: 'Enter a barcode image...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 19,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Inventory Information",
            identifier: "inventory_information",
            order: 2
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Inventory Ledger
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'items',
        sync_token: 1,
        label: "Inventory Ledger",
        identifier: "inventory_ledger",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Reference to the Ledger that records the inventory of this item in the company.",
        help_text: "Reference to the Ledger that records the inventory of this item in the company.",
        default_value: null,
        placeholder_text: 'Select a Ledger...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 21,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 12,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Billing Information",
            identifier: "billing_information",
            order: 3
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "ledgers",
            filters: [
                {
                    fields: ["ledgers.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            label: "True",
                            type: "value",
                            data: true,
                            data_type: 'boolean',
                            field_type: "checkbox",
                            input: true
                        }
                    ]
                }
            ],
            display_field: "subject",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Revenue Ledger
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'items',
        sync_token: 1,
        label: "Revenue Ledger",
        identifier: "revenue_ledger",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Reference to the Ledger that records the proceeds from the sale of this item.",
        help_text: "Reference to the Ledger that records the proceeds from the sale of this item.",
        default_value: null,
        placeholder_text: 'Select a Ledger...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 22,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 13,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Billing Information",
            identifier: "billing_information",
            order: 3
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "ledgers",
            filters: [
                {
                    fields: ["ledgers.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            label: "True",
                            type: "value",
                            data: true,
                            data_type: 'boolean',
                            field_type: "checkbox",
                            input: true
                        }
                    ]
                }
            ],
            display_field: "subject",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Expense Ledger
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'items',
        sync_token: 1,
        label: "Expense Ledger",
        identifier: "expense_ledger",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Reference to the Ledger that records the proceeds from the sale of this item.",
        help_text: "Reference to the Ledger that records the proceeds from the sale of this item.",
        default_value: null,
        placeholder_text: 'Select a Ledger...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 23,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 14,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Billing Information",
            identifier: "billing_information",
            order: 3
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "ledgers",
            filters: [
                {
                    fields: ["ledgers.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            label: "True",
                            type: "value",
                            data: true,
                            data_type: 'boolean',
                            field_type: "checkbox",
                            input: true
                        }
                    ]
                }
            ],
            display_field: "subject",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Statement Descriptor
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'items',
        sync_token: 1,
        label: "Statement Descriptor",
        identifier: "statement_descriptor",
        field_type: FieldTypes.SHORT_TEXT,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        description: "A description that appears on a financial transaction statement to provide information about the nature of the transaction.",
        help_text: "A description that appears on a financial transaction statement to provide information about the nature of the transaction.",
        default_value: null,
        placeholder_text: 'Enter a statement descriptor...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 24,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Billing Information",
            identifier: "billing_information",
            order: 3
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Is Taxable
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'items',
        sync_token: 1,
        label: "Is Taxable",
        identifier: "is_taxable",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "If true, transactions for the item are taxable.",
        help_text: "If true, transactions for this item are taxable.",
        default_value: {
            data_type: DatabaseTypes.BOOLEAN,
            data: true
        },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 25,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 15,
        is_sortable: true,
        sort_order: 15,
        sort_method: SortingMethods.BOOLEAN,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Item Checks",
            identifier: "item_checks",
            order: 4
        },
        checkbox_labels: { true: "Taxable", false: "Not Taxable" },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Is Sales Tax Included
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'items',
        sync_token: 1,
        label: "Is Sales Tax Included",
        identifier: "is_sales_tax_included",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "If true, if the sales tax is included in the item amount, and therefore is not calculated for the transaction.",
        help_text: "If true, if the sales tax is included in the item amount, and therefore is not calculated for the transaction.",
        default_value: {
            data_type: DatabaseTypes.BOOLEAN,
            data: false
        },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 26,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 16,
        is_sortable: true,
        sort_order: 16,
        sort_method: SortingMethods.BOOLEAN,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Item Checks",
            identifier: "item_checks",
            order: 4
        },
        checkbox_labels: { true: "Sales Tax Included", false: "Sales Tax Not Included" },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Is Shippable
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'items',
        sync_token: 1,
        label: "Is Shippable",
        identifier: "is_shippable",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "If true, the product is able to shipped.",
        help_text: "If true, this product is able to shipped.",
        default_value: {
            data_type: DatabaseTypes.BOOLEAN,
            data: false
        },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 27,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 17,
        is_sortable: true,
        sort_order: 17,
        sort_method: SortingMethods.BOOLEAN,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Item Checks",
            identifier: "item_checks",
            order: 4
        },
        checkbox_labels: { true: "Shippable", false: "Not Shippable" },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Is Service
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'items',
        sync_token: 1,
        label: "Is Service",
        identifier: "is_service",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "If true, the item is a service and not a product.",
        help_text: "If true, this item is a service and not a product.",
        default_value: {
            data_type: DatabaseTypes.BOOLEAN,
            data: false
        },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 28,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 18,
        is_sortable: true,
        sort_order: 18,
        sort_method: SortingMethods.BOOLEAN,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Item Checks",
            identifier: "item_checks",
            order: 4
        },
        checkbox_labels: { true: "Service", false: "Product" },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Is Active
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'items',
        sync_token: 1,
        label: "Is Active",
        identifier: "is_active",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "Represents if this Item is currently active; able for users to use it.",
        help_text: "Represents if this Item is currently active; able for users to use it.",
        default_value: {
            data_type: DatabaseTypes.BOOLEAN,
            data: true
        },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 29,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 19,
        is_sortable: true,
        sort_order: 19,
        sort_method: SortingMethods.BOOLEAN,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Item Checks",
            identifier: "item_checks",
            order: 4
        },
        checkbox_labels: { true: "Active", false: "Not Active" },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Is Archived
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'items',
        sync_token: 1,
        label: "Is Archived",
        identifier: "is_archived",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "If true, this Item is archived and not shown in normal use; only able to recover in the trash.",
        help_text: "If true, this Item is archived and not shown in normal use; only able to recover in the trash.",
        default_value: {
            data_type: DatabaseTypes.BOOLEAN,
            data: false
        },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: { true: "Archived", false: "Not Archived" },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Is Deleted
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'items',
        sync_token: 1,
        label: "Is Deleted",
        identifier: "is_deleted",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "If true, this Item is deleted and non-recoverable after 180 days.",
        help_text: "If true, this Item is deleted and non-recoverable after 180 days.",
        default_value: {
            data_type: DatabaseTypes.BOOLEAN,
            data: false
        },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: { true: "Deleted", false: "Not Deleted" },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Is Live
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'items',
        sync_token: 1,
        label: "Is Live",
        identifier: "is_live",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "If true, this Item is live for the organization. Not a record in testing mode.",
        help_text: "If true, this Item is live for the organization. Not a record in testing mode.",
        default_value: {
            data_type: DatabaseTypes.BOOLEAN,
            data: true
        },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: { true: "Live Data", false: "Test Data" },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Is Draft
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'items',
        sync_token: 1,
        label: "Is Draft",
        identifier: "is_draft",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "If true, this Item is a draft Item; Saved details, but not ready for release yet.",
        help_text: "If true, this Item is a draft Item; Saved details, but not ready for release yet.",
        default_value: {
            data_type: DatabaseTypes.BOOLEAN,
            data: false
        },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: { true: "Draft", false: "Public" },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Is Template
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'items',
        sync_token: 1,
        label: "Is Template",
        identifier: "is_template",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "If true, this Item is a template for creating future Items faster.",
        help_text: "A template is used for creating future Items faster.",
        default_value: {
            data_type: DatabaseTypes.BOOLEAN,
            data: false
        },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: { true: "Template", false: "Not Template" },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Is Sub Item
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'items',
        sync_token: 1,
        label: "Is Sub Item",
        identifier: "is_sub_item",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "If true, this is a sub item. If false or null, this is a top-level item.",
        help_text: "Represents whether an Item is a child Item to another Item in the system.",
        default_value: {
            data_type: DatabaseTypes.BOOLEAN,
            data: false
        },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 30,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 20,
        is_sortable: true,
        sort_order: 20,
        sort_method: SortingMethods.BOOLEAN,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Item Checks",
            identifier: "item_checks",
            order: 4
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: { true: "Sub Item", false: "Not Sub Item" },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Level
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'items',
        sync_token: 1,
        label: "Level",
        identifier: "level",
        field_type: FieldTypes.INTEGER,
        data_type: DatabaseTypes.INTEGER,
        special_type: null,
        description: "Represents the depth in the overall Item structure. (Parent Item, Sub Items) (Max Level: 3)",
        help_text: "The depth of the Item in the overall parent-child Item structure.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 31,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 21,
        is_sortable: true,
        sort_order: 21,
        sort_method: SortingMethods.NUMERIC,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 5
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Parent Item
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'items',
        sync_token: 1,
        label: "Parent Item",
        identifier: "parent",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "If this Item is a sub-Item, then this represents a link to the parent Item.",
        help_text: "The parent Item of this sub-Item.",
        default_value: null,
        placeholder_text: "Select a parent Item...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 32,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 22,
        is_sortable: true,
        sort_order: 22,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 5
        },
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "items",
            filters: [
                {
                    fields: ["items.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            label: "True",
                            type: "value",
                            data: true,
                            data_type: 'boolean',
                            field_type: "checkbox",
                            input: true
                        }
                    ]
                }
            ],
            display_field: "subject",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Created At
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'items',
        sync_token: 1,
        label: "Created At",
        identifier: "created_at",
        field_type: FieldTypes.DATE_TIME,
        data_type: DatabaseTypes.TIMESTAMPTZ,
        special_type: null,
        description: "Represents the date/time the Item was created. System generated timestamp.",
        help_text: "The date/time when the Item was created.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 33,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 23,
        is_sortable: true,
        sort_order: 23,
        sort_method: SortingMethods.CHRONOLOGICAL,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 5
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Created By
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'items',
        sync_token: 1,
        label: "Created By",
        identifier: "created_by",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Represents the User who created the Item. System generated.",
        help_text: "The User who created an Item.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 34,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 24,
        is_sortable: true,
        sort_order: 24,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 5
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "users",
            filters: [
                {
                    fields: ["users.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            label: "True",
                            type: "value",
                            data: true,
                            data_type: 'boolean',
                            field_type: "checkbox",
                            input: true
                        }
                    ]
                }
            ],
            display_field: "name",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Last Updated At
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'items',
        sync_token: 1,
        label: "Last Updated At",
        identifier: "last_updated_at",
        field_type: FieldTypes.DATE_TIME,
        data_type: DatabaseTypes.TIMESTAMPTZ,
        special_type: null,
        description: "Represents the date/time when the Item was last updated. System generated timestamp.",
        help_text: "The date/time when the Item was last updated.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 35,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 25,
        is_sortable: true,
        sort_order: 25,
        sort_method: SortingMethods.CHRONOLOGICAL,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 5
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Last Updated By
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'items',
        sync_token: 1,
        label: "Last Updated By",
        identifier: "last_updated_by",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Represents the User who last updated the Item. System generated.",
        help_text: "The User who last updated the Item.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 36,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 26,
        is_sortable: true,
        sort_order: 26,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 5
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "users",
            filters: [
                {
                    fields: ["users.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            label: "True",
                            type: "value",
                            data: true,
                            data_type: 'boolean',
                            field_type: "checkbox",
                            input: true
                        }
                    ]
                }
            ],
            display_field: "name",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Last Sold At
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'items',
        sync_token: 1,
        label: "Last Sold At",
        identifier: "last_sold_at",
        field_type: FieldTypes.DATE_TIME,
        data_type: DatabaseTypes.TIMESTAMPTZ,
        special_type: null,
        description: "Represents the date and time a customer last bought the product or service.",
        help_text: "The date/time when the Item was last sold at.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 37,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 27,
        is_sortable: true,
        sort_order: 27,
        sort_method: SortingMethods.CHRONOLOGICAL,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 5
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Currency
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'items',
        sync_token: 1,
        label: "Currency",
        identifier: "currency",
        field_type: FieldTypes.TEXT_DROPDOWN,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        description: "The preferred currency for a Item.",
        help_text: "The preferred currency for a Item.",
        default_value: { data_type: 'text', data: 'usd' },
        placeholder_text: "Select a currency...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 38,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 28,
        is_sortable: true,
        sort_order: 28,
        sort_method: SortingMethods.LINKED_ALPHANUMERIC,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 5
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: {
            identifier: "static.currencies",
            object_identifier: "static",
            type: "text_dropdown"
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Language
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'items',
        sync_token: 1,
        label: "Language",
        identifier: "language",
        field_type: FieldTypes.TEXT_DROPDOWN,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        description: "The preferred language for a Item.",
        help_text: "The preferred language for a Item.",
        default_value: { data_type: 'text', data: 'en' },
        placeholder_text: "Select a language...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 39,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 29,
        is_sortable: true,
        sort_order: 29,
        sort_method: SortingMethods.LINKED_ALPHANUMERIC,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 5
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: {
            identifier: "static.languages",
            object_identifier: "static",
            type: "text_dropdown"
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Popup Alert
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'items',
        sync_token: 1,
        label: "Popup Alert",
        identifier: "popup_alert",
        field_type: FieldTypes.ALERT,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "Shows an important message as a banner or dialog when viewing this record in detail.",
        help_text: "An important alert message shown when someone views an item.",
        default_value: null,
        placeholder_text: "Enter a popup message to show to items...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 40,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 30,
        is_sortable: true,
        sort_order: 30,
        sort_method: SortingMethods.NESTED_FIELD,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Item Alerts",
            identifier: "item_alerts",
            order: 5
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Archived Alert
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'items',
        sync_token: 1,
        label: "Archived Alert",
        identifier: "archived_alert",
        field_type: FieldTypes.ALERT,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "Shows an alert why the Item was archived when someone views an archived record.",
        help_text: "Shows an alert why the Item was archived when someone views an archived record.",
        default_value: null,
        placeholder_text: "Enter a reason for this Item's archival...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Hosted Item URL
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'items',
        sync_token: 1,
        label: "Hosted Item URL",
        identifier: "hosted_item_url",
        field_type: FieldTypes.URL,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        description: "Represents a public link to an item which can be viewed by the customer.",
        help_text: "Represents a public link to an item which can be viewed by the customer.",
        default_value: null,
        placeholder_text: "Enter a Item URL...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 41,
        is_required: false,
        is_editable: false,
        is_searchable: true,
        search_priority: 'B',
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 5
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Domain
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'items',
        sync_token: 1,
        label: "Domain",
        identifier: "domain",
        field_type: FieldTypes.SHORT_TEXT,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        description: "Represents the platform/domain the Item was created on. Can be created by ProHelper or other platforms through integrations.",
        help_text: "Represents the platform/domain the Item was created on. Can be created by ProHelper or other platforms through integrations.",
        default_value: null,
        placeholder_text: "Enter a Domain...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 42,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 5
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Suggested Item Action
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'items',
        sync_token: 1,
        label: "Suggested Item Action",
        identifier: "suggested_action",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "The first shown item action for this Item.",
        help_text: "The first shown item action for this Item.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: null,
        lookup_relationship_options: {
            type: LookupRelationshipTypes.INDEPENDENT,
            object_identifier: "actions",
            filters: [
                {
                    fields: ["actions.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            type: "value",
                            label: 'True',
                            input: true,
                            data: true,
                            data_type: 'boolean',
                            status: 'valid'
                        }
                    ]
                },
                {
                    fields: ["actions.object_identifier"],
                    field_types: ["id"],
                    operator: "equal",
                    values: [
                        {
                            type: "value",
                            label: 'Items',
                            input: 'items',
                            data: 'items',
                            data_type: 'text',
                            status: 'valid'
                        }
                    ]
                }
            ],
            display_field: "label",
            strict_filter_lookups: false,
            is_related_record_editable: false,
            is_related_record_creatable: false
        },
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Sync Token
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'items',
        sync_token: 1,
        label: "Sync Token",
        identifier: "sync_token",
        field_type: FieldTypes.INTEGER,
        data_type: DatabaseTypes.INTEGER,
        special_type: null,
        description: "The current sync token for this Item; Only the most current sync token can be used to write data to avoid conflits.",
        help_text: null,
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Metadata
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'items',
        sync_token: 1,
        label: "Metadata",
        identifier: "metadata",
        field_type: FieldTypes.JSON,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "Some extra metadata that can be added to a Item.",
        help_text: null,
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Item Settings
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'items',
        sync_token: 1,
        label: "Item Settings",
        identifier: "item_settings",
        field_type: FieldTypes.JSON,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "Represents some item settings for the Item.",
        help_text: null,
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Notifications
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'items',
        sync_token: 1,
        label: "Notifications",
        identifier: "notifications",
        field_type: FieldTypes.JSON,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "Represents some notifcations for the Item.",
        help_text: null,
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Custom Fields
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'items',
        sync_token: 1,
        label: "Custom Fields",
        identifier: "custom_fields",
        field_type: FieldTypes.JSON,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "Custom fields for the Item.",
        help_text: null,
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Settings
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'items',
        sync_token: 1,
        label: "Settings",
        identifier: "settings",
        field_type: FieldTypes.JSON,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "Represents some settings for the Item.",
        help_text: null,
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
];

export default ItemFieldsData;

// This constants file is a representation of how data will be recieved by the ProHelper database once linked.
import uniqid from 'uniqid';
import * as ROUTES from '../navigation/routes';

const NavigationItemsData = [
    // Home
    {
        public_id: uniqid("nav_"),
        sync_token: 1,
        identifier: "home",
        label: "Home",
        icon: "home-lg",
        description: "Home Screen",
        is_active: true,
        is_visible: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_locked: false,
        is_section: true,
        route: ROUTES.HOME,
        order: 1,
        group: {
            label: "Home",
            identifier: "home",
            order: 1
        },
        sub_group: {
            label: "Home",
            identifier: "home",
            order: 1
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Schedule Area
    {
        public_id: uniqid("nav_"),
        sync_token: 1,
        identifier: "schedule",
        label: "Schedule",
        icon: "calendar",
        description: "Scheduling and planning screen (App Page Layout).",
        is_active: true,
        is_visible: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_locked: false,
        is_section: true,
        route: ROUTES.SCHEDULE,
        order: 1,
        group: {
            label: "Schedule",
            identifier: "schedule",
            order: 2
        },
        sub_group: {
            label: "Schedule",
            identifier: "schedule",
            order: 1
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Work Area
    {
        public_id: uniqid("nav_"),
        sync_token: 1,
        identifier: "work",
        label: "Work",
        icon: "person-digging",
        description: "Work overview screen (App Page Layout).",
        is_active: true,
        is_visible: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_locked: false,
        is_section: true,
        route: ROUTES.WORK,
        order: 1,
        group: {
            label: "Work",
            identifier: "work",
            order: 3
        },
        sub_group: {
            label: "Work Overview",
            identifier: "overview",
            order: 1
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Requests
    {
        public_id: uniqid("nav_"),
        sync_token: 1,
        identifier: "requests",
        label: "Requests",
        icon: "inbox",
        description: "Requests store details about a client asking about a potential job, product, or service that can be handled by the company in the future.",
        is_active: true,
        is_visible: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_locked: false,
        is_section: false,
        route: "/requests",
        order: 2,
        group: {
            label: "Work",
            identifier: "work",
            order: 3
        },
        sub_group: {
            label: "Requests",
            identifier: "requests",
            order: 1
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Visits
    {
        public_id: uniqid("nav_"),
        sync_token: 1,
        identifier: "visits",
        label: "Visits",
        icon: "person-dolly",
        description: "Visits are record of scheduled work stops, linked to various work items such as jobs, requests, estimates, and route stops",
        is_active: true,
        is_visible: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_locked: false,
        is_section: false,
        route: "/visits",
        order: 3,
        group: {
            label: "Work",
            identifier: "work",
            order: 3
        },
        sub_group: {
            label: "Visits",
            identifier: "visits",
            order: 2
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Tasks
    {
        public_id: uniqid("nav_"),
        sync_token: 1,
        identifier: "tasks",
        label: "Tasks",
        icon: "list-check",
        description: "Tasks are small work items to be handled by Team members.",
        is_active: true,
        is_visible: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_locked: false,
        is_section: false,
        route: "/tasks",
        order: 3,
        group: {
            label: "Work",
            identifier: "work",
            order: 3
        },
        sub_group: {
            label: "Tasks",
            identifier: "tasks",
            order: 4
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Estimates
    {
        public_id: uniqid("nav_"),
        sync_token: 1,
        identifier: "estimates",
        label: "Estimates",
        icon: "envelope-open-dollar",
        description: "Estimates are a potential jobs that can be sent to the client for review and approval beforehand.",
        is_active: true,
        is_visible: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_locked: false,
        is_section: false,
        route: "/estimates",
        order: 2,
        group: {
            label: "Work",
            identifier: "work",
            order: 3
        },
        sub_group: {
            label: "Estimates",
            identifier: "estimates",
            order: 4
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Jobs
    {
        public_id: uniqid("nav_"),
        sync_token: 1,
        identifier: "jobs",
        label: "Jobs",
        icon: "briefcase",
        description: "Jobs are one-time or recurring work items that contains products or services as line items set at the transaction price.",
        is_active: true,
        is_visible: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_locked: false,
        is_section: false,
        route: "/jobs",
        order: 4,
        group: {
            label: "Work",
            identifier: "work",
            order: 3
        },
        sub_group: {
            label: "Jobs",
            identifier: "jobs",
            order: 5
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Routes
    {
        public_id: uniqid("nav_"),
        sync_token: 1,
        identifier: "routes",
        label: "Routes",
        icon: "route",
        description: "Routes are a collection of one-time or recurring jobs that can be assigned to a driver.",
        is_active: true,
        is_visible: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_locked: false,
        is_section: false,
        route: "/routes",
        order: 5,
        group: {
            label: "Work",
            identifier: "work",
            order: 3
        },
        sub_group: {
            label: "Routes",
            identifier: "routes",
            order: 6
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Clients Area
    {
        public_id: uniqid("nav_"),
        sync_token: 1,
        identifier: "clients",
        label: "Clients",
        icon: "handshake",
        description: "Clients overview screen (App Page Layout).",
        is_active: true,
        is_visible: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_locked: false,
        is_section: true,
        route: ROUTES.CLIENTS,
        order: 1,
        group: {
            label: "Clients",
            identifier: "clients",
            order: 4
        },
        sub_group: {
            label: "Clients Overview",
            identifier: "overview",
            order: 1
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Acounts
    {
        public_id: uniqid("nav_"),
        sync_token: 1,
        identifier: "accounts",
        label: "Accounts",
        icon: "sitemap",
        description: "Accounts store all the information about customers, vendors, and other people or companies.",
        is_active: true,
        is_visible: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_locked: false,
        is_section: false,
        route: "/accounts",
        order: 2,
        group: {
            label: "Clients",
            identifier: "clients",
            order: 4
        },
        sub_group: {
            label: "Accounts",
            identifier: "accounts",
            order: 2
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Contacts
    {
        public_id: uniqid("nav_"),
        sync_token: 1,
        identifier: "contacts",
        label: "Contacts",
        icon: "address-book",
        description: "Contacts store all the information about an individual person related to an account.",
        is_active: true,
        is_visible: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_locked: false,
        is_section: false,
        route: "/contacts",
        order: 3,
        group: {
            label: "Clients",
            identifier: "clients",
            order: 4
        },
        sub_group: {
            label: "Contacts",
            identifier: "contacts",
            order: 3
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Properties
    {
        public_id: uniqid("nav_"),
        sync_token: 1,
        identifier: "properties",
        label: "Properties",
        icon: "house-building",
        description: "Properties store all the information about an individual location.",
        is_active: true,
        is_visible: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_locked: false,
        is_section: false,
        route: "/properties",
        order: 4,
        group: {
            label: "Clients",
            identifier: "clients",
            order: 4
        },
        sub_group: {
            label: "Properties",
            identifier: "properties",
            order: 4
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Billing Area
    {
        public_id: uniqid("nav_"),
        sync_token: 1,
        identifier: "billing",
        label: "Billing",
        icon: "wallet",
        description: "Billing overview screen (App Page Layout).",
        is_active: true,
        is_visible: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_locked: false,
        is_section: true,
        route: ROUTES.BILLING,
        order: 1,
        group: {
            label: "Billing",
            identifier: "billing",
            order: 5
        },
        sub_group: {
            label: "Billing Overview",
            identifier: "overview",
            order: 1
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Invoices
    {
        public_id: uniqid("nav_"),
        sync_token: 1,
        identifier: "invoices",
        label: "Invoices",
        icon: "file-invoice-dollar",
        description: "Invoices are a a list of goods sent or services provided, with a statement of the sum due for these; a bill to the customer.",
        is_active: true,
        is_visible: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_locked: false,
        is_section: false,
        route: "/invoices",
        order: 3,
        group: {
            label: "Billing",
            identifier: "billing",
            order: 5
        },
        sub_group: {
            label: "Invoices",
            identifier: "invoices",
            order: 3
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Subscriptions
    {
        public_id: uniqid("nav_"),
        sync_token: 1,
        identifier: "subscriptions",
        label: "Subscriptions",
        icon: "money-bill-transfer",
        description: "Subscriptions create recurring invoices based on a schedule that are sent to Customers.",
        is_active: true,
        is_visible: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_locked: false,
        is_section: false,
        route: "/subscriptions",
        order: 4,
        group: {
            label: "Billing",
            identifier: "billing",
            order: 5
        },
        sub_group: {
            label: "Subscriptions",
            identifier: "subscriptions",
            order: 4
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Payments
    {
        public_id: uniqid("nav_"),
        sync_token: 1,
        identifier: "payments",
        label: "Payments",
        icon: "money-check-dollar",
        description: "Payments are records of collected money transactions from clients relating to previous invoices or their balance.",
        is_active: true,
        is_visible: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_locked: false,
        is_section: false,
        route: "/payments",
        order: 5,
        group: {
            label: "Billing",
            identifier: "billing",
            order: 5
        },
        sub_group: {
            label: "Payments",
            identifier: "payments",
            order: 5
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Purchase Orders
    {
        public_id: uniqid("nav_"),
        sync_token: 1,
        identifier: "purchase_orders",
        label: "Purchase Orders",
        icon: "receipt",
        description: "A Purchase Order is statement of items that is purchased by the company from vendors.",
        is_active: true,
        is_visible: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_locked: false,
        is_section: false,
        route: "/purchase_orders",
        order: 6,
        group: {
            label: "Billing",
            identifier: "billing",
            order: 5
        },
        sub_group: {
            label: "Purchase Orders",
            identifier: "purchase_orders",
            order: 6
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Items
    {
        public_id: uniqid("nav_"),
        sync_token: 1,
        identifier: "items",
        label: "Items",
        icon: "store",
        description: "Products & Services that the business offers clients. Prices are independant of Items.",
        is_active: true,
        is_visible: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_locked: false,
        is_section: false,
        route: "/items",
        order: 7,
        group: {
            label: "Billing",
            identifier: "billing",
            order: 5
        },
        sub_group: {
            label: "Items",
            identifier: "items",
            order: 7
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Audit Area
    {
        public_id: uniqid("nav_"),
        sync_token: 1,
        identifier: "audit",
        label: "Audit",
        icon: "chart-line-up",
        description: "Audit overview screen (App Page Layout).",
        is_active: true,
        is_visible: true,   
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_locked: false,
        is_section: true,
        route: ROUTES.AUDIT,
        order: 1,
        group: {
            label: "Audit",
            identifier: "audit",
            order: 6
        },
        sub_group: {
            label: "Audit Overview",
            identifier: "overview",
            order: 1
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Ledgers
    {
        public_id: uniqid("nav_"),
        sync_token: 1,
        identifier: "ledgers",
        label: "Ledgers",
        icon: "memo-pad",
        description: "A Ledger is a record-keeping tool that tracks transactions for a specific reason, to provide a clear overview of financial activities and balances.",
        is_active: true,
        is_visible: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_locked: false,
        is_section: false,
        route: ROUTES.LEDGERS,
        order: 2,
        group: {
            label: "Audit",
            identifier: "audit",
            order: 6
        },
        sub_group: {
            label: "Ledgers",
            identifier: "ledgers",
            order: 2
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Reports
    {
        public_id: uniqid("nav_"),
        sync_token: 1,
        identifier: "reports",
        label: "Reports",
        icon: "chart-pie",
        description: "A report is a document or visual that conveys information, findings, analysis, or summaries of events to provide insights, recommendations, or updates on a particular subject or situation.",
        is_active: true,
        is_visible: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_locked: false,
        is_section: false,
        route: ROUTES.REPORTS,
        order: 3,
        group: {
            label: "Audit",
            identifier: "audit",
            order: 6
        },
        sub_group: {
            label: "Reports",
            identifier: "reports",
            order: 3
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Team Area
    {
        public_id: uniqid("nav_"),
        sync_token: 1,
        identifier: "team",
        label: "Team",
        icon: "people-roof",
        description: "Team overview screen (App Page Layout).",
        is_active: true,
        is_visible: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_locked: false,
        is_section: true,
        route: ROUTES.TEAM,
        order: 1,
        group: {
            label: "Team",
            identifier: "team",
            order: 7
        },
        sub_group: {
            label: "Team Overview",
            identifier: "overview",
            order: 1
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Users
    {
        public_id: uniqid("nav_"),
        sync_token: 1,
        identifier: "users",
        label: "Users",
        icon: "user",
        description: "Users are company workers that support internal operations such as business owners, employees, contractors, and others.",
        is_active: true,
        is_visible: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_locked: false,
        is_section: false,
        route: "/users",
        order: 2,
        group: {
            label: "Team",
            identifier: "team",
            order: 7
        },
        sub_group: {
            label: "Users",
            identifier: "users",
            order: 2
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Vehicles
    {
        public_id: uniqid("nav_"),
        sync_token: 1,
        identifier: "vehicles",
        label: "Vehicles",
        icon: "car",
        description: "Vehicles are assets used by the company that are drivable to work items and can store items onboard.	",
        is_active: true,
        is_visible: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_locked: false,
        is_section: false,
        route: "/vehicles",
        order: 3,
        group: {
            label: "Team",
            identifier: "team",
            order: 7
        },
        sub_group: {
            label: "Vehicles",
            identifier: "vehicles",
            order: 3
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Security Area
    {
        public_id: uniqid("nav_"),
        sync_token: 1,
        identifier: "security",
        label: "Security",
        icon: "shield",
        description: "Security overview screen (App Page Layout).",
        is_active: true,
        is_visible: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_locked: false,
        is_section: true,
        route: ROUTES.SECURITY,
        order: 1,
        group: {
            label: "Security",
            identifier: "security",
            order: 8
        },
        sub_group: {
            label: "Security Overview",
            identifier: "overview",
            order: 1
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Roles
    {
        public_id: uniqid("nav_"),
        sync_token: 1,
        identifier: "roles",
        label: "Roles",
        icon: "id-card",
        description: "Roles allow users to have a standard role which has a certain permission set attached.",
        is_active: true,
        is_visible: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_locked: false,
        is_section: false,
        route: "/roles",
        order: 2,
        group: {
            label: "Security",
            identifier: "security",
            order: 8
        },
        sub_group: {
            label: "Roles",
            identifier: "roles",
            order: 2
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Permission Sets
    {
        public_id: uniqid("nav_"),
        sync_token: 1,
        identifier: "permission_sets",
        label: "Permission Sets",
        icon: "user-lock",
        description: "Permission Sets are a group of ProHelper permissions for a user. Can be attached to a role and directly to users.",
        is_active: true,
        is_visible: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_locked: false,
        is_section: false,
        route: "/permission_sets",
        order: 3,
        group: {
            label: "Security",
            identifier: "security",
            order: 8
        },
        sub_group: {
            label: "Permission Sets",
            identifier: "permission_sets",
            order: 3
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Settings
    {
        public_id: uniqid("nav_"),
        sync_token: 1,
        identifier: "settings",
        label: "Settings",
        icon: "cog",
        description: "Company settings for the program.",
        is_active: true,
        is_visible: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_locked: false,
        is_section: true,
        route: "/settings",
        order: 7,
        group: {
            label: "Settings",
            identifier: "settings",
            order: 9
        },
        sub_group: {
            label: "Overview",
            identifier: "overview",
            order: 1
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    }
];

export default NavigationItemsData;

// This constants file is a representation of how data for object fields will be recieved by the ProHelper database once linked.
import uniqid from 'uniqid';
import * as FieldTypes from '../../types/FieldTypes';
import * as DatabaseTypes from '../../types/DatabaseTypes';
import * as SpecialTypes from '../../types/SpecialTypes';
import States from '../../static_data/States';

const convertToDropdownArray = (staticObjectsArray) => {
    return staticObjectsArray.map((staticObj) => {
        return {
                id: null,
                company: null,
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: staticObj.label ? staticObj.label : '',
                short_label: staticObj.short_label ? staticObj.short_label : staticObj.label,
                identifier: staticObj.value.toLowerCase(),
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: staticObj.value.toLowerCase()
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                is_locked: false,
                is_default: true,
                is_custom: false,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: staticObj.metadata ? staticObj.metadata : null,
                dropdown_settings: {}
            }
    })
}

const GlobalDropdownSetsData = [
    // Global - Zones
    {
        id: null,
        company: null,
        public_id: uniqid('dropset_'),
        object: null,
        object_identifier: "global",
        sync_token: 1,
        label: "Zones",
        identifier: 'global.zones',
        dropdown_plural_label: 'Zones',
        dropdown_singular_label: "Zone",
        description: "A set of all the different geographical zones used by the company.",
        field_type: FieldTypes.CUSTOM_DROPDOWN,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_locked: true,
        is_editable: true,
        is_editable_inline: true,
        is_sortable: false,
        is_filterable: true,
        is_custom: false,
        is_colored: false,
        linked_fields: [],
        on_dropdown_delete: 'restrict',
        on_dropdown_set_delete: 'restrict',
        dropdowns: []
    },
    // Global - Cities
    {
        id: null,
        company: null,
        public_id: uniqid('dropset_'),
        object: null,
        object_identifier: "global",
        sync_token: 1,
        label: "Cities",
        identifier: 'global.cities',
        dropdown_plural_label: 'Cities',
        dropdown_singular_label: "City",
        description: "A set of all the different cities used by the company.",
        field_type: FieldTypes.TEXT_DROPDOWN,
        data_type: DatabaseTypes.TEXT,
        special_type: SpecialTypes.ADDRESS_CITY,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_locked: false,
        is_live: true,
        is_editable: true,
        is_editable_inline: true,
        is_sortable: true,
        is_filterable: true,
        is_custom: false,
        is_colored: false,
        linked_fields: [],
        on_dropdown_delete: 'restrict',
        on_dropdown_set_delete: 'restrict',
        dropdowns: [
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "Danville",
                identifier: "danville",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "danville"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {
                    state: 'ca',
                    country: 'US'
                },
                dropdown_settings: {}
            },
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "San Ramon",
                identifier: "san_ramon",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "san_ramon"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {
                    state: 'ca',
                    country: 'US'
                },
                dropdown_settings: {}
            },
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "Alamo",
                identifier: "alamo",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "alamo"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {
                    state: 'ca',
                    country: 'US'
                },
                dropdown_settings: {}
            },
        ]
    },
    // Global - Postal Codes
    {
        id: null,
        company: null,
        public_id: uniqid('dropset_'),
        object: null,
        object_identifier: "global",
        sync_token: 1,
        label: "Postal Codes",
        identifier: 'global.postal_codes',
        dropdown_plural_label: 'Postal Codes',
        dropdown_singular_label: "Postal Code",
        description: "A set of all the different postal codes used by the company.",
        field_type: FieldTypes.TEXT_DROPDOWN,
        data_type: DatabaseTypes.TEXT,
        special_type: SpecialTypes.ADDRESS_POSTAL_CODE,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_locked: false,
        is_live: true,
        is_editable: true,
        is_editable_inline: true,
        is_sortable: true,
        is_filterable: true,
        is_custom: false,
        is_colored: false,
        linked_fields: [],
        on_dropdown_delete: 'restrict',
        on_dropdown_set_delete: 'restrict',
        dropdowns: [
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "94506",
                identifier: "94506",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "94506"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {
                    state: 'ca',
                    country: 'US'
                },
                dropdown_settings: {}
            },
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "94526",
                identifier: "94526",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "94526"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {
                    state: 'ca',
                    country: 'US'
                },
                dropdown_settings: {}
            },
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "94583",
                identifier: "94583",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "94583"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {
                    state: 'ca',
                    country: 'US'
                },
                dropdown_settings: {}
            },
        ]
    },
    // Global - States
    {
        id: null,
        company: null,
        public_id: uniqid('dropset_'),
        object: null,
        object_identifier: "global",
        sync_token: 1,
        label: "States",
        identifier: 'global.states',
        dropdown_plural_label: 'States',
        dropdown_singular_label: "State",
        description: "A set of all the different states / country sub-divisions used by the company.",
        field_type: FieldTypes.TEXT_DROPDOWN,
        data_type: DatabaseTypes.TEXT,
        special_type: SpecialTypes.ADDRESS_STATE,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_locked: false,
        is_live: true,
        is_editable: true,
        is_editable_inline: true,
        is_sortable: true,
        is_filterable: true,
        is_custom: false,
        is_colored: false,
        linked_fields: [],
        on_dropdown_delete: 'restrict',
        on_dropdown_set_delete: 'restrict',
        dropdowns: convertToDropdownArray(States)
    },
    // Global - Sales Tax Rates
    {
        id: null,
        company: null,
        public_id: uniqid('dropset_'),
        object: null,
        object_identifier: "global",
        sync_token: 1,
        label: "Sales Tax Rates",
        identifier: 'global.sales_tax_rates',
        dropdown_plural_label: 'Sales Tax Rates',
        dropdown_singular_label: "Sales Tax Rate",
        description: "A set of all the different sales tax rates used by the company.",
        field_type: FieldTypes.TEXT_DROPDOWN,
        data_type: DatabaseTypes.NUMERIC,
        special_type: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_locked: false,
        is_live: true,
        is_editable: true,
        is_editable_inline: false,
        is_sortable: true,
        is_filterable: true,
        is_custom: false,
        is_colored: false,
        linked_fields: [],
        on_dropdown_delete: 'restrict',
        on_dropdown_set_delete: 'restrict',
        dropdowns: [
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "Contra Costa Tax Rate",
                identifier: "contra-costa-tax-rate",
                value: {
                    data_type: DatabaseTypes.NUMERIC,
                    data: 8.25
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_locked: true,
                is_default: true,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {
                    state_tax_rate: 6.00,
                    local_tax_rate: 0.25,
                    addition_rate: 0
                },
                dropdown_settings: {}
            },
        ]
    },
    // Global - Payment Statuses
    {
        id: null,
        company: null,
        public_id: uniqid('dropset_'),
        object: null,
        object_identifier: "global",
        sync_token: 1,
        label: "Payment Statuses",
        identifier: 'global.payment_statuses',
        dropdown_plural_label: 'Payment Statuses',
        dropdown_singular_label: "Payment Status",
        description: "A set of all the different payment status types used by the company.",
        field_type: FieldTypes.TEXT_DROPDOWN,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_locked: true,
        is_live: false,
        is_editable: false,
        is_editable_inline: false,
        is_sortable: true,
        is_filterable: true,
        is_custom: false,
        is_colored: false,
        linked_fields: [],
        on_dropdown_delete: 'restrict',
        on_dropdown_set_delete: 'restrict',
        dropdowns: [
            // Draft
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "Draft",
                identifier: "draft",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "draft"
                },
                color: null,
                sort_order: 1,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Open
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "Open",
                identifier: "open",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "open"
                },
                color: null,
                sort_order: 2,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Paid
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "Paid",
                identifier: "paid",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "paid"
                },
                color: null,
                sort_order: 3,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Past-Due
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "Past Due",
                identifier: "past_due",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "past_due"
                },
                color: null,
                sort_order: 4,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Deliquent
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "Deliquent",
                identifier: "deliquent",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "deliquent"
                },
                color: null,
                sort_order: 5,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Uncollectible
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "Uncollectible",
                identifier: "uncollectible",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "uncollectible"
                },
                color: null,
                sort_order: 6,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Void
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "Void",
                identifier: "void",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "void"
                },
                color: null,
                sort_order: 7,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
        ]
    },
    // Global - Payment Methods
    {
        id: null,
        company: null,
        public_id: uniqid('dropset_'),
        object: null,
        object_identifier: "global",
        sync_token: 1,
        label: "Payment Methods",
        identifier: 'global.payment_methods',
        dropdown_plural_label: 'Payment Methods',
        dropdown_singular_label: "Payment Method",
        description: "A set of all the different payment methods used by the company.",
        field_type: FieldTypes.TEXT_DROPDOWN,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_locked: true,
        is_live: false,
        is_editable: false,
        is_editable_inline: false,
        is_sortable: true,
        is_filterable: true,
        is_custom: false,
        is_colored: false,
        linked_fields: [],
        on_dropdown_delete: 'restrict',
        on_dropdown_set_delete: 'restrict',
        dropdowns: [
            // Card
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "Card",
                identifier: "card",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "card"
                },
                color: null,
                sort_order: 1,
                is_active: true,
                is_locked: true,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Check
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "Check",
                identifier: "open",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "check"
                },
                color: null,
                sort_order: 2,
                is_active: true,
                is_locked: true,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Cash
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "Cash",
                identifier: "cash",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "cash"
                },
                color: null,
                sort_order: 3,
                is_active: true,
                is_locked: true,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // ACH Transfer
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "ACH Transfer",
                identifier: "ach_transfer",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "ach_transfer"
                },
                color: null,
                sort_order: 4,
                is_active: true,
                is_locked: true,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Other
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "Other",
                identifier: "other",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "other"
                },
                color: null,
                sort_order: 5,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            }
        ]
    },
    // Global - Delivery Methods
    {
        id: null,
        company: null,
        public_id: uniqid('dropset_'),
        object: null,
        object_identifier: "global",
        sync_token: 1,
        label: "Delivery Methods",
        identifier: 'global.delivery_methods',
        dropdown_plural_label: 'Delivery Methods',
        dropdown_singular_label: "Delivery Method",
        description: "A set of all the different information delivery methods used by the company.",
        field_type: FieldTypes.TEXT_DROPDOWN,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_locked: false,
        is_live: false,
        is_editable: true,
        is_editable_inline: true,
        is_sortable: false,
        is_filterable: true,
        is_custom: false,
        is_colored: false,
        linked_fields: [],
        on_dropdown_delete: 'restrict',
        on_dropdown_set_delete: 'restrict',
        dropdowns: [
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "Email",
                identifier: "email",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "email"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "Mail",
                identifier: "mail",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "mail"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "Text",
                identifier: "text",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "text"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            }
        ]
    },
    // Global - Communication Methods
    {
        id: null,
        company: null,
        public_id: uniqid('dropset_'),
        object: null,
        object_identifier: "global",
        sync_token: 1,
        label: "Communication Methods",
        identifier: 'global.communication_methods',
        dropdown_plural_label: 'Communication Methods',
        dropdown_singular_label: "Communication Method",
        description: "A set of all the different communication methods used by the company.",
        field_type: FieldTypes.TEXT_DROPDOWN,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_locked: false,
        is_editable: true,
        is_editable_inline: true,
        is_sortable: false,
        is_filterable: true,
        is_custom: false,
        is_colored: false,
        linked_fields: [],
        on_dropdown_delete: 'restrict',
        on_dropdown_set_delete: 'restrict',
        dropdowns: [
            // Call
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "Call",
                identifier: "call",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "call"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Email
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "Email",
                identifier: "email",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "email"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Mail
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "Mail",
                identifier: "mail",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "mail"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Text
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "Text",
                identifier: "text",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "text"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // In Person
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "In Person",
                identifier: "in-person",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "in-person"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            }
        ]
    },
    // Global - Priorities
    {
        id: null,
        company: null,
        public_id: uniqid('dropset_'),
        object: null,
        object_identifier: 'global',
        sync_token: 1,
        label: "Priorities",
        identifier: 'global.priorities',
        dropdown_plural_label: 'Priorities',
        dropdown_singular_label: "Priority",
        description: "A set of the all the different priorities, or urgencies.",
        field_type: FieldTypes.CUSTOM_DROPDOWN,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_locked: false,
        is_live: true,
        is_editable: true,
        is_editable_inline: false,
        is_sortable: true,
        is_filterable: true,
        is_custom: false,
        is_colored: true,
        linked_fields: [],
        on_dropdown_delete: 'restrict',
        on_dropdown_set_delete: 'restrict',
        dropdowns: [
            // Urgent
            {
                id: null,
                company: null,
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "Urgent",
                identifier: "urgent",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "urgent"
                },
                color: "#CC1DFD",
                sort_order: 1,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // High
            {
                id: null,
                company: null,
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "High",
                identifier: "high",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "high"
                },
                color: "#DA3A3B",
                sort_order: 2,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Medium
            {
                id: null,
                company: null,
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "Medium",
                identifier: "medium",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "medium"
                },
                color: "#FF7E67",
                sort_order: 3,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Low
            {
                id: null,
                company: null,
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "Low",
                identifier: "low",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "low"
                },
                color: "#D7B905",
                sort_order: 4,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            }
        ]
    },
    // Global - Sources
    {
        id: null,
        company: null,
        public_id: uniqid('dropset_'),
        object: null,
        object_identifier: 'global',
        sync_token: 1,
        label: "Sources",
        identifier: 'global.sources',
        dropdown_plural_label: 'Sources',
        dropdown_singular_label: "Source",
        description: "A set of the all the different sources; origin of a record.",
        field_type: FieldTypes.CUSTOM_DROPDOWN,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_locked: false,
        is_live: true,
        is_editable: true,
        is_sortable: false,
        is_filterable: true,
        is_custom: false,
        is_colored: false,
        linked_fields: [],
        on_dropdown_delete: 'restrict',
        on_dropdown_set_delete: 'restrict',
        dropdowns: [
            // Direct
            {
                id: null,
                company: null,
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "Direct",
                identifier: "direct",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "direct"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Referral
            {
                id: null,
                company: null,
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "Referral",
                identifier: "referral",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "referral"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Phone
            {
                id: null,
                company: null,
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "Phone",
                identifier: "phone",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "phone"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Email
            {
                id: null,
                company: null,
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "Email",
                identifier: "email",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "email"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // In Person
            {
                id: null,
                company: null,
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "In-Person",
                identifier: "in-person",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "in-person"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Advertisement
            {
                id: null,
                company: null,
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "Advertisement",
                identifier: "advertisement",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "advertisement"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Website
            {
                id: null,
                company: null,
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "Website",
                identifier: "website",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "website"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Other
            {
                id: null,
                company: null,
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "Other",
                identifier: "other",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "other"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            }
        ]
    },
    // Global - Ratings
    {
        id: null,
        company: null,
        public_id: uniqid('dropset_'),
        object: null,
        object_identifier: 'global',
        sync_token: 1,
        label: "Ratings",
        identifier: 'global.ratings',
        dropdown_plural_label: 'Ratings',
        dropdown_singular_label: "Rating",
        description: "A set of all the different ratings.",
        field_type: FieldTypes.CUSTOM_DROPDOWN,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_locked: false,
        is_live: true,
        is_editable: true,
        is_editable_inline: false,
        is_sortable: true,
        is_filterable: true,
        is_custom: false,
        is_colored: false,
        linked_fields: [],
        on_dropdown_delete: 'restrict',
        on_dropdown_set_delete: 'restrict',
        dropdowns: [
            // Amazing
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "Amazing",
                identifier: "amazing",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "amazing"
                },
                color: null,
                sort_order: 1,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Good
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "Good",
                identifier: "good",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "good"
                },
                color: null,
                sort_order: 2,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Normal
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "Normal",
                identifier: "normal",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "normal"
                },
                color: null,
                sort_order: 3,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Subpar
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "Subpar",
                identifier: "subpar",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "subpar"
                },
                color: null,
                sort_order: 4,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Bad
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "Bad",
                identifier: "bad",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "bad"
                },
                color: null,
                sort_order: 5,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Opportunity
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "Opportunity",
                identifier: "opportunity",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "opportunity"
                },
                color: null,
                sort_order: 6,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            }
        ]
    },
    // Global - Difficulties
    {
        id: null,
        company: null,
        public_id: uniqid('dropset_'),
        object: null,
        object_identifier: 'global',
        sync_token: 1,
        label: "Difficulties",
        identifier: 'global.difficulties',
        dropdown_plural_label: 'Difficulties',
        dropdown_singular_label: "Difficulty",
        description: "A set of all the different Difficulties.",
        field_type: FieldTypes.CUSTOM_DROPDOWN,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_locked: false,
        is_live: true,
        is_editable: true,
        is_editable_inline: false,
        is_sortable: true,
        is_filterable: true,
        is_custom: false,
        is_colored: false,
        linked_fields: [],
        on_dropdown_delete: 'restrict',
        on_dropdown_set_delete: 'restrict',
        dropdowns: [
            // Easy
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "Easy",
                short_label: "Easy",
                identifier: "easy",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "easy"
                },
                color: null,
                sort_order: 1,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Medium 
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "Medium",
                short_label: "Medium",
                identifier: "medium",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "medium"
                },
                color: null,
                sort_order: 2,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Hard
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "Hard",
                short_label: "Hard",
                identifier: "hard",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "hard"
                },
                color: null,
                sort_order: 3,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Extreme
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "Extreme",
                short_label: "Extreme",
                identifier: "extreme",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "extreme"
                },
                color: null,
                sort_order: 4,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Unknown
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "Unknown",
                short_label: "Unknown",
                identifier: "unknown",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "unknown"
                },
                color: null,
                sort_order: 5,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            }
        ]
    },
    // Global - Departments
    {
        id: null,
        company: null,
        public_id: uniqid('dropset_'),
        object: null,
        object_identifier: 'global',
        sync_token: 1,
        label: "Departments",
        identifier: 'global.departments',
        description: "A set of all the different departments in an Organization.",
        dropdown_plural_label: 'Departments',
        dropdown_singular_label: "Department",
        field_type: FieldTypes.CUSTOM_DROPDOWN,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_locked: false,
        is_live: true,
        is_editable: true,
        is_editable_inline: true,
        is_sortable: false,
        is_filterable: true,
        is_custom: false,
        is_colored: false,
        linked_fields: [],
        on_dropdown_delete: 'restrict',
        on_dropdown_set_delete: 'restrict',
        dropdowns: [
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "Service",
                identifier: "service",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "service"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "Repair",
                identifier: "repair",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "repair"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "Sales",
                identifier: "sales",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "sales"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "Marketing",
                identifier: "marketing",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "marketing"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "Accounting",
                identifier: "accounting",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "accounting"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            }
        ]
    },
    // Global - Skills
    {
        id: null,
        company: null,
        public_id: uniqid('dropset_'),
        object: null,
        object_identifier: 'global',
        sync_token: 1,
        label: "Skills",
        identifier: 'global.skills',
        description: "A set of all the different skills that employees know / are able to do.",
        dropdown_plural_label: 'Skills',
        dropdown_singular_label: "Skill",
        field_type: FieldTypes.CUSTOM_DROPDOWN,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_locked: false,
        is_live: true,
        is_editable: true,
        is_editable_inline: true,
        is_sortable: false,
        is_filterable: true,
        is_custom: false,
        is_colored: false,
        linked_fields: [],
        on_dropdown_delete: 'restrict',
        on_dropdown_set_delete: 'restrict',
        dropdowns: [

        ]
    },
    // Global - Tools
    {
        id: null,
        company: null,
        public_id: uniqid('dropset_'),
        object: null,
        object_identifier: 'global',
        sync_token: 1,
        label: "Tools",
        identifier: 'global.tools',
        description: "A set of all the different tools that the company used for work items.",
        dropdown_plural_label: 'Tools',
        dropdown_singular_label: "Tool",
        field_type: FieldTypes.CUSTOM_DROPDOWN,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_locked: false,
        is_live: true,
        is_editable: true,
        is_editable_inline: true,
        is_sortable: false,
        is_filterable: true,
        is_custom: false,
        is_colored: false,
        linked_fields: [],
        on_dropdown_delete: 'restrict',
        on_dropdown_set_delete: 'restrict',
        dropdowns: [

        ]
    },
    // Global - Colors
    {
        id: null,
        company: null,
        public_id: uniqid('dropset_'),
        object: null,
        object_identifier: 'global',
        sync_token: 1,
        label: "Colors",
        identifier: 'global.colors',
        description: "A set of all the different colors.",
        dropdown_plural_label: 'Colors',
        dropdown_singular_label: "color",
        field_type: FieldTypes.TEXT_DROPDOWN,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_locked: false,
        is_live: true,
        is_editable: true,
        is_editable_inline: true,
        is_sortable: false,
        is_filterable: true,
        is_custom: false,
        is_colored: false,
        linked_fields: [],
        on_dropdown_delete: 'restrict',
        on_dropdown_set_delete: 'restrict',
        dropdowns: [
            // White
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "White",
                identifier: "white",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "white"
                },
                color: 'white',
                sort_order: 1,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Grey
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "Grey",
                identifier: "grey",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "grey"
                },
                color: 'grey',
                sort_order: 2,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Black
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "Black",
                identifier: "black",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "black"
                },
                color: 'black',
                sort_order: 3,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
        ]
    },
    // Global - Domains
    {
        id: null,
        company: null,
        public_id: uniqid('dropset_'),
        object: null,
        object_identifier: 'global',
        sync_token: 1,
        label: "Domains",
        identifier: 'global.domains',
        description: "A set of all the different domains where data is first created at.",
        dropdown_plural_label: 'Domains',
        dropdown_singular_label: "domain",
        field_type: FieldTypes.TEXT_DROPDOWN,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_locked: false,
        is_live: true,
        is_editable: false,
        is_editable_inline: false,
        is_sortable: false,
        is_filterable: true,
        is_custom: false,
        is_colored: false,
        linked_fields: [],
        on_dropdown_delete: 'restrict',
        on_dropdown_set_delete: 'restrict',
        dropdowns: [
            // ProHelper
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "ProHelper",
                identifier: "pro",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "pro"
                },
                color: null,
                sort_order: 1,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Stripe
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "Stripe",
                identifier: "stripe",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "stripe"
                },
                color: null,
                sort_order: 2,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // QBO
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "QBO",
                identifier: "qbo",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "qbo"
                },
                color: null,
                sort_order: 3,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
        ]
    },
    // Global - Payroll Methods
    {
        id: null,
        company: null,
        public_id: uniqid('dropset_'),
        object: null,
        object_identifier: 'global',
        sync_token: 1,
        label: "Payroll Methods",
        identifier: 'global.payroll_methods',
        description: "A set of all the different Payroll Methods in an Organization.",
        dropdown_plural_label: 'Payroll Methods',
        dropdown_singular_label: "Payoll Method",
        field_type: FieldTypes.TEXT_DROPDOWN,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_locked: false,
        is_live: true,
        is_editable: true,
        is_editable_inline: true,
        is_sortable: false,
        is_filterable: true,
        is_custom: false,
        is_colored: false,
        linked_fields: [],
        on_dropdown_delete: 'restrict',
        on_dropdown_set_delete: 'restrict',
        dropdowns: [
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "Cash",
                identifier: "cash",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "cash"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "ACH",
                identifier: "ach",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "ach"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "Check",
                identifier: "check",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "check"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "Other",
                identifier: "other",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "other"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "Not Set",
                identifier: "not-set",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "not-set"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            }
        ]
    },
    // Global - Assignment Statuses
    {
        id: null,
        company: null,
        public_id: uniqid('dropset_'),
        object: null,
        object_identifier: 'global',
        sync_token: 1,
        label: "Assignment Statuses",
        identifier: 'global.assignment_statuses',
        description: "A set of all the different Assignment Statuses in an Organization.",
        dropdown_plural_label: 'Assignment Statuses',
        dropdown_singular_label: "Assignment Status",
        field_type: FieldTypes.CUSTOM_DROPDOWN,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_locked: false,
        is_live: true,
        is_editable: false,
        is_editable_inline: false,
        is_sortable: false,
        is_filterable: true,
        is_custom: false,
        is_colored: false,
        linked_fields: [],
        on_dropdown_delete: 'restrict',
        on_dropdown_set_delete: 'restrict',
        dropdowns: [
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "Waiting for Assignment",
                identifier: "waiting_for_assignment",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "waiting_for_assignment"
                },
                color: null,
                sort_order: 1,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "Started",
                identifier: "started",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "started"
                },
                color: null,
                sort_order: 2,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "Interrupted",
                identifier: "interrupted",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "interrupted"
                },
                color: null,
                sort_order: 3,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "Completed",
                identifier: "completed",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "completed"
                },
                color: null,
                sort_order: 4,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
        ]
    },
    // Global - Schedule Statuses
    {
        id: null,
        company: null,
        public_id: uniqid('dropset_'),
        object: null,
        object_identifier: 'global',
        sync_token: 1,
        label: "Schedule Statuses",
        identifier: 'global.schedule_statuses',
        description: "A set of all the different Schedule Statuses in an Organization.",
        dropdown_plural_label: 'Schedule Statuses',
        dropdown_singular_label: "Schedule Status",
        field_type: FieldTypes.CUSTOM_DROPDOWN,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_locked: false,
        is_live: true,
        is_editable: false,
        is_editable_inline: false,
        is_sortable: false,
        is_filterable: true,
        is_custom: false,
        is_colored: false,
        linked_fields: [],
        on_dropdown_delete: 'restrict',
        on_dropdown_set_delete: 'restrict',
        dropdowns: [
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "Waiting to be Scheduled",
                identifier: "waiting_to_be_scheduled",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "waiting_to_be_scheduled"
                },
                color: null,
                sort_order: 1,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "Scheduled",
                identifier: "scheduled",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "scheduled"
                },
                color: null,
                sort_order: 2,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "Rescheduled",
                identifier: "rescheduled",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "rescheduled"
                },
                color: null,
                sort_order: 3,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "Delayed",
                identifier: "delayed",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "delayed"
                },
                color: null,
                sort_order: 4,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "Completed",
                identifier: "completed",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "completed"
                },
                color: null,
                sort_order: 5,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "Cancelled",
                identifier: "cancelled",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "cancelled"
                },
                color: null,
                sort_order: 6,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "global",
                sync_token: 1,
                label: "Expired",
                identifier: "expired",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "expired"
                },
                color: null,
                sort_order: 7,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
        ]
    },
]

export default GlobalDropdownSetsData;
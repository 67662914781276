// This constants file is a representation of how data for purchase order fields will be recieved by the ProHelper database once linked.
import uniqid from 'uniqid';
import * as FieldTypes from '../../types/FieldTypes';
import * as DatabaseTypes from '../../types/DatabaseTypes';
import * as SpecialTypes from '../../types/SpecialTypes';
import * as FilterOperatorTypeIdenifiers from '../../filtering/operators/FilterOperatorTypeIdentifiers';
import * as LookupRelationshipTypes from '../../types/LookupRelationshipTypes';
import * as SortingMethods from '../../sorting/SortingMethods';

const PurchaseOrderFieldsData = [
    // Database ID
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Database ID",
        identifier: "id",
        field_type: FieldTypes.ID,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "The database id of this Purchase Order.",
        help_text: "The database id of this Purchase Order.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: true,
        is_editable: false,
        is_searchable: false,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Organization
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Organization",
        identifier: "org",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "The organization id of the ProHelper company.",
        help_text: "The organization id of the ProHelper company.",
        default_value: null,
        placeholder_text: "Select an organization...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: true,
        is_editable: false,
        is_searchable: false,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Subject
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Subject",
        identifier: "subject",
        field_type: FieldTypes.LONG_TEXT,
        data_type: DatabaseTypes.TEXT,
        special_type: SpecialTypes.MAIN_LABEL,
        description: "The subject/title represents a short description for an Purchase Order.",
        help_text: "The subject/title represents a short description for an Purchase Order.",
        default_value: null,
        placeholder_text: "Enter a Purchase Order subject/title...",
        is_active: true,
        is_visible: true,
        visible_order: 1,
        is_required: true,
        is_editable: true,
        is_searchable: true,
        search_priority: 'A',
        is_filterable: false,
        filter_order: null,
        is_sortable: true,
        sort_order: 1,
        sort_method: SortingMethods.ALPHANUMERIC,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: false,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Description
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Description",
        identifier: "description",
        field_type: FieldTypes.LONG_TEXT,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        description: "A optional description for an Purchase Order.",
        help_text: "A optional description for an Purchase Order.",
        default_value: null,
        placeholder_text: "Add a Purchase Order description...",
        is_active: true,
        is_visible: true,
        visible_order: 2,
        is_required: false,
        is_editable: true,
        is_searchable: true,
        search_priority: 'B',
        is_filterable: false,
        filter_order: null,
        is_sortable: true,
        sort_order: 2,
        sort_method: SortingMethods.ALPHANUMERIC,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: false,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Type
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Type",
        identifier: "type",
        field_type: FieldTypes.TYPE_DROPDOWN,
        data_type: DatabaseTypes.BIGINT,
        special_type: SpecialTypes.MAIN_TYPE,
        description: "Represents the type of purchase_order, allowing groups of purchase_orders.",
        help_text: "Represents the type of purchase_order, allowing groups of purchase_orders.",
        default_value: null,
        placeholder_text: "Select a Purchase Order Type...",
        is_active: true,
        is_visible: true,
        visible_order: 3,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 1,
        is_sortable: true,
        sort_order: 3,
        sort_method: SortingMethods.CUSTOM_SORT_ORDER,
        is_multi: false,
        is_async: true,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: {
            identifier: "purchase_orders.types",
            object_identifier: "purchase_orders",
            type: "type_dropdown"
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Status
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Status",
        identifier: "status",
        field_type: FieldTypes.CUSTOM_DROPDOWN,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "The status of this purchase_order.",
        help_text: "The status of this purchase_order.",
        default_value: null,
        placeholder_text: "Select a Purchase Order Status...",
        is_active: true,
        is_visible: true,
        visible_order: 4,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 2,
        is_sortable: true,
        sort_order: 4,
        sort_method: SortingMethods.CUSTOM_SORT_ORDER,
        is_multi: false,
        is_async: true,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: {
            identifier: "purchase_orders.statuses",
            object_identifier: "purchase_orders",
            type: "custom_dropdown"
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Due Date
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Due Date",
        identifier: "due_date",
        field_type: FieldTypes.DATE_TIME,
        data_type: DatabaseTypes.TIMESTAMPTZ,
        special_type: null,
        description: "The date on which payment for this purchase_order is due.",
        help_text: "The date on which payment for this purchase_order is due.",
        default_value: null,
        placeholder_text: "Select a Due Date...",
        is_active: true,
        is_visible: true,
        visible_order: 5,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 3,
        is_sortable: true,
        sort_order: 5,
        sort_method: SortingMethods.CHRONOLOGICAL,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: false,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Issued Date
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Issued Date",
        identifier: "issued_date",
        field_type: FieldTypes.DATE_TIME,
        data_type: DatabaseTypes.TIMESTAMPTZ,
        special_type: null,
        description: "The date this Purchase Order was entered into the system. May be edited.",
        help_text: "The date this Purchase Order was entered into the system. May be edited.",
        default_value: null,
        placeholder_text: "Select an Issued Date...",
        is_active: true,
        is_visible: true,
        visible_order: 6,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 4,
        is_sortable: true,
        sort_order: 6,
        sort_method: SortingMethods.CHRONOLOGICAL,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: false,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Vendor
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Vendor",
        identifier: "vendor",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "The vendor account associated with this purchase_order.",
        help_text: "The vendor account associated with this purchase_order.",
        default_value: null,
        placeholder_text: "Select an Account...",
        is_active: true,
        is_visible: true,
        visible_order: 7,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 5,
        is_sortable: true,
        sort_order: 7,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "accounts",
            filters: [{
                field: "is_active",
                operator: "equals",
                values: [{ 
                    type: "value",
                    label: "True",
                    data: true
                }]
            }, {
                field: "type",
                operator: "equals",
                values: [{ 
                    type: "vendor",
                    label: "Vendor",
                    data: 'vendor'
                }]
            }],
            display_field: "name",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true
        },
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Primary Contact
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Primary Contact",
        identifier: "primary_contact",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "The primary contact associated with this purchase_order.",
        help_text: "The primary contact associated with this purchase_order.",
        default_value: null,
        placeholder_text: "Select a Primary Contact...",
        is_active: true,
        is_visible: true,
        visible_order: 8,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 6,
        is_sortable: true,
        sort_order: 8,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "contacts",
            filters: [{
                field: "is_active",
                operator: "equals",
                values: [{ 
                    type: "value",
                    label: "True",
                    data: true
                }]
            }],
            display_field: "name",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true
        },
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Primary Property
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Primary Property",
        identifier: "primary_property",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "The primary property associated with this purchase_order.",
        help_text: "The primary property associated with this purchase_order.",
        default_value: null,
        placeholder_text: "Select a Primary Property...",
        is_active: true,
        is_visible: true,
        visible_order: 9,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 7,
        is_sortable: true,
        sort_order: 9,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "properties",
            filters: [{
                field: "is_active",
                operator: "equals",
                values: [{ 
                    type: "value",
                    label: "True",
                    data: true
                }]
            }],
            display_field: "address",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true
        },
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Billing Contact
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Billing Contact",
        identifier: "billing_contact",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "The billing contact for this purchase_order.",
        help_text: "The billing contact for this purchase_order.",
        default_value: null,
        placeholder_text: "Select a Billing Contact...",
        is_active: true,
        is_visible: true,
        visible_order: 10,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 8,
        is_sortable: true,
        sort_order: 10,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "contacts",
            filters: [{
                field: "is_active",
                operator: "equals",
                values: [{ 
                    type: "value",
                    label: "True",
                    data: true
                }]
            }],
            display_field: "name",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true
        },
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Billing Property
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Billing Property",
        identifier: "billing_property", 
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "The billing property for this purchase_order.",
        help_text: "The billing property for this purchase_order.",
        default_value: null,
        placeholder_text: "Select a Billing Property...",
        is_active: true,
        is_visible: true,
        visible_order: 11,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 9,
        is_sortable: true,
        sort_order: 11,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "properties",
            filters: [{
                field: "is_active",
                operator: "equals",
                values: [{ 
                    type: "value",
                    label: "True",
                    data: true
                }]
            }],
            display_field: "address",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true
        },
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Tags
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Tags",
        identifier: "tags",
        field_type: FieldTypes.TEXT_DROPDOWN,
        data_type: DatabaseTypes.TEXT_ARRAY,
        special_type: null,
        description: "Represents a list of words to tag the Purchase Order. Searchable field.",
        help_text: "Represents a list of words to tag the job. Searchable field.",
        default_value: null,
        placeholder_text: "Add some Purchase Order Tags...",
        is_active: true,
        is_visible: true,
        visible_order: 14,
        is_required: false,
        is_editable: true,
        is_searchable: true,
        search_priority: 'A',
        is_filterable: true,
        filter_order: 12,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: true,
        is_async: true,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: {
            identifier: "purchase_orders.tags",
            object_identifier: "purchase_orders",
            type: "text_dropdown"
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Purchase Order ID
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Purchase Order ID",
        identifier: "purchase_order_id",
        field_type: FieldTypes.PUBLIC_ID,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        description: "Unique identifier for the Purchase Order record.",
        help_text: "Unique identifier for the Purchase Order record.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_visible: true,
        visible_order: 15,
        is_required: false,
        is_editable: false,
        is_searchable: true,
        search_priority: 'B',
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: true,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Purchase Order Number
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Purchase Order Number",
        identifier: "purchase_order_number",
        field_type: FieldTypes.INTEGER,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        description: "A unique, identifying string that appears on emails or output documents sent to the customer for this purchase_order.",
        help_text: "A unique, identifying string that appears on emails or output documents sent to the customer for this purchase_order.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_visible: true,
        visible_order: 16,
        is_required: true,
        is_editable: false,
        is_searchable: true,
        search_priority: 'A',
        is_filterable: true,
        filter_order: 13,
        is_sortable: true,
        sort_order: 14,
        sort_method: SortingMethods.NUMERIC,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: true,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Purchase Orderer
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Purchase Orderer",
        identifier: "purchase_orderer",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "The user responsible for creating and sending this purchase_order.",
        help_text: "The user responsible for creating and sending this purchase_order.",
        default_value: null,
        placeholder_text: "Select a Purchase Orderer...",
        is_active: true,
        is_visible: true,
        visible_order: 17,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 14,
        is_sortable: true,
        sort_order: 15,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Extra Information",
            identifier: "extra_information",
            order: 2
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "users",
            filters: [{
                field: "is_active",
                operator: "equals",
                values: [{ 
                    type: "value",
                    label: "True",
                    data: true
                }]
            }],
            display_field: "name",
            strict_filter_lookups: false,
            is_related_record_editable: false,
            is_related_record_creatable: false
        },
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Request
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Request",
        identifier: "request",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Represents the request which was converted to create an Purchase Order.",
        help_text: "Represents the request which was converted to create an Purchase Order.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_visible: true,
        visible_order: 18,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        is_filterable: true,
        filter_order: 15,
        is_sortable: true,
        sort_order: 16,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: true,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Extra Information",
            identifier: "extra_information",
            order: 2
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "requests",
            filters: [{
                field: "is_active",
                operator: "equals",
                values: [{ 
                    type: "value",
                    label: "True",
                    data: true
                }]
            }],
            display_field: "subject",
            strict_filter_lookups: false,
            is_related_record_editable: false,
            is_related_record_creatable: false
        },
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Estimate
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Estimate",
        identifier: "estimate",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "The Estimate this purchase_order is associated with.",
        help_text: "The Estimate this purchase_order is associated with.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_visible: true,
        visible_order: 19,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 16,
        is_sortable: true,
        sort_order: 17,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Extra Information",
            identifier: "extra_information",
            order: 2
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "estimates",
            filters: [{
                field: "is_active",
                operator: "equals",
                values: [{ 
                    type: "value",
                    label: "True",
                    data: true
                }]
            }],
            display_field: "subject",
            strict_filter_lookups: false,
            is_related_record_editable: false,
            is_related_record_creatable: false
        },
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Invoice
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Invoice",
        identifier: "invoice",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "The Invoice this purchase_order is associated with.",
        help_text: "The Invoice this purchase_order is associated with.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_visible: true,
        visible_order: 19,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 16,
        is_sortable: true,
        sort_order: 17,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Extra Information",
            identifier: "extra_information",
            order: 2
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "invoices",
            filters: [{
                field: "is_active",
                operator: "equals",
                values: [{ 
                    type: "value",
                    label: "True",
                    data: true
                }]
            }],
            display_field: "subject",
            strict_filter_lookups: false,
            is_related_record_editable: false,
            is_related_record_creatable: false
        },
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Job
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Job",
        identifier: "job",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "The Job this purchase_order is associated with.",
        help_text: "The Job this purchase_order is associated with.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_visible: true,
        visible_order: 20,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 17,
        is_sortable: true,
        sort_order: 18,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Extra Information",
            identifier: "extra_information", 
            order: 2
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "jobs",
            filters: [{
                field: "is_active",
                operator: "equals",
                values: [{ 
                    type: "value",
                    label: "True",
                    data: true
                }]
            }],
            display_field: "subject",
            strict_filter_lookups: false,
            is_related_record_editable: false,
            is_related_record_creatable: false
        },
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Expense Ledger
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Expense Ledger",
        identifier: "expense_ledger",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Reference to the accounting ledger for expenses.",
        help_text: "Reference to the accounting ledger for expenses.",
        default_value: null,
        placeholder_text: "Select an Accounting Ledger...",
        is_active: true,
        is_visible: true,
        visible_order: 22,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 19,
        is_sortable: true,
        sort_order: 20,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Financial Information",
            identifier: "financial_information",
            order: 3
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "ledger",
            filters: [{
                field: "is_active",
                operator: "equals",
                values: [{ 
                    type: "value",
                    label: "True",
                    data: true
                }]
            }],
            display_field: "name",
            strict_filter_lookups: false,
            is_related_record_editable: false,
            is_related_record_creatable: false
        },
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Billing Reason
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Billing Reason",
        identifier: "billing_reason",
        field_type: FieldTypes.CUSTOM_DROPDOWN,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "The reason why the purchase order was created.",
        help_text: "The reason why the purchase order was created.",
        default_value: null,
        placeholder_text: 'Enter a Billing Reason...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 27,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 21,
        is_sortable: true,
        sort_order: 25,
        sort_method: SortingMethods.CUSTOM_SORT_ORDER,
        is_multi: false,
        is_async: true,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Financial Information",
            identifier: "financial_information",
            order: 3
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: {
            identifier: "purchase_orders.billing_reasons",
            object_identifier: "purchase_orders",
            type: "custom_dropdown"
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Payment Method 
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Payment Method",
        identifier: "payment_method",
        field_type: FieldTypes.TEXT_DROPDOWN,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        description: "The way this purchase_order will be paid.",
        help_text: "The way this purchase_order will be paid.",
        default_value: null,
        placeholder_text: "Select a Payment Method...",
        is_active: true,
        is_visible: true,
        visible_order: 23,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 20,
        is_sortable: true,
        sort_order: 21,
        sort_method: SortingMethods.CUSTOM_SORT_ORDER,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Financial Information",
            identifier: "financial_information",
            order: 3
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: {
            identifier: "global.payment_methods",
            object_identifier: "global",
            type: "custom_dropdown"
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Delivery Method
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Delivery Method",
        identifier: "delivery_method",
        field_type: FieldTypes.TEXT_DROPDOWN,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        description: "The way this purchase_order will be sent to the customer.",
        help_text: "The way this purchase_order will be sent to the customer.",
        default_value: null,
        placeholder_text: "Select a Delivery Method...",
        is_active: true,
        is_visible: true,
        visible_order: 24,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 21,
        is_sortable: true,
        sort_order: 22,
        sort_method: SortingMethods.CUSTOM_SORT_ORDER,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Financial Information",
            identifier: "financial_information",
            order: 3
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: {
            identifier: "global.delivery_methods",
            object_identifier: "global",
            type: "custom_dropdown"
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Vendor Message
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Vendor Message",
        identifier: "vendor_message",
        field_type: FieldTypes.LONG_TEXT,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        description: "A message from the Vendor for an Purchase Order.",
        help_text: "A message from the Vendor for an Purchase Order.",
        default_value: null,
        placeholder_text: "Add a Purchase Order vendor message...",
        is_active: true,
        is_visible: true,
        visible_order: 25,
        is_required: false,
        is_editable: true,
        is_searchable: true,
        search_priority: 'B',
        is_filterable: false,
        filter_order: null,
        is_sortable: true,
        sort_order: 22,
        sort_method: SortingMethods.ALPHANUMERIC,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: false,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Financial Information",
            identifier: "financial_information",
            order: 3
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Period Start
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Period Start",
        identifier: "period_start",
        field_type: FieldTypes.DATE_TIME,
        data_type: DatabaseTypes.TIMESTAMPTZ,
        special_type: null,
        description: "Start of the usage period during which purchase_order items were added to this purchase_order.",
        help_text: "Start of the usage period during which purchase_order items were added to this purchase_order.",
        default_value: null,
        placeholder_text: "Select a Period Start date...",
        is_active: true,
        is_visible: true,
        visible_order: 26,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 23,
        is_sortable: true,
        sort_order: 23,
        sort_method: SortingMethods.CHRONOLOGICAL,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Financial Information",
            identifier: "financial_information",
            order: 3
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Period End
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Period End",
        identifier: "period_end",
        field_type: FieldTypes.DATE_TIME,
        data_type: DatabaseTypes.TIMESTAMPTZ,
        special_type: null,
        description: "End of the usage period during which purchase_order items were added to this purchase_order.",
        help_text: "End of the usage period during which purchase_order items were added to this purchase_order.",
        default_value: null,
        placeholder_text: "Select a Period End date...",
        is_active: true,
        is_visible: true,
        visible_order: 27,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 24,
        is_sortable: true,
        sort_order: 24,
        sort_method: SortingMethods.CHRONOLOGICAL,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Financial Information",
            identifier: "financial_information",
            order: 3
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Deposit
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Deposit",
        identifier: "deposit",
        field_type: FieldTypes.CURRENCY,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "The deposit amount applied to the purchase_order.",
        help_text: "The deposit amount applied to the purchase_order.",
        default_value: null,
        placeholder_text: "Enter deposit amount...",
        is_active: true,
        is_visible: true,
        visible_order: 28,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 25,
        is_sortable: true,
        sort_order: 25,
        sort_method: SortingMethods.NUMERIC,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Financial Information",
            identifier: "financial_information",
            order: 3
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Sub Total
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Sub Total",
        identifier: "subtotal",
        field_type: FieldTypes.CURRENCY,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Total of all subscriptions, purchase_order items, and prorations on the purchase_order before any purchase_order level discount or exclusive tax is applied. Item discounts are already incorporated",
        help_text: "Total before discounts and tax",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_visible: true,
        visible_order: 29,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        is_filterable: true,
        filter_order: 26,
        is_sortable: true,
        sort_order: 26,
        sort_method: SortingMethods.NUMERIC,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Financial Information",
            identifier: "financial_information",
            order: 3
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Sub Total Excluding Tax
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Sub Total Excluding Tax",
        identifier: "subtotal_excluding_tax",
        field_type: FieldTypes.CURRENCY,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "The integer amount in cents representing the subtotal of the purchase_order before any purchase_order level discount or tax is applied. Item discounts are already incorporated",
        help_text: "Total before tax but after discounts",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_visible: true,
        visible_order: 30,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        is_filterable: true,
        filter_order: 27,
        is_sortable: true,
        sort_order: 27,
        sort_method: SortingMethods.NUMERIC,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Financial Information",
            identifier: "financial_information",
            order: 3
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Discounts
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Discounts",
        identifier: "discounts",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT_ARRAY,
        special_type: null,
        description: "The discounts applied to the purchase_order. Line item discounts are applied before purchase_order discounts.",
        help_text: "Applied discounts",
        default_value: null,
        placeholder_text: "Select discounts to apply...",
        is_active: true,
        is_visible: true,
        visible_order: 31,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 28,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Financial Information",
            identifier: "financial_information",
            order: 3
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "discounts",
            filters: [{
                field: "is_active", 
                operator: "equals",
                values: [{
                    type: "value",
                    label: "True",
                    data: true
                }]
            }],
            display_field: "name",
            strict_filter_lookups: false,
            is_related_record_editable: false,
            is_related_record_creatable: false
        },
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Discount Amount
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Discount Amount",
        identifier: "discount_amount",
        field_type: FieldTypes.CURRENCY,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "The discount amount applied to this purchase_order.",
        help_text: "Total discount amount applied",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_visible: true,
        visible_order: 32,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 29,
        is_sortable: true,
        sort_order: 28,
        sort_method: SortingMethods.NUMERIC,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Financial Information",
            identifier: "financial_information",
            order: 3
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Is Taxable
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Is Taxable",
        identifier: "is_taxable",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "If true, this Purchase Order is taxable and uses the tax rates data.",
        help_text: "Whether tax should be applied",
        default_value: {
            data_type: DatabaseTypes.BOOLEAN,
            value: false
        },
        placeholder_text: null,
        is_active: true,
        is_visible: true,
        visible_order: 33,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 30,
        is_sortable: true,
        sort_order: 29,
        sort_method: SortingMethods.BOOLEAN,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Financial Information",
            identifier: "financial_information",
            order: 3
        },
        checkbox_labels: {
            true: "Taxable",
            false: "Not Taxable"
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Is Tax Applied After Discount
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Is Tax Applied After Discount",
        identifier: "is_tax_applied_after_discount",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "If true, the tax rates are applied after discounts are applied to the subtotal.",
        help_text: "Whether tax is calculated on discounted amount",
        default_value: {
            data_type: DatabaseTypes.BOOLEAN,
            value: false
        },
        placeholder_text: null,
        is_active: true,
        is_visible: true,
        visible_order: 34,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 31,
        is_sortable: true,
        sort_order: 30,
        sort_method: SortingMethods.BOOLEAN,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Financial Information",
            identifier: "financial_information",
            order: 3
        },
        checkbox_labels: {
            true: "Tax applied after discounts",
            false: "Tax applied before discounts"
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Tax Rates
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Tax Rates",
        identifier: "tax_rates",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT_ARRAY,
        special_type: null,
        description: "The tax rates applied to this purchase_order, if any.",
        help_text: "Applied tax rates",
        default_value: null,
        placeholder_text: "Select tax rates...",
        is_active: true,
        is_visible: true,
        visible_order: 35,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        is_filterable: true, 
        filter_order: 32,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: true,
        is_async: true,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Financial Information",
            identifier: "financial_information",
            order: 3
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "tax_rates",
            filters: [{
                field: "is_active",
                operator: "equals", 
                values: [{
                    type: "value",
                    label: "True",
                    data: true
                }]
            }],
            display_field: "name",
            strict_filter_lookups: false,
            is_related_record_editable: false,
            is_related_record_creatable: false
        },
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Tax Amount
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders', 
        sync_token: 1,
        label: "Tax Amount",
        identifier: "tax_amount",
        field_type: FieldTypes.CURRENCY,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "The total tax amount for a Purchase Order represents as a currency unit.",
        help_text: "Total tax amount",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_visible: true,
        visible_order: 36,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 33,
        is_sortable: true,
        sort_order: 31,
        sort_method: SortingMethods.NUMERIC,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Financial Information",
            identifier: "financial_information",
            order: 3
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Total Excluding Tax
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Total Excluding Tax",
        identifier: "total_excluding_tax",
        field_type: FieldTypes.CURRENCY,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "The amount amount for the purchase_order after discounts excluding taxes",
        help_text: "Total after discounts but before taxes",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_visible: true,
        visible_order: 37,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        is_filterable: true,
        filter_order: 34,
        is_sortable: true,
        sort_order: 32,
        sort_method: SortingMethods.NUMERIC,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Financial Information",
            identifier: "financial_information",
            order: 3
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Total
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Total",
        identifier: "total",
        field_type: FieldTypes.CURRENCY,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "The total amount for the purchase_order after discounts and taxes.",
        help_text: "Final total amount",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_visible: true,
        visible_order: 38,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        is_filterable: true,
        filter_order: 35,
        is_sortable: true,
        sort_order: 33,
        sort_method: SortingMethods.NUMERIC,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Financial Information",
            identifier: "financial_information",
            order: 3
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Amount Due
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Amount Due",
        identifier: "amount_due",
        field_type: FieldTypes.CURRENCY,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Final amount due at this time for this purchase_order.",
        help_text: "Amount currently due",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_visible: true,
        visible_order: 39,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        is_filterable: true,
        filter_order: 36,
        is_sortable: true,
        sort_order: 34,
        sort_method: SortingMethods.NUMERIC,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Financial Information",
            identifier: "financial_information",
            order: 3
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Amount Paid 
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Amount Paid",
        identifier: "amount_paid",
        field_type: FieldTypes.CURRENCY,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "The amount, in cents, that was paid of the Purchase Order.",
        help_text: "Amount already paid",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_visible: true,
        visible_order: 40,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        is_filterable: true,
        filter_order: 37,
        is_sortable: true,
        sort_order: 35,
        sort_method: SortingMethods.NUMERIC,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Financial Information",
            identifier: "financial_information",
            order: 3
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Amount Remaining
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Amount Remaining", 
        identifier: "amount_remaining",
        field_type: FieldTypes.CURRENCY,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "The difference between the amount due and the amount paid, in cents.",
        help_text: "Amount still to be paid",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_visible: true,
        visible_order: 41,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        is_filterable: true,
        filter_order: 38,
        is_sortable: true,
        sort_order: 36,
        sort_method: SortingMethods.NUMERIC,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Financial Information",
            identifier: "financial_information",
            order: 3
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Starting Balance
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Starting Balance",
        identifier: "starting_balance",
        field_type: FieldTypes.CURRENCY,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Starting customer balance before the purchase_order is finalized. If the purchase_order has not been finalized yet, this will be the current customer balance.",
        help_text: "Initial balance before purchase order",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_visible: true,
        visible_order: 42,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        is_filterable: true,
        filter_order: 39,
        is_sortable: true,
        sort_order: 37,
        sort_method: SortingMethods.NUMERIC,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Financial Information",
            identifier: "financial_information",
            order: 3
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Ending Balance
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Ending Balance",
        identifier: "ending_balance",
        field_type: FieldTypes.CURRENCY,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Ending customer balance after the purchase_order is finalized. Purchase Orders are finalized approximately an hour after successful webhook delivery or when payment collection is attempted for the purchase_order. If the purchase_order has not been finalized yet, this will be null.",
        help_text: "Final balance after purchase order",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_visible: true,
        visible_order: 43,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        is_filterable: true,
        filter_order: 40,
        is_sortable: true,
        sort_order: 38,
        sort_method: SortingMethods.NUMERIC,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Financial Information",
            identifier: "financial_information",
            order: 3
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Statement Descriptor
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Statement Descriptor",
        identifier: "statement_descriptor",
        field_type: FieldTypes.SHORT_TEXT,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        description: "Extra information about an purchase_order for the customer's credit card statement.",
        help_text: "Text shown on credit card statement",
        default_value: null,
        placeholder_text: "Enter statement descriptor...",
        is_active: true,
        is_visible: true,
        visible_order: 47,
        is_required: false,
        is_editable: true,
        is_searchable: true,
        search_priority: 'B',
        is_filterable: true,
        filter_order: 43,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Financial Information",
            identifier: "financial_information",
            order: 3
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Subscription Proration Date
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Subscription Proration Date",
        identifier: "subscriptions_proration_date",
        field_type: FieldTypes.DATE_TIME,
        data_type: DatabaseTypes.TIMESTAMPTZ,
        special_type: null,
        description: "Only set for upcoming purchase_orders that preview prorations. The time used to calculate prorations.",
        help_text: "Date used for proration calculations",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_visible: true,
        visible_order: 48,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 44,
        is_sortable: true,
        sort_order: 41,
        sort_method: SortingMethods.CHRONOLOGICAL,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Financial Information",
            identifier: "financial_information",
            order: 3
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Application Fee Amount
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Application Fee Amount",
        identifier: "application_fee_amount",
        field_type: FieldTypes.CURRENCY,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "The fee in cents that will be applied to the purchase_order when the purchase_order is paid.",
        help_text: "Additional processing fee amount",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_visible: true,
        visible_order: 49,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Financial Information",
            identifier: "financial_information",
            order: 3
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Primary Contact's Name
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Primary Contact's Name",
        identifier: "primary_name",
        field_type: FieldTypes.NAME,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "The primary contact's name.",
        help_text: "Name of main contact person",
        default_value: null,
        placeholder_text: "Enter primary contact name...",
        is_active: true,
        is_visible: true,
        visible_order: 50,
        is_required: false,
        is_editable: true,
        is_searchable: true,
        search_priority: 'A',
        is_filterable: true,
        filter_order: 45,
        is_sortable: true,
        sort_order: 42,
        sort_method: SortingMethods.NESTED_FIELD,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Contact Information",
            identifier: "contact_information",
            order: 4
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Primary Contact's Phone
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Primary Contact's Phone",
        identifier: "primary_phone",
        field_type: FieldTypes.PHONE,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "The primary contact's phone number.",
        help_text: "Phone number for main contact",
        default_value: null,
        placeholder_text: "Enter primary contact phone...",
        is_active: true,
        is_visible: true,
        visible_order: 51,
        is_required: false,
        is_editable: true,
        is_searchable: true,
        search_priority: 'A',
        is_filterable: true,
        filter_order: 46,
        is_sortable: true,
        sort_order: 43,
        sort_method: SortingMethods.NESTED_FIELD,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Contact Information",
            identifier: "contact_information",
            order: 4
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Primary Contact's Email
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Primary Contact's Email",
        identifier: "primary_email",
        field_type: FieldTypes.EMAIL,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "The primary contact's email.",
        help_text: "Email address for main contact",
        default_value: null,
        placeholder_text: "Enter primary contact email...",
        is_active: true,
        is_visible: true,
        visible_order: 52,
        is_required: false,
        is_editable: true,
        is_searchable: true,
        search_priority: 'A',
        is_filterable: false,
        filter_order: null,
        is_sortable: true,
        sort_order: 44,
        sort_method: SortingMethods.NESTED_FIELD,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Contact Information",
            identifier: "contact_information",
            order: 4
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Property's Address
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Property's Address",
        identifier: "property_addess",
        field_type: FieldTypes.ADDRESS,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "The primary property's address.",
        help_text: "Full address of main property",
        default_value: null,
        placeholder_text: "Enter property address...",
        is_active: true,
        is_visible: true,
        visible_order: 53,
        is_required: false,
        is_editable: true,
        is_searchable: true,
        search_priority: 'A',
        is_filterable: true,
        filter_order: 47,
        is_sortable: true,
        sort_order: 45,
        sort_method: SortingMethods.NESTED_FIELD,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Contact Information",
            identifier: "contact_information",
            order: 4
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Billing Contact's Name
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Billing Contact's Name",
        identifier: "billing_name",
        field_type: FieldTypes.NAME,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "The billing contact's name.",
        help_text: "Name of billing contact person",
        default_value: null,
        placeholder_text: "Enter billing contact name...",
        is_active: true,
        is_visible: true,
        visible_order: 54,
        is_required: false,
        is_editable: true,
        is_searchable: true,
        search_priority: 'B',
        is_filterable: true,
        filter_order: 48,
        is_sortable: true,
        sort_order: 46,
        sort_method: SortingMethods.NESTED_FIELD,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Contact Information",
            identifier: "contact_information",
            order: 4
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Billing Contact's Phone Number
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Billing Contact's Phone",
        identifier: "billing_phone",
        field_type: FieldTypes.PHONE,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "The billing contact's phone number.",
        help_text: "Phone number for billing contact",
        default_value: null,
        placeholder_text: "Enter billing contact phone...",
        is_active: true,
        is_visible: true,
        visible_order: 55,
        is_required: false,
        is_editable: true,
        is_searchable: true,
        search_priority: 'B',
        is_filterable: true,
        filter_order: 49,
        is_sortable: true,
        sort_order: 47,
        sort_method: SortingMethods.NESTED_FIELD,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Contact Information",
            identifier: "contact_information",
            order: 4
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Billing Contact's Email
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Billing Contact's Email",
        identifier: "billing_email",
        field_type: FieldTypes.EMAIL,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "The billing contact's email.",
        help_text: "Email address for billing contact",
        default_value: null,
        placeholder_text: "Enter billing contact email...",
        is_active: true,
        is_visible: true,
        visible_order: 56,
        is_required: false,
        is_editable: true,
        is_searchable: true,
        search_priority: 'B',
        is_filterable: false,
        filter_order: null,
        is_sortable: true,
        sort_order: 48,
        sort_method: SortingMethods.NESTED_FIELD,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Contact Information",
            identifier: "contact_information",
            order: 4
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Billing Property's Address
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Billing Property's Address",
        identifier: "billing_address",
        field_type: FieldTypes.ADDRESS,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "The billing address for this purchase_order.",
        help_text: "Full billing address",
        default_value: null,
        placeholder_text: "Enter billing address...",
        is_active: true,
        is_visible: true,
        visible_order: 57,
        is_required: false,
        is_editable: true,
        is_searchable: true,
        search_priority: 'B',
        is_filterable: true,
        filter_order: 50,
        is_sortable: true,
        sort_order: 49,
        sort_method: SortingMethods.NESTED_FIELD,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Contact Information",
            identifier: "contact_information",
            order: 4
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Out of Band
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Out of Band",
        identifier: "out_of_band",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "Represents if this Purchase Order payment has been out of band. Meaning the purchase_order has been paid outside of ProHelper.",
        help_text: "Whether paid outside system",
        default_value: {
            data_type: DatabaseTypes.BOOLEAN,
            value: false
        },
        placeholder_text: null,
        is_active: true,
        is_visible: true,
        visible_order: 62,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 55,
        is_sortable: true,
        sort_order: 54,
        sort_method: SortingMethods.BOOLEAN,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Purchase Order Checks",
            identifier: "purchase_order_checks",
            order: 5
        },
        checkbox_labels: {
            true: "Paid in Cash",
            false: "Not Paid in Cash"
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Is Active
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Is Active",
        identifier: "is_active",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "If True, this purchase_order is active.",
        help_text: "Whether purchase order is currently active",
        default_value: {
            data_type: DatabaseTypes.BOOLEAN,
            value: true
        },
        placeholder_text: null,
        is_active: true,
        is_visible: true,
        visible_order: 63,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 56,
        is_sortable: true,
        sort_order: 55,
        sort_method: SortingMethods.BOOLEAN,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Purchase Order Checks",
            identifier: "purchase_order_checks",
            order: 5
        },
        checkbox_labels: {
            true: "Active",
            false: "Not Active"
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Is On Hold
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Is On Hold",
        identifier: "is_on_hold",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "Indicates if the purchase order is temporarily paused",
        help_text: "Whether purchase order is on hold",
        default_value: {
            data_type: DatabaseTypes.BOOLEAN,
            value: false
        },
        placeholder_text: null,
        is_active: true,
        is_visible: true,
        visible_order: 64,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 57,
        is_sortable: true,
        sort_order: 56,
        sort_method: SortingMethods.BOOLEAN,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Purchase Order Checks",
            identifier: "purchase_order_checks",
            order: 5
        },
        checkbox_labels: {
            true: "On Hold",
            false: "Not On Hold"
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Is Closed
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Is Closed",
        identifier: "is_closed",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "If true this purchase_order has been closed or canceled and requires no further work or action. This is basically the inverted \"active\" field for purchase_orders. A job can be completed and closed, or a purchase_order can be closed but not completed.",
        help_text: "Whether purchase order is closed",
        default_value: {
            data_type: DatabaseTypes.BOOLEAN,
            value: false
        },
        placeholder_text: null,
        is_active: true,
        is_visible: true,
        visible_order: 65,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 58,
        is_sortable: true,
        sort_order: 57,
        sort_method: SortingMethods.BOOLEAN,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Purchase Order Checks",
            identifier: "purchase_order_checks",
            order: 5
        },
        checkbox_labels: {
            true: "Closed",
            false: "Not Closed"
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Is Completed
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Is Completed",
        identifier: "is_completed",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "If true, all the work by the assigned users has been completed and the purchase_order is done. \"Completed\" purchase_orders should be moved to \"Converted\" as soon as possible.",
        help_text: "Whether all work is completed",
        default_value: {
            data_type: DatabaseTypes.BOOLEAN,
            value: false
        },
        placeholder_text: null,
        is_active: true,
        is_visible: true,
        visible_order: 66,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 59,
        is_sortable: true,
        sort_order: 58,
        sort_method: SortingMethods.BOOLEAN,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Purchase Order Checks",
            identifier: "purchase_order_checks",
            order: 5
        },
        checkbox_labels: {
            true: "Completed",
            false: "Not Completed"
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Is Archived
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Is Archived",
        identifier: "is_archived",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "Represents if an Purchase Order is archived, hidden but not deleted. Users can still access, view, and restore archived Purchase Orders.",
        help_text: "Whether purchase order is archived",
        default_value: {
            data_type: DatabaseTypes.BOOLEAN,
            value: false
        },
        placeholder_text: null,
        is_active: true,
        is_visible: false,
        visible_order: null,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: {
            true: "Archived",
            false: "Not Archived"
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Is Deleted
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Is Deleted",
        identifier: "is_deleted",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "Represents if a user has deleted this Purchase Order. Cannot view or edit the Purchase Order if deleted is true. Kept in our database connections to not break connected links.",
        help_text: "Whether purchase order is deleted",
        default_value: {
            data_type: DatabaseTypes.BOOLEAN,
            value: false
        },
        placeholder_text: null,
        is_active: true,
        is_visible: false,
        visible_order: null,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: {
            true: "Deleted",
            false: "Not Deleted"
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Is Locked
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Is Locked",
        identifier: "is_locked",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "If true, an Purchase Order is locked permanently and it's data cannot be edited or deleted even by admin users.",
        help_text: "Whether purchase order is locked",
        default_value: {
            data_type: DatabaseTypes.BOOLEAN,
            value: false
        },
        placeholder_text: null,
        is_active: true,
        is_visible: true,
        visible_order: 77,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 65,
        is_sortable: true,
        sort_order: 68,
        sort_method: SortingMethods.BOOLEAN,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Purchase Order Checks",
            identifier: "purchase_order_checks",
            order: 5
        },
        checkbox_labels: {
            true: "Locked",
            false: "Not Locked"
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Is Draft
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Is Draft",
        identifier: "is_draft",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "If true, this Purchase Order is a draft Purchase Order; Saved details, but not ready for release yet.",
        help_text: "Whether purchase order is in draft state",
        default_value: {
            data_type: DatabaseTypes.BOOLEAN,
            value: false
        },
        placeholder_text: null,
        is_active: true,
        is_visible: false,
        visible_order: null,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: {
            true: "Draft",
            false: "Public"
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Is Template
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Is Template",
        identifier: "is_template",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "If true, this Purchase Order is a template for creating future Purchase Orders faster.",
        help_text: "Whether purchase order is a template",
        default_value: {
            data_type: DatabaseTypes.BOOLEAN,
            value: false
        },
        placeholder_text: null,
        is_active: true,
        is_visible: false,
        visible_order: null,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: {
            true: "Template",
            false: "Normal"
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Is Live
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Is Live",
        identifier: "is_live",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "Represents if the Purchase Order is live for regular use if true, or testing only.",
        help_text: "Whether purchase order is live or test data",
        default_value: {
            data_type: DatabaseTypes.BOOLEAN,
            value: true
        },
        placeholder_text: null,
        is_active: true,
        is_visible: false,
        visible_order: null,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: {
            true: "Live Data",
            false: "Test Data"
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Is Sub Purchase Order
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Is Sub Purchase Order",
        identifier: "is_sub_purchase_order",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "If true, this Purchase Order is a sub Purchase Order and has a parent Purchase Order associated with it. If true, the parent Purchase Order should be filled in.",
        help_text: "Whether purchase order is a sub-order",
        default_value: {
            data_type: DatabaseTypes.BOOLEAN,
            value: false
        },
        placeholder_text: null,
        is_active: true,
        is_visible: true,
        visible_order: 67,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 60,
        is_sortable: true,
        sort_order: 59,
        sort_method: SortingMethods.BOOLEAN,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Purchase Order Checks",
            identifier: "purchase_order_checks",
            order: 5
        },
        checkbox_labels: {
            true: "Sub Account",
            false: "Not Sub Account"
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Level
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Level",
        identifier: "level", 
        field_type: FieldTypes.INTEGER,
        data_type: DatabaseTypes.INTEGER,
        special_type: null,
        description: "Represents the depth in the overall Purchase Order structure. (Parent Purchase Order, Sub Purchase Orders) (Max Level: 3)",
        help_text: "Hierarchical depth level",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_visible: true,
        visible_order: 68,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 61,
        is_sortable: true,
        sort_order: 60,
        sort_method: SortingMethods.NUMERIC,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 6
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Parent Purchase Order
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Parent Purchase Order",
        identifier: "parent",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "If \"is_sub_purchase_order\" is true, this represents a link to the parent Purchase Order for this Purchase Order.",
        help_text: "Parent purchase order if this is a sub-order",
        default_value: null,
        placeholder_text: "Select parent purchase order...",
        is_active: true,
        is_visible: true,
        visible_order: 69,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 62,
        is_sortable: true,
        sort_order: 61,
        sort_method: SortingMethods.LINKED_FIELD,
        is_multi: false,
        is_async: true,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information", 
            identifier: "system_information",
            order: 6
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            lookup_type: "lookup_relationship",
            related_object_table: "purchase_orders",
            filters: [{
                field: "is_active",
                operator: "equals",
                values: [{
                    type: "value",
                    label: "True",
                    data: true
                }]
            }],
            on_related_delete: "restrict",
            display_name: "title"
        },
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Bill With Parent
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Bill With Parent",
        identifier: "bill_with_parent",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "If true, this Purchase Order is a sub Purchase Order and will be added to the total of the parent Purchase Order and paid under that.",
        help_text: "Whether to bill with parent purchase order",
        default_value: {
            data_type: DatabaseTypes.BOOLEAN,
            value: false
        },
        placeholder_text: null,
        is_active: true,
        is_visible: true,
        visible_order: 70,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 63,
        is_sortable: true,
        sort_order: 62,
        sort_method: SortingMethods.BOOLEAN,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 6
        },
        checkbox_labels: {
            true: "Sub Account",
            false: "Not Sub Account"
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Currency
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Currency",
        identifier: "currency",
        field_type: FieldTypes.TEXT_DROPDOWN,
        data_type: DatabaseTypes.TEXT,
        special_type: SpecialTypes.CURRENCY,
        description: "The preferred currency that this Purchase Order is paid in.",
        help_text: "Currency for amounts",
        default_value: {
            data_type: 'text',
            data: 'usd'
        },
        placeholder_text: "Select currency...",
        is_active: true,
        is_visible: true,
        visible_order: 71,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 64,
        is_sortable: true,
        sort_order: 63,
        sort_method: SortingMethods.LINKED_ALPHANUMERIC,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 6
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: {
            identifier: "static.currencies",
            object_identifier: "static",
            type: "text_dropdown"
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Language
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Language",
        identifier: "language",
        field_type: FieldTypes.TEXT_DROPDOWN,
        data_type: DatabaseTypes.TEXT,
        special_type: SpecialTypes.LANGUAGE,
        description: "This preferred language that this Purchase Order is in.",
        help_text: "Language for text content",
        default_value: {
            data_type: 'text',
            data: 'en'
        },
        placeholder_text: "Select language...",
        is_active: true,
        is_visible: true,
        visible_order: 72,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 65,
        is_sortable: true,
        sort_order: 64,
        sort_method: SortingMethods.LINKED_ALPHANUMERIC,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 6
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: {
            identifier: "static.languages",
            object_identifier: "static", 
            type: "text_dropdown"
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Time Zone
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Time Zone",
        identifier: "time_zone",
        field_type: FieldTypes.TEXT_DROPDOWN,
        data_type: DatabaseTypes.TEXT,
        special_type: SpecialTypes.TIME_ZONE,
        description: "The time zone for this Purchase Order.",
        help_text: "Time zone for dates/times",
        default_value: null,
        placeholder_text: "Select a Time Zone...",
        is_active: true,
        is_visible: true,
        visible_order: 73,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 66,
        is_sortable: true,
        sort_order: 65,
        sort_method: SortingMethods.LINKED_ALPHANUMERIC,
        is_multi: false,
        is_async: true,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 6
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: {
            identifier: "static.time_zones",
            object_identifier: "static",
            type: "text_dropdown"
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Country
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Country",
        identifier: "country",
        field_type: FieldTypes.TEXT_DROPDOWN,
        data_type: DatabaseTypes.TEXT,
        special_type: SpecialTypes.COUNTRY,
        description: "The Country that the Purchase Order is located in.",
        help_text: "Country for tax/legal purposes",
        default_value: null,
        placeholder_text: "Select country...",
        is_active: true,
        is_visible: true,
        visible_order: 74,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 67,
        is_sortable: true,
        sort_order: 66,
        sort_method: SortingMethods.LINKED_ALPHANUMERIC,
        is_multi: false,
        is_async: true,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 6
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: {
            identifier: "static.countries",
            object_identifier: "static",
            type: "text_dropdown",
            on_delete: "cascade"
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Hosted Purchase Order URL
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Hosted Purchase Order URL",
        identifier: "hosted_purchase_order_url",
        field_type: FieldTypes.URL,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        description: "The URL where the purchase_order can be accessed.",
        help_text: "Web link to view purchase order",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_visible: true,
        visible_order: 75,
        is_required: false,
        is_editable: false,
        is_searchable: true,
        search_priority: 'B',
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: true,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 6
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Domain
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Domain",
        identifier: "domain",
        field_type: FieldTypes.TEXT_DROPDOWN,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        description: "Represents the platform/domain the Purchase Order was created on. Can be created by ProHelper or other platforms through integrations.",
        help_text: "Source system/platform",
        default_value: {
            data_type: 'text',
            data: 'pro'
        },
        placeholder_text: null,
        is_active: true,
        is_visible: true,
        visible_order: 76,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 6
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: {
            identifier: "global.domains",
            object_identifier: "global",
            type: "text_dropdown",
            on_delete: "cascade"
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Pop-Up Alert
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Pop-Up Alert",
        identifier: "popup_alert",
        field_type: FieldTypes.ALERT,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "If \"is_popup_required\" is true, this field can show an important message to users about this Purchase Order. Shows at the top of Purchase Order page layouts.",
        help_text: "Important alert message",
        default_value: null,
        placeholder_text: "Enter alert message...",
        is_active: true,
        is_visible: true,
        visible_order: 77,
        is_required: false,
        is_editable: true,
        is_searchable: true,
        search_priority: 'B',
        is_filterable: true,
        filter_order: 68,
        is_sortable: true,
        sort_order: 67,
        sort_method: SortingMethods.ALPHANUMERIC,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Purchase Order Alerts",
            identifier: "purchase_order_alerts",
            order: 7
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Completed Alert
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Completed Alert",
        identifier: "completed_alert",
        field_type: FieldTypes.ALERT,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "Represents and alert message with information around why this Purchase Order is completed.",
        help_text: "Alert message about completion",
        default_value: null,
        placeholder_text: "Enter completed alert message...",
        is_active: true,
        is_visible: true,
        visible_order: 78,
        is_required: false,
        is_editable: true,
        is_searchable: true,
        search_priority: 'C',
        is_filterable: true,
        filter_order: 69,
        is_sortable: true,
        sort_order: 68,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "sytem_information",
            order: 4
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Closed Alert
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Closed Alert",
        identifier: "closed_alert",
        field_type: FieldTypes.ALERT,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "Contains information and an alert message with information around why this Purchase Order is closed.",
        help_text: "Alert message about closure",
        default_value: null,
        placeholder_text: "Enter closed alert message...",
        is_active: true,
        is_visible: true,
        visible_order: 79,
        is_required: false,
        is_editable: true,
        is_searchable: true,
        search_priority: 'C',
        is_filterable: true,
        filter_order: 70,
        is_sortable: true,
        sort_order: 69,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "sytem_information",
            order: 4
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Locked Alert
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Locked Alert",
        identifier: "locked_alert",
        field_type: FieldTypes.ALERT,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "Represents additional text to explain why a Purchase Order is locked.",
        help_text: "Alert message about locked status",
        default_value: null,
        placeholder_text: "Enter locked alert message...",
        is_active: true,
        is_visible: true,
        visible_order: 80,
        is_required: false,
        is_editable: true,
        is_searchable: true,
        search_priority: 'C',
        is_filterable: true,
        filter_order: 71,
        is_sortable: true,
        sort_order: 70,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Purchase Order Alerts",
            identifier: "purchase_order_alerts",
            order: 7
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // On Hold Alert
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "On Hold Alert",
        identifier: "on_hold_alert",
        field_type: FieldTypes.ALERT,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "This field can show a message to users about why this Purchase Order is on hold. Shows at the top of account page layouts.",
        help_text: "Alert message about hold status",
        default_value: null,
        placeholder_text: "Enter on-hold alert message...",
        is_active: true,
        is_visible: true,
        visible_order: 81,
        is_required: false,
        is_editable: true,
        is_searchable: true,
        search_priority: 'D',
        is_filterable: true,
        filter_order: 72,
        is_sortable: true,
        sort_order: 71,
        sort_method: SortingMethods.NESTED_FIELD,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Purchase Order Alerts",
            identifier: "purchase_order_alerts",
            order: 7
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Created At
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Created At", 
        identifier: "created_at",
        field_type: FieldTypes.DATE_TIME,
        data_type: DatabaseTypes.TIMESTAMPTZ,
        special_type: null,
        description: "Represents the timestamp the user created this Purchase Order in the system.",
        help_text: "When purchase order was created",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_visible: true,
        visible_order: 82,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        is_filterable: true,
        filter_order: 73,
        is_sortable: true,
        sort_order: 72,
        sort_method: SortingMethods.CHRONOLOGICAL,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 6
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Created By
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Created By",
        identifier: "created_by",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Represents the user who created this Purchase Order in the system.",
        help_text: "User who created purchase order",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_visible: true,
        visible_order: 83,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        is_filterable: true,
        filter_order: 74,
        is_sortable: true,
        sort_order: 73,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 6
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "users",
            filters: [{
                field: "is_active",
                operator: "equals",
                values: [{
                    type: "value",
                    label: "True",
                    data: true
                }]
            }],
            display_field: "name",
            strict_filter_lookups: false,
            is_related_record_editable: false,
            is_related_record_creatable: false
        },
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Last Updated At
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Last Updated At",
        identifier: "last_updated_at",
        field_type: FieldTypes.DATE_TIME,
        data_type: DatabaseTypes.TIMESTAMPTZ,
        special_type: null,
        description: "Represents the last timestamp when a user updated this Purchase Order's information.",
        help_text: "When purchase order was last modified",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_visible: true,
        visible_order: 84,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        is_filterable: true,
        filter_order: 75,
        is_sortable: true,
        sort_order: 74,
        sort_method: SortingMethods.CHRONOLOGICAL,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 6
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Last Updated By
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Last Updated By",
        identifier: "last_updated_by",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Represents the user who last updated this account's information. System generated.",
        help_text: "User who last modified purchase order",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_visible: true,
        visible_order: 85,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        is_filterable: true,
        filter_order: 76,
        is_sortable: true,
        sort_order: 75,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 6
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "users",
            filters: [{
                field: "is_active",
                operator: "equals",
                values: [{
                    type: "value",
                    label: "True",
                    data: true
                }]
            }],
            display_field: "name",
            strict_filter_lookups: false,
            is_related_record_editable: false,
            is_related_record_creatable: false
        },
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Last Viewed At
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Last Viewed At",
        identifier: "last_viewed_at",
        field_type: FieldTypes.DATE_TIME,
        data_type: DatabaseTypes.DATE,
        special_type: null,
        description: "Represents the last timestamp when a user last viewed this Purchase Order's detail page.",
        help_text: "When purchase order was last viewed",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_visible: true,
        visible_order: 86,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        is_filterable: true,
        filter_order: 77,
        is_sortable: true,
        sort_order: 76,
        sort_method: SortingMethods.CHRONOLOGICAL,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 6
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Last Viewed By
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Last Viewed By",
        identifier: "last_viewed_by",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Represents the last user last viewed this Purchase Order's detail page.",
        help_text: "User who last viewed purchase order",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_visible: true,
        visible_order: 87,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        is_filterable: true,
        filter_order: 78,
        is_sortable: true,
        sort_order: 77,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 6
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "users",
            filters: [{
                field: "is_active",
                operator: "equals",
                values: [{
                    type: "value",
                    label: "True",
                    data: true
                }]
            }],
            display_field: "name",
            strict_filter_lookups: false,
            is_related_record_editable: false,
            is_related_record_creatable: false
        },
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Last Activity At
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Last Activity At",
        identifier: "last_activity_at",
        field_type: FieldTypes.DATE_TIME,
        data_type: DatabaseTypes.TIMESTAMPTZ,
        special_type: null,
        description: "Represent the last timestamp when a related item to an Purchase Order was created, updated, or deleted.",
        help_text: "When last activity occurred",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_visible: true,
        visible_order: 88,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        is_filterable: true,
        filter_order: 79,
        is_sortable: true,
        sort_order: 78,
        sort_method: SortingMethods.CHRONOLOGICAL,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 6
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Last Activity By
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Last Activity By",
        identifier: "last_activity_by",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Represent the last user id who created, updated, or deleted a related item to an Purchase Order.",
        help_text: "User who performed last activity",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_visible: true,
        visible_order: 89,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        is_filterable: true,
        filter_order: 80,
        is_sortable: true,
        sort_order: 79,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 6
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "users",
            filters: [{
                field: "is_active",
                operator: "equals",
                values: [{
                    type: "value",
                    label: "True",
                    data: true
                }]
            }],
            display_field: "name",
            strict_filter_lookups: false,
            is_related_record_editable: false,
            is_related_record_creatable: false
        },
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Completed At
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Completed At",
        identifier: "completed_at",
        field_type: FieldTypes.DATE_TIME,
        data_type: DatabaseTypes.TIMESTAMPTZ,
        special_type: null,
        description: "References the timestamp when the user completed this job.",
        help_text: "When purchase order was completed",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_visible: true,
        visible_order: 90,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        is_filterable: true,
        filter_order: 81,
        is_sortable: true,
        sort_order: 80,
        sort_method: SortingMethods.CHRONOLOGICAL,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 6
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Completed By
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Completed By",
        identifier: "completed_by",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "References the user id who completed this Purchase Order.",
        help_text: "User who completed purchase order",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_visible: true,
        visible_order: 91,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 82,
        is_sortable: true,
        sort_order: 81,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 6
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent", 
            object_identifier: "users",
            filters: [{
                field: "is_active",
                operator: "equals",
                values: [{
                    type: "value",
                    label: "True",
                    data: true
                }]
            }],
            display_field: "name",
            strict_filter_lookups: false,
            is_related_record_editable: false,
            is_related_record_creatable: false
        },
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Closed At
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Closed At",
        identifier: "closed_at",
        field_type: FieldTypes.DATE_TIME,
        data_type: DatabaseTypes.TIMESTAMPTZ,
        special_type: null,
        description: "References the timestamp when the user closed this Purchase Order.",
        help_text: "When purchase order was closed",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_visible: true,
        visible_order: 92,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        is_filterable: true,
        filter_order: 83,
        is_sortable: true,
        sort_order: 82,
        sort_method: SortingMethods.CHRONOLOGICAL,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 6
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Closed By
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Closed By",
        identifier: "closed_by",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "References the user id who closed this Purchase Order.",
        help_text: "User who closed purchase order",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_visible: true,
        visible_order: 93,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 84,
        is_sortable: true,
        sort_order: 83,
        sort_method: SortingMethods.LINKED_ALPHANUMERIC,
        is_multi: false,
        is_async: true,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 6
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "users",
            filters: [{
                field: "is_active",
                operator: "equals",
                values: [{
                    type: "value",
                    label: "True",
                    data: true
                }]
            }],
            display_field: "name",
            strict_filter_lookups: false,
            is_related_record_editable: false,
            is_related_record_creatable: false
        },
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Archived Alert
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Archived Alert",
        identifier: "archived_alert",
        field_type: FieldTypes.ALERT,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "A text area to show the reasoning behind the archival of this Purchase Order.",
        help_text: "Alert message about archival",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Archived At
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Archived At",
        identifier: "archived_at",
        field_type: FieldTypes.DATE_TIME,
        data_type: DatabaseTypes.TIMESTAMPTZ,
        special_type: null,
        description: "Represents the timestamp the user archived this Purchase Order. System generated timestamp.",
        help_text: "When purchase order was archived",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Archived By
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Archived By",
        identifier: "archived_by",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Represents the user who archived this Purchase Order. System generated.",
        help_text: "User who archived purchase order",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "users",
            filters: [{
                field: "is_active",
                operator: "equals",
                values: [{
                    type: "value",
                    label: "True",
                    data: true
                }]
            }],
            display_field: "name",
            strict_filter_lookups: false,
            is_related_record_editable: false,
            is_related_record_creatable: false
        },
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Suggested Action
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Suggested Action",
        identifier: "suggested_action",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "The first shown item action for a Record shown on the List View and Page Layout screens. Can be system generated.",
        help_text: "Recommended next action",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Sync Token
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Sync Token",
        identifier: "sync_token",
        field_type: FieldTypes.HIDDEN,
        data_type: DatabaseTypes.INTEGER,
        special_type: null,
        description: "Represents the current sync token of this object. If a user tries an operation on this account with an invalid sync token, they operation will fail. (i.e. Offline-Conflicting Issue)",
        help_text: "Version control token",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Metadata
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Metadata",
        identifier: "metadata",
        field_type: FieldTypes.JSON,
        data_type: DatabaseTypes.JSONB,
        special_type: SpecialTypes.METADATA,
        description: "Some extra metadata that can be added to a Purchase Orders.",
        help_text: "Additional metadata for the Purchase Orders record.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Custom Fields
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Custom Fields",
        identifier: "custom_fields",
        field_type: FieldTypes.JSON,
        data_type: DatabaseTypes.JSONB,
        special_type: SpecialTypes.CUSTOM_FIELDS,
        description: "A JSON storing custom fields and values that match the custom Purchase Order object fields.",
        help_text: "Custom fields data for this Purchase Order.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Settings
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Settings",
        identifier: "settings",
        field_type: FieldTypes.JSON,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "A JSON object representing some settings for this purchase order.",
        help_text: "Purchase Order specific settings and configurations.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Notifications
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Notifcations",
        identifier: "notifications",
        field_type: "json",
        data_type: "jsonb",
        special_type: null,
        description: "Notification toggles for the Purchase Orders.",
        help_text: "",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
];

export default PurchaseOrderFieldsData;

//
// A file containing all the different special types for object_fields & dropdown_sets
// This file is created to maintain a centeral name for special types around the program.
// 


export const PUBLIC_ID = 'public_id';
export const MAIN_LABEL = 'main_label';
export const MAIN_TYPE = 'main_type';
export const MAIN_BALANCE = 'main_balance';
export const CURRENCY = 'currency';
export const TIME_ZONE = 'time_zone';
export const COUNTRY = 'country';
export const ADDRESS_CITY = 'address_city';
export const ADDRESS_POSTAL_CODE = 'address_postal_code';
export const ADDRESS_STATE = 'address_state';
export const LANGUAGE = 'language';
export const METADATA = 'metadata';
export const CUSTOM_FIELDS = 'custom_fields';


// This constants file is a representation of how data for object types will be recieved by the ProHelper database once linked.
import uniqid from 'uniqid';


const VehicleObjectTypesData = [

    // ----------------------------------------
    // Vehicle Object Types
    // ----------------------------------------

    // Car
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "vehicles",
        sync_token: 1,
        label: "Car",
        identifier: "car",
        description: "A car",
        sort_order: 1,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Truck
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "vehicles",
        sync_token: 1,
        label: "Truck",
        identifier: "truck",
        description: "A truck",
        sort_order: 1,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Van
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "vehicles",
        sync_token: 1,
        label: "Van",
        identifier: "van",
        description: "A van",
        sort_order: 1,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Service Van
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "vehicles",
        sync_token: 1,
        label: "Service Van",
        identifier: "service_van",
        description: "A service van",
        sort_order: 1,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Repair Van
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "vehicles",
        sync_token: 1,
        label: "Repair Van",
        identifier: "repair_van",
        description: "A repair van",
        sort_order: 1,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Trailer
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "vehicles",
        sync_token: 1,
        label: "trailer",
        identifier: "trailer",
        description: "A trailer",
        sort_order: 1,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
];

export default VehicleObjectTypesData;
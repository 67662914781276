
// Import Modules
import uniqid from 'uniqid';
import { identifier } from 'safe-identifier';
import * as ROUTES from '../../../../navigation/routes';
import * as ScreenTypes from '../../../../types/ScreenTypes';
import * as ScreenIdentifiers from '../../../../screens/ScreenIdentifiers';
import * as ComponentTypes from '../../../../types/ProHelperComponentTypes';


const VehiclePageLayoutsData = [
    // Default
    {
        public_id: uniqid('page_'),
        sync_token: 1,
        identifier: identifier('Default').toLowerCase(),
        label: 'Default',
        description: 'View a Vehicle in detail.',
        status: 'published',
        revisions: 1,
        route: ROUTES.USER_DETAIL_PREFIX,
        type: ScreenTypes.OBJECT_PAGE_LAYOUT,
        screen_identifier: ScreenIdentifiers.USER_PAGE_LAYOUT,
        template: null,
        object: null,
        object_identifier: 'vehicles',
        object_type: null,
        group: {
            label: 'Team',
            identifier: 'team',
            order: 6
        },
        sub_group: {
            label: 'Vehicles',
            identifier: 'vehicles',
            order: 2
        },
        components: {
            component_1: {
                id: "component_1",
                label: 'Header',
                object_identifier: 'vehicles',
                connection_type: 'parent',
                type: ComponentTypes.OBJECT_HEADER,
                header_type: 'action_bar',
                special_type: 'header',
                is_editable: true,
                visibility: ['mobile', 'tablet', 'desktop'],
                metadata: {
                    fields: [
                        {
                            label: "Title",
                            identifier: "vehicles.title",
                            is_required: true,
                            is_read_only: false,
                            is_shown_if_empty: true
                        },
                        {
                            label: "Fleet Number",
                            identifier: "vehicles.fleet_number",
                            is_required: false,
                            is_read_only: false,
                            is_shown_if_empty: true
                        },
                        {
                            label: "Type",
                            identifier: "vehicles.type",
                            is_required: false,
                            is_read_only: false,
                            is_shown_if_empty: true
                        },
                        {
                            label: "Status",
                            identifier: "vehicles.status",
                            is_required: false,
                            is_read_only: false,
                            is_shown_if_empty: true
                        },
                        {
                            label: "Driver",
                            identifier: "vehicles.driver",
                            is_required: false,
                            is_read_only: false,
                            is_shown_if_empty: true
                        },
                        {
                            label: "Notes",
                            identifier: "vehicles.notes",
                            is_required: false,
                            is_read_only: false,
                            is_shown_if_empty: true
                        },
                    ],
                    first_visible_amount: {
                        mobile: 4,
                        tablet: 4,
                        desktop: 2
                    }
                }
            },
            component_2: {
                id: "component_2",
                label: 'Details',
                object_identifier: 'vehicles',
                connection_type: 'parent',
                type: ComponentTypes.OBJECT_DETAILS,
                header_type: 'normal',
                special_type: null,
                visibility: ['mobile', 'tablet', 'desktop'],
                is_editable: true,
                metadata: {
                    sections: [
                        // General 
                        {
                            label: "General Information",
                            id: 'general_info',
                            tab_order: 'left-right',
                            visibility: ['mobile', 'tablet', 'desktop'],
                            is_locked: false,
                            show_on_first_load: {
                                mobile: false,
                                tablet: true,
                                desktop: true
                            },
                            first_visible_amount: {
                                mobile: 4,
                                tablet: 4,
                                desktop: 4
                            },
                            fields: [
                                {
                                    label: "Fleet Number",
                                    identifier: "vehicles.fleet_number",
                                    is_required: true,
                                    is_read_only: false,
                                    is_shown_if_empty: false
                                },
                                {
                                    label: "License Plate",
                                    identifier: "vehicles.license_plate",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Vin Number",
                                    identifier: "vehicles.vin_number",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Department",
                                    identifier: "vehicles.department",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Hub Address",
                                    identifier: "vehicles.hub_address",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                            ],
                        },
                        // Vehicle Information
                        {
                            label: "Vehicle Information",
                            id: 'vehicle_information',
                            tab_order: 'top-bottom',
                            visibility: ['mobile', 'tablet', 'desktop'],
                            is_locked: false,
                            show_on_first_load: {
                                mobile: false,
                                tablet: false,
                                desktop: true
                            },
                            first_visible_amount: {
                                mobile: 4,
                                tablet: 4,
                                desktop: 4
                            },
                            fields: [
                                {
                                    label: "Make",
                                    identifier: "vehicles.make",
                                    is_required: true,
                                    is_read_only: true,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Model",
                                    identifier: "vehicles.model",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Trim",
                                    identifier: "vehicles.trim",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Year",
                                    identifier: "vehicles.year",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Color",
                                    identifier: "vehicles.color",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Fuel Type",
                                    identifier: "vehicles.fuel_type",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Fuel Efficiency Avg.",
                                    identifier: "vehicles.fuel_efficiency_avg",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                }
                            ],
                        },
                        // Maintenance Information
                        {
                            label: "Maintenance Information",
                            id: 'maintenance_information',
                            tab_order: 'top-bottom',
                            visibility: ['mobile', 'tablet', 'desktop'],
                            is_locked: false,
                            show_on_first_load: {
                                mobile: false,
                                tablet: false,
                                desktop: true
                            },
                            first_visible_amount: {
                                mobile: 4,
                                tablet: 4,
                                desktop: 4
                            },
                            fields: [
                                {
                                    label: "Current Mileage",
                                    identifier: "vehicles.current_mileage",
                                    is_required: true,
                                    is_read_only: true,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Last Maintenance Mileage",
                                    identifier: "vehicles.last_maintenance_mileage",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Last Maintenance Date",
                                    identifier: "vehicles.last_maintenance_date",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Next Maintenance Date",
                                    identifier: "vehicles.next_maintenance_mileage",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Next Maintenance Mileage",
                                    identifier: "vehicles.next_maintenance_date",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Maintenance Interval Miles",
                                    identifier: "vehicles.maintenance_interval_miles",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Smog Notes",
                                    identifier: "vehicles.smog_notes",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                            ],
                        },
                        // Asset Information
                        {
                            label: "Asset Information",
                            id: 'asset_information',
                            tab_order: 'top-bottom',
                            visibility: ['mobile', 'tablet', 'desktop'],
                            is_locked: false,
                            show_on_first_load: {
                                mobile: false,
                                tablet: false,
                                desktop: true
                            },
                            first_visible_amount: {
                                mobile: 4,
                                tablet: 4,
                                desktop: 4
                            },
                            fields: [
                                {
                                    label: "Purchase Date",
                                    identifier: "vehicles.purchase_date",
                                    is_required: true,
                                    is_read_only: true,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Purchase Price",
                                    identifier: "vehicles.purchase_price",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Current Value",
                                    identifier: "vehicles.current_value",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Loan Notes",
                                    identifier: "vehicles.loan_notes",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                }
                            ],
                        },
                        // System Information
                        {
                            label: "System Information",
                            id: 'system_info',
                            tab_order: 'left-right',
                            visibility: ['mobile', 'tablet', 'desktop'],
                            is_locked: false,
                            show_on_first_load: {
                                mobile: false,
                                tablet: false,
                                desktop: true
                            },
                            first_visible_amount: {
                                mobile: 4,
                                tablet: 4,
                                desktop: 4
                            },
                            fields: [
                                {
                                    label: "Popup Alert",
                                    identifier: "vehicles.popup_alert",
                                    is_required: false,
                                    is_read_only: false,
                                    is_shown_if_empty: true
                                },
                                {
                                    label: "Vehicle ID",
                                    identifier: "vehicles.vehicle_id",
                                    is_required: true,
                                    is_read_only: true,
                                    is_shown_if_empty: true
                                }
                            ],
                        },
                    ]
                }
            },
            component_3: {
                id: "component_3",
                label: 'Activity',
                object_identifier: 'vehicles',
                connection_type: 'parent',
                type: ComponentTypes.ACTIVITY,
                special_type: null,
                header_type: 'normal',
                is_editable: false,
                visibility: ['mobile', 'tablet', 'desktop'],
                metadata: {
                    activities: [
                        // Tasks
                        {
                            label: 'Tasks',
                            identifier: 'tasks_0',
                            object_identifier: 'tasks',
                            display_field: 'tasks.subject',
                            show_records_container_on_first_load: {
                                mobile: false,
                                tablet: true,
                                desktop: true
                            },
                            record_cells_first_visible_amount: {
                                mobile: 3,
                                tablet: 2,
                                desktop: 2
                            },
                            filters: [
                                {
                                    fields: ["tasks.is_active"],
                                    field_types: ["checkbox"],
                                    operator: "equal",
                                    values: [
                                        {
                                            type: "value",
                                            label: 'True',
                                            input: true,
                                            data: true,
                                            data_type: 'boolean',
                                            status: 'valid'
                                        }
                                    ]
                                }
                            ],
                            sort_field: "tasks.last_updated_at",
                            sort_by: ["tasks.last_updated_at"],
                            sort_method: "chronological",
                            sort_direction: "DESC",
                            sort_nulls_last: true,
                            sort_join: [],
                            search_value: '',
                            item_actions: [
                                "tasks.edit",
                                "tasks.mark_completed",
                                "tasks.mark_closed",
                                "tasks.convert_to_job",
                                "tasks.archive",
                                "tasks.delete"
                            ],
                            fields: [
                                {
                                    label: 'Subject',
                                    identifier: 'tasks.subject',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["tasks.subject"],
                                    display_format: null
                                },
                                {
                                    label: 'Description',
                                    identifier: 'tasks.description',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["tasks.description"],
                                    display_format: null
                                },
                                {
                                    label: 'Type',
                                    identifier: 'tasks.type',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["tasks.type"],
                                    display_format: null
                                },
                                {
                                    label: 'Status',
                                    identifier: 'tasks.status',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["tasks.status", "dropdowns.label"],
                                    display_format: null
                                },
                                {
                                    label: 'Start Date',
                                    identifier: 'tasks.start_date',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["tasks.start_date"],
                                    display_format: "MMMM Do YYYY, h:mm a"
                                },
                                {
                                    label: 'Due Date',
                                    identifier: 'tasks.due_date',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["tasks.due_date"],
                                    display_format: "MMMM Do YYYY, h:mm a"
                                },
                                {
                                    label: 'Assigned To',
                                    identifier: 'tasks.assigned_to',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["tasks.assigned_to", "users.name", "name.display"],
                                    display_format: null
                                },
                            ]
                        },
                        // Notes
                        {
                            label: 'Notes',
                            identifier: 'notes_3',
                            object_identifier: 'notes',
                            display_field: 'notes.title',
                            show_records_container_on_first_load: {
                                mobile: false,
                                tablet: true,
                                desktop: true
                            },
                            record_cells_first_visible_amount: {
                                mobile: 3,
                                tablet: 2,
                                desktop: 2
                            },
                            filters: [
                                {
                                    fields: ["notes.is_active"],
                                    field_types: ["checkbox"],
                                    operator: "equal",
                                    values: [
                                        {
                                            type: "value",
                                            label: 'True',
                                            input: true,
                                            data: true,
                                            data_type: 'boolean',
                                            status: 'valid'
                                        }
                                    ]
                                }
                            ],
                            sort_field: "notes.created_at",
                            sort_by: ["notes.created_at"],
                            sort_method: "chronological",
                            sort_direction: "DESC",
                            sort_nulls_last: true,
                            sort_join: [],
                            search_value: '',
                            item_actions: [],
                            fields: [
                                {
                                    label: 'Title',
                                    identifier: 'notes.title',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["notes.title"],
                                    display_format: null
                                },
                                {
                                    label: 'Status',
                                    identifier: 'notes.status',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["notes.status","dropdowns.label"],
                                    display_format: null
                                },
                                {
                                    label: 'Created At',
                                    identifier: 'notes.created_at',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["notes.created_at"],
                                    display_format: "MMMM Do YYYY, h:mm a"
                                },
                                {
                                    label: 'Created By',
                                    identifier: 'notes.created_by',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["notes.created_by","users.name","name.display"],
                                    display_format: null
                                },
                                {
                                    label: 'Body',
                                    identifier: 'notes.body',
                                    is_read_only: false,
                                    is_visible: true,
                                    display_fields: ["notes.body"],
                                    display_format: null
                                }
                            ]
                        }
                    ]
                }
            },
            component_4: {
                id: "component_6",
                label: 'Timeline',
                object_identifier: 'accounts',
                connection_type: 'parent',
                type: ComponentTypes.TIMELINE,
                header_type: 'normal',
                special_type: null,
                is_editable: false,
                visibility: ['mobile', 'tablet', 'desktop'],
                metadata: {
                    notifcations: {
                        contacts: true,
                        properties: true,
                        tasks: true,
                        events: true,
                        notes: true,
                        attachments: true
                    }
                }
            },
        },
        sections: [
            {
                id: uniqid('section_'),
                anchor_name: 'header',
                visibility: ['mobile', 'tablet', 'desktop'],
                template_label: 'Full',
                template_identifier: 'full',
                shrink_method: 'seperate',
                columns: {
                    mobile: [{ id: '1', flexSize: 1, components: ["component_1"] }],
                    tablet: [{ id: '1', flexSize: 1, components: ["component_1"] }],
                    desktop: [{ id: '1', flexSize: 1, components: ["component_1"] }]
                }
            },
            {
                id: uniqid('section_'),
                anchor_name: 'details',
                visibility: ['mobile', 'tablet', 'desktop'],
                template_label: 'Full',
                template_identifier: 'full',
                shrink_method: 'seperate',
                columns: {
                    mobile: [{ id: '2', flexSize: 1, components: ["component_2"] }],
                    tablet: [{ id: '2', flexSize: 1, components: ["component_2"] }],
                    desktop: [{ id: '2', flexSize: 1, components: ["component_2"] }]
                }
            },
            {
                id: uniqid('section_'),
                anchor_name: 'activities_timeline',
                visibility: ['mobile', 'tablet', 'desktop'],
                template_label: null,
                template_identifier: null,
                shrink_method: 'tabs',
                columns: {
                    mobile: [{ id: '3', flexSize: 1, components: [["component_3", "component_4"]] }],
                    tablet: [{ id: '3', flexSize: 2, components: ["component_3"] }, { id: '4', flexSize: 1, components: ["component_4"] }],
                    desktop: [{ id: '3', flexSize: 2, components: ["component_3"] }, { id: '4', flexSize: 1, components: ["component_4"] }]
                }
            }
        ],
        item_actions: [
            "vehicles.duplicate",
            "vehicles.archive"
        ],
        more_actions: [ ],
        sort_order: 1,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        read_access: "all_users",
        edit_access: "private",
        created_at: new Date(),
        created_by: null,
        last_updated_at: new Date(),
        last_updated_by: null,
        last_viewed_at: new Date(),
        last_viewed_by: null,
        published_at: new Date(),
        published_by: null,
        
    }
];

export default VehiclePageLayoutsData;
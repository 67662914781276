// Import NPM Modules
import _ from 'lodash';

// Import Custom Modules
import * as ROUTES from '../../constants/navigation/routes';
import * as ScreenTypes from '../../constants/types/ScreenTypes';
import * as ScreenIdentifiers from '../../constants/screens/ScreenIdentifiers';

export default class LayoutHelper {

    /**
     * Takes in a route and the customizations object and returns a current screen object
     * @param {String} route The internal route url 
     * @param {*} customizations The full customizations object
     * 
     * @returns {Object} The current screen object to update the redux reducer.
     */
    GetCurrentScreen(route, customizations) {
        // Start with null
        let currentScreen = null;
        // Check if inputs are valid
        if (!_.isEmpty(route) && !_.isEmpty(customizations)) {
            if (route === ROUTES.HOME) {
                // Home Screen
                return {
                    title: "Home",
                    identifier: ScreenIdentifiers.HOME,
                    type: ScreenTypes.APP_PAGE_LAYOUT,
                    description: "The dashboard for the ProHelper program.",
                    icon: "home",
                    route: route,
                    object: null
                };
            } else if (route === ROUTES.SCHEDULE) {
                // Home Screen
                return {
                    title: "Schedule Overview",
                    identifier: ScreenIdentifiers.SCHEDULE,
                    type: ScreenTypes.APP_PAGE_LAYOUT,
                    description: "Scheduling and planning screen (App Page Layout).",
                    icon: "calendar",
                    route: route,
                    object: null
                };
            } else if (route === ROUTES.SETTINGS_USER) {
                // User Settings Screen
                return {
                    title: "User Settings",
                    identifier: ScreenIdentifiers.USER_SETTINGS,
                    type: ScreenTypes.SETTINGS,
                    description: "Update your personal information, preferences, security settings, and more.",
                    icon: "user",
                    route: route,
                    object: null
                };
            } else if (route.startsWith(ROUTES.SETTINGS)) {
                if (route === ROUTES.SETTINGS) {
                    // Main Settings Screen
                    return {
                        title: "Settings",
                        identifier: ScreenIdentifiers.SETTINGS,
                        type: ScreenTypes.SETTINGS,
                        description: "Update the company settings.",
                        icon: null,
                        route: route,
                        object: null
                    };
                } else if (route === ROUTES.SETTINGS_CUSTOMIZE_OBJECTS) {
                    return {
                        title: "Customize Objects",
                        identifier: ScreenIdentifiers.SETTINGS_CUSTOMIZE_OBJECTS,
                        type: ScreenTypes.SETTINGS,
                        description: "Customize the Objects used for your ProHelper platform.",
                        icon: null,
                        route: route,
                        object: null
                    };
                } else if (route === ROUTES.SETTINGS_CUSTOMIZATIONS_MANAGER) {
                    return {
                        title: "Customizations Manager",
                        identifier: ScreenIdentifiers.SETTINGS_CUSTOMIZATIONS_MANAGER,
                        type: ScreenTypes.SETTINGS,
                        description: "View and edit all customizations used for your ProHelper platform.",
                        icon: null,
                        route: route,
                        object: null
                    };
                } else if (route === ROUTES.SETTINGS_COMPANY) {
                    return {
                        title: "Company Information",
                        identifier: ScreenIdentifiers.SETTINGS_COMPANY_SETTINGS,
                        type: ScreenTypes.SETTINGS,
                        description: "Update the organization's information.",
                        icon: null,
                        route: route,
                        object: null
                    };
                } else if (route === ROUTES.SETTINGS_COMPANY_BRANDING) {
                    return {
                        title: "Company Branding",
                        identifier: ScreenIdentifiers.SETTINGS_COMPANY_BRANDING,
                        type: ScreenTypes.SETTINGS,
                        description: "Update the company branding.",
                        icon: null,
                        route: route,
                        object: null
                    };
                } else if (route === ROUTES.SETTINGS_COMPANY_FINANCIAL_INFORMATION) {
                    return {
                        title: "Company Financials",
                        identifier: ScreenIdentifiers.SETTINGS_COMPANY_FINANCIALS,
                        type: ScreenTypes.SETTINGS,
                        description: "Update the company financials.",
                        icon: null,
                        route: route,
                        object: null
                    };
                } else if (route === ROUTES.SETTINGS_PROHELPER_OVERVIEW) {
                    return {
                        title: "ProHelper Overview",
                        identifier: "prohelper_overview",
                        type: ScreenTypes.SETTINGS,
                        description: "View your organizations status for ProHelper.",
                        icon: null,
                        route: route,
                        object: null
                    };
                } else if (route === ROUTES.SETTINGS_PROHELPER_SUBSCRIPTION) {
                    return {
                        title: "ProHelper Subscription",
                        identifier: ScreenIdentifiers.SETTINGS_PROHELPER_SUBSCRIPTION,
                        type: ScreenTypes.SETTINGS,
                        description: "View your organizations ProHelper subscription.",
                        icon: null,
                        route: route,
                        object: null
                    };
                } else if (route === ROUTES.SETTINGS_PROHELPER_PLANS) {
                    return {
                        title: "ProHelper Plans",
                        identifier: ScreenIdentifiers.SETTINGS_PROHELPER_PLANS,
                        type: ScreenTypes.SETTINGS,
                        description: "View all the available ProHelper software plans.",
                        icon: null,
                        route: route,
                        object: null
                    };
                } else if (route === ROUTES.SETTINGS_PROHELPER_PAYMENTS) {
                    return {
                        title: "ProHelper Payments",
                        identifier: ScreenIdentifiers.SETTINGS_PROHELPER_PAYMENTS,
                        type: ScreenTypes.SETTINGS,
                        description: "View your organizations payments to ProHelper.",
                        icon: null,
                        route: route,
                        object: null
                    };
                } else if (route === ROUTES.SETTINGS_DATA_IMPORT) {
                    return {
                        title: "Data Import",
                        identifier: ScreenIdentifiers.SETTINGS_DATA_IMPORT,
                        type: ScreenTypes.SETTINGS,
                        description: "Import external data to ProHelper.",
                        icon: null,
                        route: route,
                        object: null
                    };
                } else if (route === ROUTES.SETTINGS_DATA_EXPORT) {
                    return {
                        title: "Data Export",
                        identifier: ScreenIdentifiers.SETTINGS_DATA_EXPORT,
                        type: ScreenTypes.SETTINGS,
                        description: "Export ProHelper data externally.",
                        icon: null,
                        route: route,
                        object: null
                    };
                } else if (route === ROUTES.SETTINGS_DATA_ARCHIVE) {
                    return {
                        title: "Archive",
                        identifier: ScreenIdentifiers.SETTINGS_DATA_ARCHIVE,
                        type: ScreenTypes.SETTINGS,
                        description: "The archive bin of recently deleted records.",
                        icon: null,
                        route: route,
                        object: null
                    };
                } else if (route === ROUTES.SETTINGS_DATA_MANAGE_INTEGRATIONS) {
                    return {
                        title: "Manage Integrations",
                        identifier: ScreenIdentifiers.SETTINGS_MANAGE_INTEGRATIONS,
                        type: ScreenTypes.SETTINGS,
                        description: "Manage all integrations that are connected to the organization ProHelper account.",
                        icon: null,
                        route: route,
                        object: null
                    };
                }
            } else if (route === ROUTES.CLIENTS) {
                // Clients Screen
                return {
                    title: "Clients Overview",
                    identifier: ScreenIdentifiers.CLIENTS_OVERVIEW,
                    type: ScreenTypes.APP_PAGE_LAYOUT,
                    description: "Clients overview screen (App Page Layout).",
                    icon: "handshake",
                    route: route,
                    object: null
                };
            } else if (route.startsWith(ROUTES.ACCOUNTS)) {
                if (route === ROUTES.ACCOUNTS) {
                    // Accounts Screen
                    currentScreen = {
                        title: "Accounts",
                        identifier: ScreenIdentifiers.ACCOUNTS_LIST_VIEW,
                        type: ScreenTypes.OBJECT_LIST_VIEW,
                        description: "View all Accounts within List Views.",
                        icon: customizations.objects.accounts ? customizations.objects.accounts.icon : 'sitemap',
                        route: route,
                        object: customizations.objects.accounts
                    };
                } else {
                    // Account Screen
                    currentScreen = {
                        title: "Account",
                        identifier: ScreenIdentifiers.ACCOUNT_PAGE_LAYOUT,
                        type: ScreenTypes.OBJECT_PAGE_LAYOUT,
                        description: "View an Account's information in detail.",
                        icon:  customizations.objects.accounts ? customizations.objects.accounts.icon : 'sitemap',
                        route: route,
                        object: customizations.objects.accounts
                    };
                }
            } else if (route.startsWith(ROUTES.CONTACTS)) {
                if (route === ROUTES.CONTACTS) {
                    // Contacts Screen
                    currentScreen = {
                        title: "Contacts",
                        identifier: ScreenIdentifiers.CONTACTS_LIST_VIEW,
                        type: ScreenTypes.OBJECT_LIST_VIEW,
                        description: "View all Contacts within List Views.",
                        icon:  customizations.objects.contacts ? customizations.objects.contacts.icon : 'address-book',
                        route: route,
                        object: customizations.objects.contacts
                    };
                } else {
                    // Contact Screen
                    currentScreen = {
                        title: "Contact",
                        identifier: ScreenIdentifiers.CONTACT_PAGE_LAYOUT,
                        type: ScreenTypes.OBJECT_PAGE_LAYOUT,
                        description: "View an Contact's information in detail.",
                        icon:  customizations.objects.contacts ? customizations.objects.contacts.icon : 'address-book',
                        route: route,
                        object: customizations.objects.contacts
                    };
                }
            } else if (route.startsWith(ROUTES.PROPERTIES)) {
                if (route === ROUTES.PROPERTIES) {
                    // Properties Screen
                    currentScreen = {
                        title: "Properties",
                        identifier: ScreenIdentifiers.PROPERTIES_LIST_VIEW,
                        type: ScreenTypes.OBJECT_LIST_VIEW,
                        description: "View all Properties within List Views.",
                        icon: customizations.objects.properties ? customizations.objects.properties.icon : 'house-building',
                        route: route,
                        object: customizations.objects.properties
                    };
                } else {
                    // Property Screen
                    currentScreen = {
                        title: "Property",
                        identifier: ScreenIdentifiers.PROPERTY_PAGE_LAYOUT,
                        type: ScreenTypes.OBJECT_PAGE_LAYOUT,
                        description: "View a Property's information in detail.",
                        icon: customizations.objects.properties ? customizations.objects.properties.icon : 'house-building',
                        route: route,
                        object: customizations.objects.properties
                    };
                }
            } else if (route === ROUTES.TEAM) {
                // Team Screen
                return {
                    title: "Team Overview",
                    identifier: ScreenIdentifiers.TEAM_OVERVIEW,
                    type: ScreenTypes.APP_PAGE_LAYOUT,
                    description: "Team overview screen (App Page Layout).",
                    icon: "people-roof",
                    route: route,
                    object: null
                };
            } else if (route.startsWith(ROUTES.USERS)) {
                if (route === ROUTES.USERS) {
                    // Users Screen
                    currentScreen = {
                        title: "Users",
                        identifier: ScreenIdentifiers.USERS_LIST_VIEW,
                        type: ScreenTypes.OBJECT_LIST_VIEW,
                        description: "View all Users within List Views.",
                        icon: customizations.objects.users ? customizations.objects.users.icon : 'user',
                        route: route,
                        object: customizations.objects.users
                    };
                } else {
                    // User Screen
                    currentScreen = {
                        title: "User",
                        identifier: ScreenIdentifiers.USER_PAGE_LAYOUT,
                        type: ScreenTypes.OBJECT_PAGE_LAYOUT,
                        description: "View a User's information in detail.",
                        icon: customizations.objects.users ? customizations.objects.users.icon : 'user',
                        route: route,
                        object: customizations.objects.users
                    };
                }
            } else if (route.startsWith(ROUTES.VEHICLES)) {
                if (route === ROUTES.VEHICLES) {
                    // Vehicles Screen
                    currentScreen = {
                        title: "Vehicles",
                        identifier: ScreenIdentifiers.VEHICLES_LIST_VIEW,
                        type: ScreenTypes.OBJECT_LIST_VIEW,
                        description: "View all Vehicles within List Views.",
                        icon: customizations.objects.vehicles ? customizations.objects.vehicles.icon : 'car',
                        route: route,
                        object: customizations.objects.vehicles
                    };
                } else {
                    // Vehicle Screen
                    currentScreen = {
                        title: "Vehicle",
                        identifier: ScreenIdentifiers.VEHICLE_PAGE_LAYOUT,
                        type: ScreenTypes.OBJECT_PAGE_LAYOUT,
                        description: "View a Vehicle's information in detail.",
                        icon: customizations.objects.vehicles ? customizations.objects.vehicles.icon : 'car',
                        route: route,
                        object: customizations.objects.vehicles
                    };
                }
            } else if (route === ROUTES.SECURITY) {
                // Security Screen
                return {
                    title: "Security Overview",
                    identifier: ScreenIdentifiers.SECURITY_OVERVIEW,
                    type: ScreenTypes.APP_PAGE_LAYOUT,
                    description: "Security overview screen (App Page Layout).",
                    icon: "shield",
                    route: route,
                    object: null
                };
            } else if (route.startsWith(ROUTES.ROLES)) {
                if (route === ROUTES.ROLES) {
                    // Roles Screen (Object List View)
                    currentScreen = {
                        title: "Roles",
                        identifier: ScreenIdentifiers.ROLES_LIST_VIEW,
                        type: ScreenTypes.OBJECT_LIST_VIEW,
                        description: "View all Roles within List Views.",
                        icon: customizations.objects.roles ? customizations.objects.roles.icon : 'id-card',
                        route: route,
                        object: customizations.objects.roles
                    };
                } else {
                    // Role Screen (Object Page Layout)
                    currentScreen = {
                        title: "Role",
                        identifier: ScreenIdentifiers.ROLE_PAGE_LAYOUT,
                        type: ScreenTypes.OBJECT_PAGE_LAYOUT,
                        description: "View a Role's information in detail.",
                        icon: customizations.objects.roles ? customizations.objects.roles.icon : 'id-card',
                        route: route,
                        object: customizations.objects.roles
                    };
                }
            } else if (route.startsWith(ROUTES.PERMISSION_SETS)) {
                if (route === ROUTES.PERMISSION_SETS) {
                    // Permission Sets Screen (Object List View)
                    currentScreen = {
                        title: "Permission Sets",
                        identifier: ScreenIdentifiers.PERMISSION_SETS_LIST_VIEW,
                        type: ScreenTypes.OBJECT_LIST_VIEW,
                        description: "View all Permission Sets within List Views.",
                        icon: customizations.objects.permission_sets ? customizations.objects.permission_sets.icon : 'user-lock',
                        route: route,
                        object: customizations.objects.permission_sets
                    };
                } else {
                    // Permission Set Screen (Object Page Layout)
                    currentScreen = {
                        title: "Permission Set",
                        identifier: ScreenIdentifiers.PERMISSION_SET_PAGE_LAYOUT,
                        type: ScreenTypes.OBJECT_PAGE_LAYOUT,
                        description: "View a Permission Set's information in detail.",
                        icon: customizations.objects.permission_sets ? customizations.objects.permission_sets.icon : 'user-lock',
                        route: route,
                        object: customizations.objects.permission_sets
                    };
                }
            } else if (route === ROUTES.WORK) {
                // Work Screen
                return {
                    title: "Work Overview",
                    identifier: ScreenIdentifiers.WORK_OVERVIEW,
                    type: ScreenTypes.APP_PAGE_LAYOUT,
                    description: "Work overview screen (App Page Layout).",
                    icon: "person-digging",
                    route: route,
                    object: null
                };
            } else if (route.startsWith(ROUTES.REQUESTS)) {
                if (route === ROUTES.REQUESTS) {
                    // Requests Screen (Object List View)
                    currentScreen = {
                        title: "Requests",
                        identifier: ScreenIdentifiers.REQUESTS_LIST_VIEW,
                        type: ScreenTypes.OBJECT_LIST_VIEW,
                        description: "View all Requests within List Views.",
                        icon: customizations.objects.requests ? customizations.objects.requests.icon : 'inbox',
                        route: route,
                        object: customizations.objects.requests
                    };
                } else {
                    // Request Screen (Object Page Layout)
                    currentScreen = {
                        title: "Request",
                        identifier: ScreenIdentifiers.REQUEST_PAGE_LAYOUT,
                        type: ScreenTypes.OBJECT_PAGE_LAYOUT,
                        description: "View a Request's information in detail.",
                        icon: customizations.objects.requests ? customizations.objects.requests.icon : 'inbox',
                        route: route,
                        object: customizations.objects.requests
                    };
                }
            } else if (route.startsWith(ROUTES.TASKS)) {
                if (route === ROUTES.TASKS) {
                    // Tasks Screen (Object List View)
                    currentScreen = {
                        title: "Tasks",
                        identifier: ScreenIdentifiers.TASKS_LIST_VIEW,
                        type: ScreenTypes.OBJECT_LIST_VIEW,
                        description: "View all Tasks within List Views.",
                        icon: customizations.objects.tasks ? customizations.objects.tasks.icon : 'list-check',
                        route: route,
                        object: customizations.objects.tasks
                    };
                } else {
                    // Task Screen (Object Page Layout)
                    currentScreen = {
                        title: "Task",
                        identifier: ScreenIdentifiers.TASK_PAGE_LAYOUT,
                        type: ScreenTypes.OBJECT_PAGE_LAYOUT,
                        description: "View a Task's information in detail.",
                        icon: customizations.objects.tasks ? customizations.objects.tasks.icon : 'list-check',
                        route: route,
                        object: customizations.objects.tasks
                    };
                }
            } else if (route.startsWith(ROUTES.JOBS)) {
                if (route === ROUTES.JOBS) {
                    // Jobs Screen (Object List View)
                    currentScreen = {
                        title: "Jobs",
                        identifier: ScreenIdentifiers.JOBS_LIST_VIEW,
                        type: ScreenTypes.OBJECT_LIST_VIEW,
                        description: "View all Jobs within List Views.",
                        icon: customizations.objects.jobs ? customizations.objects.jobs.icon : 'briefcase',
                        route: route,
                        object: customizations.objects.jobs
                    };
                } else {
                    // Job Screen (Object Page Layout)
                    currentScreen = {
                        title: "Job",
                        identifier: ScreenIdentifiers.JOB_PAGE_LAYOUT,
                        type: ScreenTypes.OBJECT_PAGE_LAYOUT,
                        description: "View a Job's information in detail.",
                        icon: customizations.objects.jobs ? customizations.objects.jobs.icon : 'briefcase',
                        route: route,
                        object: customizations.objects.jobs
                    };
                }
            } else if (route.startsWith(ROUTES.ROUTES)) {
                if (route === ROUTES.ROUTES) {
                    // Routes Screen (Object List View)
                    currentScreen = {
                        title: "Routes",
                        identifier: ScreenIdentifiers.ROUTES_LIST_VIEW,
                        type: ScreenTypes.OBJECT_LIST_VIEW,
                        description: "View all Routes within List Views.",
                        icon: customizations.objects.routes ? customizations.objects.routes.icon : 'route',
                        route: route,
                        object: customizations.objects.routes
                    };
                } else {
                    // Route Screen (Object Page Layout)
                    currentScreen = {
                        title: "Route",
                        identifier: ScreenIdentifiers.ROUTE_PAGE_LAYOUT,
                        type: ScreenTypes.OBJECT_PAGE_LAYOUT,
                        description: "View a Route's information in detail.",
                        icon: customizations.objects.routes ? customizations.objects.routes.icon : 'route',
                        route: route,
                        object: customizations.objects.routes
                    };
                }
            } else if (route === ROUTES.BILLING) {
                // Billing Screen
                return {
                    title: "Billing Overview",
                    identifier: ScreenIdentifiers.BILLING_OVERVIEW,
                    type: ScreenTypes.APP_PAGE_LAYOUT,
                    description: "Billing overview screen (App Page Layout).",
                    icon: "wallet",
                    route: route,
                    object: null
                };
            }  else if (route.startsWith(ROUTES.VISITS)) {
                if (route === ROUTES.VISITS) {
                    // Events Screen
                    currentScreen = {
                        title: "Visits",
                        identifier: ScreenIdentifiers.VISITS_LIST_VIEW,
                        type: ScreenTypes.OBJECT_LIST_VIEW,
                        description: "View all Visits within List Views.",
                        icon: customizations.objects.visits ? customizations.objects.visits.icon : 'person-dolly',
                        route: route,
                        object: customizations.objects.visits
                    };
                } else {
                    // Event Screen
                    currentScreen = {
                        title: "Visit",
                        identifier: ScreenIdentifiers.VISIT_PAGE_LAYOUT,
                        type: ScreenTypes.OBJECT_PAGE_LAYOUT,
                        description: "View a Visit's information in detail.",
                        icon: customizations.objects.visits ? customizations.objects.visits.icon : 'person-dolly',
                        route: route,
                        object: customizations.objects.visits
                    };
                }
            } else if (route.startsWith(ROUTES.ESTIMATES)) {
                if (route === ROUTES.ESTIMATES) {
                    // Estimates Screen
                    currentScreen = {
                        title: "Estimates",
                        identifier: ScreenIdentifiers.ESTIMATES_LIST_VIEW,
                        type: ScreenTypes.OBJECT_LIST_VIEW,
                        description: "View all Estimates within List Views.",
                        icon: customizations.objects.estimates ? customizations.objects.estimates.icon : 'envelope-open-dollar',
                        route: route,
                        object: customizations.objects.estimates
                    };
                } else {
                    // Estimate Screen
                    currentScreen = {
                        title: "Estimate",
                        identifier: ScreenIdentifiers.ESTIMATE_PAGE_LAYOUT,
                        type: ScreenTypes.OBJECT_PAGE_LAYOUT,
                        description: "View a Estimate's information in detail.",
                        icon: customizations.objects.estimates ? customizations.objects.estimates.icon : 'envelope-open-dollar',
                        route: route,
                        object: customizations.objects.estimates
                    };
                }
            } else if (route.startsWith(ROUTES.INVOICES)) {
                if (route === ROUTES.INVOICES) {
                    // Invoices Screen
                    currentScreen = {
                        title: "Invoices",
                        identifier: ScreenIdentifiers.INVOICES_LIST_VIEW,
                        type: ScreenTypes.OBJECT_LIST_VIEW,
                        description: "View all Invoices within List Views.",
                        icon: customizations.objects.invoices ? customizations.objects.invoices.icon : 'file-invoice-dollar',
                        route: route,
                        object: customizations.objects.invoices
                    };
                } else {
                    // Invoice Screen
                    currentScreen = {
                        title: "Invoice",
                        identifier: ScreenIdentifiers.ESTIMATE_PAGE_LAYOUT,
                        type: ScreenTypes.OBJECT_PAGE_LAYOUT,
                        description: "View a Invoice's information in detail.",
                        icon: customizations.objects.invoices ? customizations.objects.invoices.icon : 'file-invoice-dollar',
                        route: route,
                        object: customizations.objects.invoices
                    };
                }
            } else if (route.startsWith(ROUTES.SUBSCRIPTIONS)) {
                if (route === ROUTES.SUBSCRIPTIONS) {
                    // Subscriptions Screen
                    currentScreen = {
                        title: "Subscriptions",
                        identifier: ScreenIdentifiers.SUBSCRIPTIONS_LIST_VIEW,
                        type: ScreenTypes.OBJECT_LIST_VIEW,
                        description: "View all Subscriptions within List Views.",
                        icon: customizations.objects.subscriptions ? customizations.objects.subscriptions.icon : 'money-bill-transfer',
                        route: route,
                        object: customizations.objects.subscriptions
                    };
                } else {
                    // Invoice Screen
                    currentScreen = {
                        title: "Invoice",
                        identifier: ScreenIdentifiers.ESTIMATE_PAGE_LAYOUT,
                        type: ScreenTypes.OBJECT_PAGE_LAYOUT,
                        description: "View a Invoice's information in detail.",
                        icon: customizations.objects.subscriptions ? customizations.objects.subscriptions.icon : 'money-bill-transfer',
                        route: route,
                        object: customizations.objects.subscriptions
                    };
                }
            } else if (route.startsWith(ROUTES.PAYMENTS)) {
                if (route === ROUTES.PAYMENTS) {
                    // Payments Screen
                    currentScreen = {
                        title: "Payments",
                        identifier: ScreenIdentifiers.PAYMENTS_LIST_VIEW,
                        type: ScreenTypes.OBJECT_LIST_VIEW,
                        description: "View all Payments within List Views.",
                        icon: customizations.objects.payments ? customizations.objects.payments.icon : 'money-check-dollar',
                        route: route,
                        object: customizations.objects.payments
                    };
                } else {
                    // Payment Screen
                    currentScreen = {
                        title: "Payment",
                        identifier: ScreenIdentifiers.PAYMENT_PAGE_LAYOUT,
                        type: ScreenTypes.OBJECT_PAGE_LAYOUT,
                        description: "View a Payment's information in detail.",
                        icon: customizations.objects.payments ? customizations.objects.payments.icon : 'money-check-dollar',
                        route: route,
                        object: customizations.objects.payments
                    };
                }
            } else if (route.startsWith(ROUTES.ITEMS)) {
                if (route === ROUTES.ITEMS) {
                    // Items Screen
                    currentScreen = {
                        title: "Items",
                        identifier: ScreenIdentifiers.ITEMS_LIST_VIEW,
                        type: ScreenTypes.OBJECT_LIST_VIEW,
                        description: "View all Items within List Views.",
                        icon: customizations.objects.items ? customizations.objects.items.icon : 'store',
                        route: route,
                        object: customizations.objects.items
                    };
                } else {
                    // Item Screen
                    currentScreen = {
                        title: "Item",
                        identifier: ScreenIdentifiers.ITEM_PAGE_LAYOUT,
                        type: ScreenTypes.OBJECT_PAGE_LAYOUT,
                        description: "View a Item's information in detail.",
                        icon: customizations.objects.items ? customizations.objects.items.icon : 'store',
                        route: route,
                        object: customizations.objects.items
                    };
                }
            } else if (route.startsWith(ROUTES.PURCHASE_ORDERS)) {
                if (route === ROUTES.PURCHASE_ORDERS) {
                    // Purchase Orders Screen
                    currentScreen = {
                        title: "Purchase Orders",
                        identifier: ScreenIdentifiers.PURCHASE_ORDERS_LIST_VIEW,
                        type: ScreenTypes.OBJECT_LIST_VIEW,
                        description: "View all Purchase Orders within List Views.",
                        icon: customizations.objects.purchase_orders ? customizations.objects.purchase_orders.icon : 'receipt',
                        route: route,
                        object: customizations.objects.purchase_orders
                    };
                } else {
                    // Purchase Order Screen
                    currentScreen = {
                        title: "Purchase Order",
                        identifier: ScreenIdentifiers.PURCHASE_ORDER_PAGE_LAYOUT,
                        type: ScreenTypes.OBJECT_PAGE_LAYOUT,
                        description: "View a Purchase Order's information in detail.",
                        icon: customizations.objects.purchase_orders ? customizations.objects.purchase_orders.icon : 'receipt',
                        route: route,
                        object: customizations.objects.purchase_orders
                    };
                }
            } else if (route === ROUTES.AUDIT) {
                // Audit Screen
                return {
                    title: "Audit Overview",
                    identifier: ScreenIdentifiers.AUDIT_OVERVIEW,
                    type: ScreenTypes.APP_PAGE_LAYOUT,
                    description: "Audit overview screen (App Page Layout).",
                    icon: "chart-pie-simple-circle-dollar",
                    route: route,
                    object: null
                };
            } else if (route.startsWith(ROUTES.LEDGERS)) {
                if (route === ROUTES.LEDGERS) {
                    // Ledgers Screen
                    currentScreen = {
                        title: "Ledgers",
                        identifier: ScreenIdentifiers.LEDGERS_LIST_VIEW,
                        type: ScreenTypes.OBJECT_LIST_VIEW,
                        description: "View all Ledgers within List Views.",
                        icon: customizations.objects.ledgers ? customizations.objects.ledgers.icon : 'memo-pad',
                        route: route,
                        object: customizations.objects.ledgers
                    };
                } else {
                    // Ledger Screen
                    currentScreen = {
                        title: "Ledger",
                        identifier: ScreenIdentifiers.LEDGER_PAGE_LAYOUT,
                        type: ScreenTypes.OBJECT_PAGE_LAYOUT,
                        description: "View a Ledger's information in detail.",
                        icon: customizations.objects.ledgers ? customizations.objects.ledgers.icon : 'memo-pad',
                        route: route,
                        object: customizations.objects.ledgers
                    };
                }
            } else if (route.startsWith(ROUTES.REPORTS)) {
                if (route === ROUTES.REPORTS) {
                    // Reports Screen
                    currentScreen = {
                        title: "Reports",
                        identifier: ScreenIdentifiers.REPORTS_LIST_VIEW,
                        type: ScreenTypes.OBJECT_LIST_VIEW,
                        description: "View all Reports within List Views.",
                        icon: customizations.objects.reports ? customizations.objects.reports.icon : 'chart-pie',
                        route: route,
                        object: customizations.objects.reports
                    };
                } else {
                    // Report Screen
                    currentScreen = {
                        title: "Report",
                        identifier: ScreenIdentifiers.REPORT_PAGE_LAYOUT,
                        type: ScreenTypes.OBJECT_PAGE_LAYOUT,
                        description: "View a Report's information in detail.",
                        icon: customizations.objects.reports ? customizations.objects.reports.icon : 'chart-pie',
                        route: route,
                        object: customizations.objects.reports
                    };
                }
            } else {
                console.log('Screen Layout Header not handled yet:', route);
            }
        }

        return currentScreen;

    }
    
}
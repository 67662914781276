


// All Table Identifier Data

// Special Screens (used for more option button and menu area, and possibly other places)
export const HOME = 'home';

// Schedule
export const EVENTS = 'events';
export const VISITS = 'visits';


// Work Area
export const TASKS = 'tasks';
export const REQUESTS = 'requests';
export const JOBS = 'jobs';
export const ROUTES = 'routes';
export const ROUTE_STOPS = 'route_stops';

// Clients Area
export const ACCOUNTS = 'accounts';
export const CONTACTS = 'contacts';
export const PROPERTIES = 'properties';

// Billing Area
export const INVOICES = 'invoices';
export const SUBSCRIPTIONS = 'subscriptions';
export const ESTIMATES = 'estimates';
export const PAYMENTS = 'payments';
export const ITEMS = 'items';
export const PURCHASE_ORDERS = 'purchase_orders';

// Accounting Area
export const LEDGERS = 'ledgers';
export const REPORTS = 'reports';

// Team Area
export const USERS = 'users';
export const VEHICLES = 'vehicles';

// Security Area
export const ROLES = 'roles';
export const PERMISSION_SETS = 'permission_sets';

// Other 

// Not an actual table, but still used for "global" items not related to any 1 table.
export const GLOBAL = 'global';
export const STATIC = 'static';


// Other Area
export const NOTES = 'notes';
export const ATTACHMENTS = 'attachments';
export const TIMELINE_LOGS = 'timeline_logs';
export const LINE_ITEMS = 'line_items';

// This constants file is a representation of how data for object fields will be recieved by the ProHelper database once linked.
import uniqid from 'uniqid';
import * as FieldTypes from '../../types/FieldTypes';
import * as DatabaseTypes from '../../types/DatabaseTypes';

const PurchaseOrderDropdownSetsData = [
    // Purchase Orders - Statuses
    {
        id: null,
        company: null,
        public_id: uniqid('dropset_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Purchase Order Statuses",
        identifier: 'purchase_orders.statuses',
        dropdown_plural_label: 'Statuses',
        dropdown_singular_label: "Status",
        description: "A set of all the different statuses for an purchase order.",
        field_type: FieldTypes.TEXT_DROPDOWN,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_locked: false,
        is_live: true,
        is_editable: true,
        is_editable_inline: false,
        is_sortable: true,
        is_filterable: true,
        is_custom: false,
        is_colored: false,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        linked_fields: [],
        on_dropdown_delete: 'restrict',
        on_dropdown_set_delete: 'restrict',
        dropdowns: [
            // Draft
            {
                id: null,
                company: null,
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "purchase_orders",
                sync_token: 1,
                label: "Draft",
                identifier: "draft",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "draft"
                },
                color: null,
                sort_order: 1,
                is_active: true,
                is_archived: false,
                is_deleted: false,
                is_locked: true,
                is_default: true,
                is_custom: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Pending Approval
            {
                id: null,
                company: null,
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "purchase_orders",
                sync_token: 1,
                label: "Pending Approval",
                identifier: "pending_approval",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "pending_approval"
                },
                color: null,
                sort_order: 2,
                is_active: true,
                is_archived: false,
                is_deleted: false,
                is_locked: true,
                is_default: true,
                is_custom: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Approved
            {
                id: null,
                company: null,
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "purchase_orders",
                sync_token: 1,
                label: "Approved",
                identifier: "approved",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "approved"
                },
                color: null,
                sort_order: 3,
                is_active: true,
                is_archived: false,
                is_deleted: false,
                is_locked: true,
                is_default: true,
                is_custom: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Sent to Vendor
            {
                id: null,
                company: null,
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "purchase_orders",
                sync_token: 1,
                label: "Sent to Vendor",
                identifier: "sent_to_vendor",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "sent_to_vendor"
                },
                color: null,
                sort_order: 4,
                is_active: true,
                is_archived: false,
                is_deleted: false,
                is_locked: true,
                is_default: true,
                is_custom: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Acknowledged
            {
                id: null,
                company: null,
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "purchase_orders",
                sync_token: 1,
                label: "Acknowledged",
                identifier: "acknowledged",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "acknowledged"
                },
                color: null,
                sort_order: 5,
                is_active: true,
                is_archived: false,
                is_deleted: false,
                is_locked: true,
                is_default: true,
                is_custom: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Partially Received 
            {
                id: null,
                company: null,
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "purchase_orders",
                sync_token: 1,
                label: "Partially Received",
                identifier: "partially_received",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "partially_received"
                },
                color: null,
                sort_order: 6,
                is_active: true,
                is_archived: false,
                is_deleted: false,
                is_locked: true,
                is_default: true,
                is_custom: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Fully Received 
            {
                id: null,
                company: null,
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "purchase_orders",
                sync_token: 1,
                label: "Fully Received",
                identifier: "fully_received",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "fully_received"
                },
                color: null,
                sort_order: 7,
                is_active: true,
                is_archived: false,
                is_deleted: false,
                is_locked: true,
                is_default: true,
                is_custom: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Completed
            {
                id: null,
                company: null,
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "purchase_orders",
                sync_token: 1,
                label: "Completed",
                identifier: "completed",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "completed"
                },
                color: null,
                sort_order: 8,
                is_active: true,
                is_archived: false,
                is_deleted: false,
                is_locked: true,
                is_default: true,
                is_custom: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Cancelled
            {
                id: null,
                company: null,
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "purchase_orders",
                sync_token: 1,
                label: "Cancelled",
                identifier: "cancelled",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "cancelled"
                },
                color: null,
                sort_order: 9,
                is_active: true,
                is_archived: false,
                is_deleted: false,
                is_locked: true,
                is_default: true,
                is_custom: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // On Hold
            {
                id: null,
                company: null,
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "purchase_orders",
                sync_token: 1,
                label: "On Hold",
                identifier: "on_hold",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "on_hold"
                },
                color: null,
                sort_order: 10,
                is_active: true,
                is_archived: false,
                is_deleted: false,
                is_locked: true,
                is_default: true,
                is_custom: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Rejected
            {
                id: null,
                company: null,
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "purchase_orders",
                sync_token: 1,
                label: "Rejected",
                identifier: "rejected",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "rejected"
                },
                color: null,
                sort_order: 11,
                is_active: true,
                is_archived: false,
                is_deleted: false,
                is_locked: true,
                is_default: true,
                is_custom: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
        ]
    },
    // Purchase Orders - Tags
    {
        id: null,
        company: null,
        public_id: uniqid('dropset_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Purchase Order Tags",
        identifier: 'purchase_orders.tags',
        description: "A set of all the different tags for an purchase order.",
        dropdown_plural_label: 'Tags',
        dropdown_singular_label: "Tag",
        field_type: FieldTypes.TEXT_DROPDOWN,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_locked: false,
        is_live: true,
        is_editable: true,
        is_editable_inline: true,
        is_sortable: false,
        is_filterable: true,
        is_custom: false,
        is_colored: false,
        linked_fields: [],
        on_dropdown_delete: 'restrict',
        on_dropdown_set_delete: 'restrict',
        dropdowns: [
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "purchase_orders",
                sync_token: 1,
                label: "New",
                identifier: "new",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "new"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "purchase_orders",
                sync_token: 1,
                label: "Special",
                identifier: "special",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "special"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "purchase_orders",
                sync_token: 1,
                label: "Temporary",
                identifier: "temporary",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "temporary"
                },
                color: null,
                sort_order: null,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            }
        ]
    },
    // Purchase Orders - Billing Reasons
    {
        id: null,
        company: null,
        public_id: uniqid('dropset_'),
        object: null,
        object_identifier: 'purchase_orders',
        sync_token: 1,
        label: "Billing Reasons",
        identifier: 'purchase_orders.billing_reasons',
        description: "A set of all the different reasons for billing an Purchase Order from a vendor.",
        dropdown_plural_label: 'Billing Reasons',
        dropdown_singular_label: "Billing Reason",
        field_type: FieldTypes.CUSTOM_DROPDOWN,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_locked: false,
        is_live: true,
        is_editable: true,
        is_editable_inline: true,
        is_sortable: true,
        is_filterable: true,
        is_custom: false,
        is_colored: false,
        linked_fields: [],
        on_dropdown_delete: 'restrict',
        on_dropdown_set_delete: 'restrict',
        dropdowns: [
            // Subscription
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "purchase_orders",
                sync_token: 1,
                label: "Subscription",
                identifier: "subscription",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "subscription"
                },
                color: null,
                sort_order: 1,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Manual
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "purchase_orders",
                sync_token: 1,
                label: "Manual",
                identifier: "manual",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "manual"
                },
                color: null,
                sort_order: 2,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Outstanding Balance
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "purchase_orders",
                sync_token: 1,
                label: "Outstanding Balance",
                identifier: "outstanding_balance",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "outstanding_balance"
                },
                color: null,
                sort_order: 3,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
            // Deposit
            {
                public_id: uniqid('dropdown_'),
                object: null,
                object_identifier: "purchase_orders",
                sync_token: 1,
                label: "Deposit",
                identifier: "deposit",
                value: {
                    data_type: DatabaseTypes.TEXT,
                    data: "deposit"
                },
                color: null,
                sort_order: 4,
                is_active: true,
                is_locked: false,
                is_default: false,
                is_custom: false,
                is_archived: false,
                is_deleted: false,
                is_live: true,
                created_by: null,
                created_at: new Date(),
                last_updated_by: null,
                last_updated_at: new Date(),
                use_history: {
                    used_by: {},
                    used_at: {},
                },
                metadata: {},
                dropdown_settings: {}
            },
        ]
    }
    
]

export default PurchaseOrderDropdownSetsData;
// This constants file is a representation of how data for account fields will be recieved by the ProHelper database once linked.
import uniqid from 'uniqid';

const AccountFieldsData = [
    // ID
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Database ID",
        identifier: "id",
        field_type: "id",
        data_type: "bigint",
        special_type: null,
        description: "The database id of this Account.",
        help_text: "The ID used for the ProHelper database.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: true,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        sort_field: null,
        sort_join: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Company
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Company",
        identifier: "company",
        field_type: "lookup_relationship",
        data_type: "bigint",
        special_type: null,
        description: "The parent company for this Account.",
        help_text: "The parent company for an account.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: true,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Name
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Name",
        identifier: "name",
        field_type: "name",
        data_type: "jsonb",
        special_type: "main_label",
        description: "The complete name for an Account. Can be a person's full name, company name, or a manual name.",
        help_text: "The name for this account.",
        default_value: null,
        placeholder_text: "Enter a name...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 1,
        is_required: true,
        is_editable: true,
        is_searchable: true,
        search_priority: 'A',
        is_filterable: true,
        filter_order: 12,
        is_sortable: true,
        sort_order: 1,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Basic Information",
            identifier: "basic_information",
            order: 1
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Description
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Description",
        identifier: "description",
        field_type: "long_text",
        data_type: "text",
        special_type: null,
        description: "Information about the Account by the organization.",
        help_text: "Information or a summary of an account.",
        default_value: null,
        placeholder_text: "Enter a description...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 2,
        is_required: false,
        is_editable: true,
        is_searchable: true,
        search_priority: 'B',
        is_filterable: false,
        filter_order: null,
        is_sortable: true,
        sort_order: 2,
        sort_method: "alphanumeric",
        sort_field: null,
        sort_join: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Basic Information",
            identifier: "basic_information",
            order: 1
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Balance
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Balance",
        identifier: "balance",
        field_type: "balance",
        data_type: "bigint",
        special_type: null,
        description: "Represents this Account's current balance owed to the company. Stored as the smallest unit of the Account's currency type.",
        help_text: "The currency amount an Account owes to your company.",
        default_value: { data_type: "bigint", data: "0" },
        placeholder_text: "Enter a balance adjustment...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 3,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 4,
        is_sortable: true,
        sort_order: 3,
        sort_method: "numeric",
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: true,
        group: {
            label: "Basic Information",
            identifier: "basic_information",
            order: 1
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Primary Contact
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Primary Contact",
        identifier: "primary_contact",
        field_type: "lookup_relationship",
        data_type: "bigint",
        special_type: null,
        description: "References the primary person/contact for this Account; the main person to communicate with related to the Account.",
        help_text: "The primary person to communicate with related to this Account.",
        default_value: null,
        placeholder_text: "Enter a Contact..",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 4,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 2,
        is_sortable: true,
        sort_order: 4,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Basic Information",
            identifier: "basic_information",
            order: 1
        },
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "contacts",
            filters: [
                {
                    fields: ["contacts.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            label: "True",
                            type: "value",
                            data: true,
                            data_type: 'boolean',
                            field_type: "checkbox",
                            input: true
                        }
                    ]
                },
                {
                    fields: ["contacts.account", "accounts.id"],
                    field_types: ["lookup_relationship", "id"],
                    operator: "equal",
                    values: [
                        {
                            type: "relationship",
                            label: "Parent Account",
                            input: 'parent.id',
                            data: null,
                            data_type: 'relationship',
                            status: 'valid'
                        }
                    ]
                }
            ],
            display_field: "name",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Primary Property
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Primary Property",
        identifier: "primary_property",
        field_type: "lookup_relationship",
        data_type: "bigint",
        special_type: null,
        description: "References the primary property/address for this Account; the main place of residence or business associated with the Account.",
        help_text: "The primary property/place associated with this Account.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 5,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 3,
        is_sortable: true,
        sort_order: 5,
        sort_method: null,
        sort_field: null,
        sort_join: null,
        is_multi: false,
        is_async: true,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Basic Information",
            identifier: "basic_information",
            order: 1
        },
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "properties",
            filters: [
                {
                    fields: ["properties.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            type: "value",
                            label: 'True',
                            input: true,
                            data: true,
                            data_type: 'boolean',
                            status: 'valid'
                        }
                    ]
                },
                {
                    fields: ["properties.account", "accounts.id"],
                    field_types: ["lookup_relationship", "id"],
                    operator: "equal",
                    values: [
                        {
                            type: "relationship",
                            label: 'Parent Account',
                            input: 'parent.id',
                            data: null,
                            data_type: 'relationship',
                            status: 'valid'
                        }
                    ]
                }
            ],
            display_field: "address",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 

        },
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Type
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Type",
        identifier: "type",
        field_type: "type_dropdown",
        data_type: "bigint",
        special_type: null,
        description: "Represents the object type of this Account.",
        help_text: "A custom type to categorize an Account.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 6,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 1,
        is_sortable: true,
        sort_order: 6,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Basic Information",
            identifier: "basic_information",
            order: 1
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Status
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Status",
        identifier: "status",
        field_type: "custom_dropdown",
        data_type: "bigint",
        special_type: null,
        description: "Represents the current status of this account.",
        help_text: "A custom status, or stage, for an account in it's lifecycle.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 7,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 5,
        is_sortable: true,
        sort_order: 7,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Basic Information",
            identifier: "basic_information",
            order: 1
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: {
            identifier: "accounts.statuses",
            object_identifier: "accounts",
            type: "custom_dropdown"
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Priority
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Priority",
        identifier: "priority",
        field_type: "custom_dropdown",
        data_type: "bigint",
        special_type: null,
        description: "Represents the priority, or urgency, of this account.",
        help_text: "A ranking of importantance/urgency of an account.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 8,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 7,
        is_sortable: true,
        sort_order: 8,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Basic Information",
            identifier: "basic_information",
            order: 1
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: {
            identifier: "global.priorities",
            object_identifier: "global",
            type: "custom_dropdown"
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Source
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Source",
        identifier: "source",
        field_type: "custom_dropdown",
        data_type: "bigint",
        special_type: null,
        description: "Represents where the account first originated from.",
        help_text: "The source, or origin, of an account.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 9,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 7,
        is_sortable: true,
        sort_order: 9,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Basic Information",
            identifier: "basic_information",
            order: 1
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: {
            identifier: "global.sources",
            object_identifier: "global",
            type: "custom_dropdown"
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Rating
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Rating",
        identifier: "rating",
        field_type: "custom_dropdown",
        data_type: "bigint",
        special_type: null,
        description: "Represents a subjective rating, or value, for the Account.",
        help_text: "Your company's subjective rating of this Account.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 10,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 7,
        is_sortable: true,
        sort_order: 10,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Basic Information",
            identifier: "basic_information",
            order: 1
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: {
            identifier: "global.ratings",
            object_identifier: "global",
            type: "custom_dropdown"
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Zone
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Zone",
        identifier: "zone",
        field_type: "custom_dropdown",
        data_type: "bigint",
        special_type: null,
        description: "Reference to the zone, or area, the property is in. Useful for creating custom geographic areas in your local area.",
        help_text: "A custom geographical area to identify where an account is located in.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 11,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 9,
        is_sortable: true,
        sort_order: 11,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Basic Information",
            identifier: "basic_information",
            order: 1
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: {
            identifier: "global.zones",
            object_identifier: "global",
            type: "custom_dropdown"
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Tags
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Tags",
        identifier: "tags",
        field_type: "text_dropdown",
        data_type: "text[]",
        special_type: null,
        description: "A list of account tags linked to this account. Is a custom multi dropdown for account tags.",
        help_text: "Identifier labels for extra information about an account.",
        default_value: null,
        placeholder_text: "Enter some tags for this account",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 12,
        is_required: false,
        is_editable: true,
        is_searchable:  true,
        search_priority: 'A',
        is_filterable: true,
        filter_order: 10,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        sort_field: null,
        sort_join: null,
        is_multi: true,
        is_async: true,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Basic Information",
            identifier: "basic_information",
            order: 1
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: {
            identifier: "accounts.tags",
            object_identifier: "accounts",
            type: "text_dropdown"
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Owner
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Owner",
        identifier: "owner",
        field_type: "lookup_relationship",
        data_type: "bigint",
        special_type: null,
        description: "References the user responsible for maintaing the information for this account. Defaults to the user who created the account.",
        help_text: "The user responsible for maintaining the information of an account.",
        default_value: null,
        placeholder_text: "Enter a user responsible...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 13,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 11,
        is_sortable: true,
        sort_order: 12,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Basic Information",
            identifier: "basic_information",
            order: 1
        },
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "users",
            filters: [
                {
                    fields: ["users.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            type: "value",
                            label: 'True',
                            input: true,
                            data: true,
                            data_type: 'boolean',
                            status: 'valid'
                        }
                    ]
                },
                {
                    fields: ["users.is_employee"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            type: "value",
                            label: 'True',
                            input: true,
                            data: true,
                            data_type: 'boolean',
                            status: 'valid'
                        }
                    ]
                }
            ],
            display_field: "name",
            strict_filter_lookups: false,
            is_related_record_editable: false,
            is_related_record_creatable: false 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Account ID
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Account ID",
        identifier: "account_id",
        field_type: "public_id",
        data_type: "text",
        special_type: "public_id",
        description: "The unique ID for an Account shown to publically to users. Account Prefix is 'acct_'.",
        help_text: "The unique ID for an Account shown to publically to users.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 14,
        is_required: true,
        is_editable: false,
        is_searchable: true,
        search_priority: 'A',
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        sort_field: null,
        sort_join: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Basic Information",
            identifier: "basic_information",
            order: 1
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Phone Number
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Primary Phone",
        identifier: "phone",
        field_type: "phone",
        data_type: "jsonb",
        special_type: null,
        description: "The primary phone number for the account. If no other number is entered, the primary phone number will be used for all purposes. (contacting, billing, shipping, etc)",
        help_text: "The main phone number used for an account.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 15,
        is_required: false,
        is_editable: true,
        is_searchable: true,
        search_priority: 'A',
        is_filterable: true,
        filter_order: null,
        is_sortable: true,
        sort_order: 13,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Contact Information",
            identifier: "contact_information",
            order: 2
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Email Address
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Primary Email",
        identifier: "email",
        field_type: "email",
        data_type: "jsonb",
        special_type: null,
        description: "The primary email address for the account. If no other email is entered, the primary email address will be used for all purposes. (contacting, billing, shipping, etc)",
        help_text: "The main email address used for an account.",
        default_value: null,
        placeholder_text: "Enter an email address...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 16,
        is_required: false,
        is_editable: true,
        is_searchable: true,
        search_priority: 'A',
        is_filterable: false,
        filter_order: null,
        is_sortable: true,
        sort_order: 14,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Contact Information",
            identifier: "contact_information",
            order: 2
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Address
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Primary Address",
        identifier: "address",
        field_type: "address",
        data_type: "jsonb",
        special_type: null,
        description: "The primary address is the main location associated with this account. If no other addresses are provided, this address will be used for all purposes. (work, billing, shipping)",
        help_text: "The main address used for an account.",
        default_value: null,
        placeholder_text: "Enter a primary address...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 17,
        is_required: false,
        is_editable: true,
        is_searchable: true,
        search_priority: 'A',
        is_filterable: true,
        filter_order: 12,
        is_sortable: true,
        sort_order: 15,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Contact Information",
            identifier: "contact_information",
            order: 2
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Do Not Call
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Do Not Call",
        identifier: "do_not_call",
        field_type: "checkbox",
        data_type: "boolean",
        special_type: null,
        description: "If true, no team member should call the listed phone numbers for this account.",
        help_text: "Whether an account wants to be contacted by phone.",
        default_value: { data_type: "boolean", data: false },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 18,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 13,
        is_sortable: true,
        sort_order: 16,
        sort_method: "boolean",
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Contact Information",
            identifier: "contact_information",
            order: 2
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: { true: "Do Not Call", false: "Callable" },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Do Not Email
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Do Not Email",
        identifier: "do_not_email",
        field_type: "checkbox",
        data_type: "boolean",
        special_type: null,
        description: "If true, no team member or system process should email the listed emails for this account.",
        help_text: "Whether an account wants to receive email.",
        default_value: { data_type: "boolean", data: false },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 19,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 13,
        is_sortable: true,
        sort_order: 16,
        sort_method: "boolean",
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Contact Information",
            identifier: "contact_information",
            order: 2
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: { true: "Do Not Email", false: "Emailable" },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Do Not Mail
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Do Not Mail",
        identifier: "do_not_mail",
        field_type: "checkbox",
        data_type: "boolean",
        special_type: null,
        description: "If true, no team member should mail to the listed addresses for this account.",
        help_text: "Whether an account wants to receive mail.",
        default_value: { data_type: "boolean", data: false },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 20,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 15,
        is_sortable: true,
        sort_order: 18,
        sort_method: "boolean",
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Contact Information",
            identifier: "contact_information",
            order: 2
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: { true: "Do Not Mail", false: "Mailable" },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Website
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Website",
        identifier: "website",
        field_type: "url",
        data_type: "text",
        special_type: null,
        description: "A website url link associated with this account.",
        help_text: "A linked website for an account.",
        default_value: null,
        placeholder_text: "Enter a Website...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 21,
        is_required: false,
        is_editable: true,
        is_searchable: true,
        search_priority: 'B',
        is_filterable: false,
        filter_order: null,
        is_sortable: true,
        sort_order: 19,
        sort_method: "alphanumeric",
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Contact Information",
            identifier: "contact_information",
            order: 2
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Secondary Email Address
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Secondary Email Address",
        identifier: "secondary_email",
        field_type: "email",
        data_type: "jsonb",
        special_type: null,
        description: "A secondary email for the account. Useful for information purposes and searching.",
        help_text: "A secondary email address...",
        default_value: null,
        placeholder_text: "Enter a second email address...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 22,
        is_required: false,
        is_editable: true,
        is_searchable: true,
        search_priority: 'B',
        is_filterable: false,
        filter_order: null,
        is_sortable: true,
        sort_order: 20,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Contact Information",
            identifier: "contact_information",
            order: 2
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Secondary Phone Number
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Secondary Phone Number",
        identifier: "secondary_phone",
        field_type: "phone",
        data_type: "jsonb",
        special_type: null,
        description: "A secondary phone number for the account for information purposes and searching.",
        help_text: "A second phone number...",
        default_value: null,
        placeholder_text: "Enter a second phone number for this account.",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 23,
        is_required: false,
        is_editable: true,
        is_searchable: true,
        search_priority: 'B',
        is_filterable: true,
        filter_order: null,
        is_sortable: true,
        sort_order: 21,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Contact Information",
            identifier: "contact_information",
            order: 2
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Other Email Address
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Other Email Address",
        identifier: "other_email",
        field_type: "email",
        data_type: "jsonb",
        special_type: null,
        description: "An extra email for the account for information purposes and searching.",
        help_text: "An extra email address connected to an account.",
        default_value: null,
        placeholder_text: "Enter an extra email address...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 24,
        is_required: false,
        is_editable: true,
        is_searchable: true,
        search_priority: 'C',
        is_filterable: false,
        filter_order: null,
        is_sortable: true,
        sort_order: 22,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Contact Information",
            identifier: "contact_information",
            order: 2
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Other Phone Number
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Other Phone Number",
        identifier: "other_phone",
        field_type: "phone",
        data_type: "jsonb",
        special_type: null,
        description: "An extra phone number for the account for information purposes and searching.",
        help_text: "An extra phone number connected to an account.",
        default_value: null,
        placeholder_text: "Enter an extra phone number...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 25,
        is_required: false,
        is_editable: true,
        is_searchable: true,
        search_priority: 'C',
        is_filterable: false,
        filter_order: null,
        is_sortable: true,
        sort_order: 23,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Contact Information",
            identifier: "contact_information",
            order: 2
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Billing Contact
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Billing Contact",
        identifier: "billing_contact",
        field_type: "lookup_relationship",
        data_type: "bigint",
        special_type: null,
        description: "The billing contact or \"person\" to contact about this account's billing.",
        help_text: "The person to contact with any billing related items or issues.",
        default_value: null,
        placeholder_text: "Select a billing contact...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 26,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 16,
        is_sortable: true,
        sort_order: 24,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Billing Information",
            identifier: "billing_information",
            order: 3
        },
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "contacts",
            display_field: "name",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true,
            filters: [
                {
                    fields: ["contacts.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            type: "value",
                            label: 'True',
                            input: true,
                            data: true,
                            data_type: 'boolean',
                            status: 'valid'
                        }
                    ]
                },
                {
                    fields: ["contacts.account", "accounts.id"],
                    field_types: ["lookup_relationship", "id"],
                    operator: "equal",
                    values: [
                        {
                            type: "relationship",
                            label: 'Parent Account',
                            input: 'parent.id',
                            data: null,
                            data_type: 'relationship',
                            status: 'valid'
                        }
                    ]
                }
            ],
        },
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Billing Name
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Billing Name",
        identifier: "billing_name",
        field_type: "name",
        data_type: "jsonb",
        special_type: null,
        description: "The billing name for this account to be used when estimate, invoices, or other billing items need to be sent to this account.",
        help_text: "The name of the person or company handling billing for an account.",
        default_value: null,
        placeholder_text: "Enter the name of the person to bill to...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 27,
        is_required: false,
        is_editable: true,
        is_searchable: true,
        search_priority: 'B',
        is_filterable: false,
        filter_order: null,
        is_sortable: true,
        sort_order: 25,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Billing Information",
            identifier: "billing_information",
            order: 3
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Billing Phone Number
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Billing Phone Number",
        identifier: "billing_phone",
        field_type: "phone",
        data_type: "jsonb",
        special_type: null,
        description: "The billing phone number for this account to be used when discussing estimate, invoice, or other billing questions with this account.",
        help_text: "The phone number to send all billing related calls for an account.",
        default_value: null,
        placeholder_text: "Enter a phone number for billing related calls...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 28,
        is_required: false,
        is_editable: true,
        is_searchable: true,
        search_priority: 'B',
        is_filterable: false,
        filter_order: null,
        is_sortable: true,
        sort_order: 26,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Billing Information",
            identifier: "billing_information",
            order: 3
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Billing Email Address
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Billing Email Address",
        identifier: "billing_email",
        field_type: "email",
        data_type: "text",
        special_type: null,
        description: "The billing email for this account to be used when estimate, invoices, or other billing items need to be sent to this account.",
        help_text: "The email address to send all billing related emails for an account.",
        default_value: null,
        placeholder_text: "Enter an email for billing related emails...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 29,
        is_required: false,
        is_editable: true,
        is_searchable: true,
        search_priority: 'B',
        is_filterable: false,
        filter_order: null,
        is_sortable: true,
        sort_order: 27,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Billing Information",
            identifier: "billing_information",
            order: 3
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Billing Property
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Billing Property",
        identifier: "billing_property",
        field_type: "lookup_relationship",
        data_type: "bigint",
        special_type: null,
        description: "References the billing property for this account. This is the billing property or \"address\" for this account to use for billing items (estimates, invoices, payments, others)..",
        help_text: "The linked billing property for an account.",
        default_value: null,
        placeholder_text: "Select a property for the main billing address...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 30,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 17,
        is_sortable: true,
        sort_order: 28,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Billing Information",
            identifier: "billing_information",
            order: 3
        },
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "properties",
            filters: [
                {
                    fields: ["properties.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            type: "value",
                            label: 'True',
                            input: true,
                            data: true,
                            data_type: 'boolean',
                            status: 'valid'
                        }
                    ]
                },
                {
                    fields: ["properties.account", "accounts.id"],
                    field_types: ["lookup_relationship", "id"],
                    operator: "equal",
                    values: [
                        {
                            type: "relationship",
                            label: 'Parent Account',
                            input: 'parent.id',
                            data: null,
                            data_type: 'relationship',
                            status: 'valid'
                        }
                    ]
                }
            ],
            display_field: "address",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 

        },
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Billing Address
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Billing Address",
        identifier: "billing_address",
        field_type: "address",
        data_type: "jsonb",
        special_type: null,
        description: "The address for this account to send billing related mail. Used for estimates, invoices, payments, and other billing items.",
        help_text: "The full billing address of an account.",
        default_value: null,
        placeholder_text: "Enter an address for billing...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 31,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: 'B',
        is_filterable: true,
        filter_order: 18,
        is_sortable: true,
        sort_order: 29,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Billing Information",
            identifier: "billing_information",
            order: 3
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Balance with Sub-Accounts
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Balance with Sub-Accounts",
        identifier: "balance_with_sub_accounts",
        field_type: "currency",
        data_type: "bigint",
        special_type: null,
        description: "This field is the total sum of the balance of this account and it's sub-account's balances. Stored as the smallest unit of the account's currency type.",
        help_text: "The current amount an account owes to your company with all its sub-accounts included.",
        default_value: { data_type: "bigint", data: "0" },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 32,
        is_required: true,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 19,
        is_sortable: true,
        sort_order: 30,
        sort_method: "numeric",
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Billing Information",
            identifier: "billing_information",
            order: 3
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,


        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Default Payment Method
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Default Payment Method",
        identifier: "default_payment_method",
        field_type: "lookup_relationship",
        data_type: "bigint",
        special_type: null,
        description: "The payment method shows how to receive a payment from the customer.",
        help_text: "How you receive payments from an account.",
        default_value: null,
        placeholder_text: "Select a payment method type...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 33,
        is_required: false,
        is_editable: true,
        is_searchable: true,
        search_priority: null,
        is_filterable: true,
        filter_order: 21,
        is_sortable: true,
        sort_order: 32,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Billing Information",
            identifier: "billing_information",
            order: 3
        },
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "payment_methods",
            filters: [
                {
                    fields: ["payment_methods.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            type: "value",
                            label: 'True',
                            input: true,
                            data: true,
                            data_type: 'boolean',
                            status: 'valid'
                        }
                    ]
                },
                {
                    field: ["payment_methods.account", "accounts.id"],
                    field_types: ["lookup_relationship", "id"],
                    operator: "equal",
                    values: [
                        {
                            type: "relationship",
                            label: 'Parent Account',
                            input: 'parent.id',
                            data: null,
                            data_type: 'relationship',
                            status: 'valid'
                        }
                    ]
                }
            ],
            display_field: "name",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 

        },
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Payment Status
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Payment Status",
        identifier: "payment_status",
        field_type: "text_dropdown",
        data_type: "text",
        special_type: null,
        description: "The payment status represents this account's current payment status for any active invoices or billable items.",
        help_text: "The current payment status of any billing items for an account.",
        default_value: { data_type: 'text', data: 'none' },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 34,
        is_required: false,
        is_editable: true,
        is_searchable: true,
        search_priority: null,
        is_filterable: true,
        filter_order: 21,
        is_sortable: true,
        sort_order: 32,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Billing Information",
            identifier: "billing_information",
            order: 3
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: {
            identifier: "global.payment_statuses",
            object_identifier: "global",
            type: "text_dropdown"
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Is Taxable
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Is Taxable",
        identifier: "is_taxable",
        field_type: "checkbox",
        data_type: "boolean",
        special_type: null,
        description: "If true, this account will be charged local sales tax for future billing items.",
        help_text: "Represents if an account is taxable or not for billing related items.",
        default_value: { data_type: 'boolean', data: true },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 35,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 22,
        is_sortable: true,
        sort_order: 33,
        sort_method: "boolean",
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Billing Information",
            identifier: "billing_information",
            order: 3
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: { true: "Taxable", false: "Not Taxable" },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Tax ID
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Tax ID",
        identifier: "tax_id",
        field_type: "encrypted_string",
        data_type: "text",
        special_type: null,
        description: "A Tax Identification Number to be used in tax reports. Encyrpted in the ProHelper Database.",
        help_text: "The Tax ID for an account. Encrypted until access requested.",
        default_value: null,
        placeholder_text: "Enter a tax id... (AES-256 encrypted)",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 36,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: true,
        is_sensitive: true,
        group: {
            label: "Billing Information",
            identifier: "billing_information",
            order: 3
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Delivery Method
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Delivery Method",
        identifier: "delivery_method",
        field_type: "text_dropdown",
        data_type: "text",
        special_type: null,
        description: "The delivery method represents the preferred way to send billing and other company information to this account.",
        help_text: "The current payment status of any billing items for an account.",
        default_value: null,
        placeholder_text: "Select a delivery method...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 37,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 23,
        is_sortable: true,
        sort_order: 34,
        sort_method: null,
        sort_field: null,
        sort_join: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Shipping Information",
            identifier: "shipping_information",
            order: 4
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: {
            identifier: "global.delivery_methods",
            object_identifier: "global",
            type: "text_dropdown"
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Communication Method
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Communication Method",
        identifier: "communication_method",
        field_type: "text_dropdown",
        data_type: "text",
        special_type: null,
        description: "The preferred communication method shows which method to get information to and from the customer.",
        help_text: "What channel the account prefers to communicate on.",
        default_value: null,
        placeholder_text: "Select a communication method...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 38,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 24,
        is_sortable: true,
        sort_order: 35,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Shipping Information",
            identifier: "shipping_information",
            order: 4
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: {
            identifier: "global.communication_methods",
            object_identifier: "global",
            type: "text_dropdown"
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Shipping Contact
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Shipping Contact",
        identifier: "shipping_contact",
        field_type: "lookup_relationship",
        data_type: "bigint",
        special_type: null,
        description: "The shipping contact or \"person\" to contact about this account's shipping.",
        help_text: "The person to contact with any shipping related items or issues.",
        default_value: null,
        placeholder_text: "Select a shipping contact...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 39,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 25,
        is_sortable: true,
        sort_order: 36,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Shipping Information",
            identifier: "shipping_information",
            order: 4
        },
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "contacts",
            filters: [
                {
                    fields: ["contacts.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            type: "value",
                            label: 'True',
                            input: true,
                            data: true,
                            data_type: 'boolean',
                            status: 'valid'
                        }
                    ]
                },
                {
                    fields: ["contacts.account", "accounts.id"],
                    field_types: ["lookup_relationship", "id"],
                    operator: "equal",
                    values: [
                        {
                            type: "relationship",
                            label: 'Parent Account',
                            input: 'parent.id',
                            data: null,
                            data_type: 'relationship',
                            status: 'valid'
                        }
                    ]
                }
            ],
            display_field: "display_name",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 

        },
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Shipping Name
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Shipping Name",
        identifier: "shipping_name",
        field_type: "name",
        data_type: "jsonb",
        special_type: null,
        description: "The main shipping name for this account.",
        help_text: "The name of the main shipping person or company for an account.",
        default_value: null,
        placeholder_text: "Enter a name for the person or company to ship to...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 40,
        is_required: false,
        is_editable: true,
        is_searchable: true,
        search_priority: 'C',
        is_filterable: false,
        filter_order: null,
        is_sortable: true,
        sort_order: 37,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Shipping Information",
            identifier: "shipping_information",
            order: 4
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Shipping Phone Number
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Shipping Phone Number",
        identifier: "shipping_phone",
        field_type: "phone",
        data_type: "text",
        special_type: null,
        description: "The shipping phone number for this account to be used when discussing shipping or mailing questions with this account.",
        help_text: "The phone number to send all shipping related calls for an account.",
        default_value: null,
        placeholder_text: "Enter a phone number for shipping related calls...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 41,
        is_required: false,
        is_editable: true,
        is_searchable: true,
        search_priority: 'C',
        is_filterable: false,
        filter_order: null,
        is_sortable: true,
        sort_order: 38,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Shipping Information",
            identifier: "shipping_information",
            order: 4
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,


        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Shipping Property
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Shipping Property",
        identifier: "shipping_property",
        field_type: "lookup_relationship",
        data_type: "bigint",
        special_type: null,
        description: "The linked shipping property for this account.",
        help_text: "The linked shipping property for an account.",
        default_value: null,
        placeholder_text: "Select a property for the main shipping address...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 42,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 26,
        is_sortable: true,
        sort_order: 39,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Shipping Information",
            identifier: "shipping_information",
            order: 5
        },
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "properties",
            filters: [
                {
                    fields: ["properties.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            type: "value",
                            label: 'True',
                            input: true,
                            data: true,
                            data_type: 'boolean',
                            status: 'valid'
                        }
                    ]
                },
                {
                    fields: ["properties.account", "accounts.id"],
                    field_types: ["lookup_relationship", "id"],
                    operator: "equal",
                    values: [
                        {
                            type: "relationship",
                            label: 'Parent Account',
                            input: 'parent.id',
                            data: null,
                            data_type: 'relationship',
                            status: 'valid'
                        }
                    ]
                }
            ],
            display_field: "address",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 

        },
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Shipping Address
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Shipping Address",
        identifier: "shipping_address",
        field_type: "address",
        data_type: "jsonb",
        special_type: null,
        description: "The main shipping address for this account.",
        help_text: "The complete shipping address of an account.",
        default_value: null,
        placeholder_text: "Enter an address for shipping...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 43,
        is_required: false,
        is_editable: true,
        is_searchable: true,
        search_priority: 'C',
        is_filterable: true,
        filter_order: 27,
        is_sortable: true,
        sort_order: 40,
        sort_method: null,
        sort_field: null,
        sort_join: null,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Shipping Information",
            identifier: "shipping_information",
            order: 4
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Is Sub Account
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Is Sub Account",
        identifier: "is_sub_account",
        field_type: "checkbox",
        data_type: "boolean",
        special_type: null,
        description: "If true, this account is a sub account and as a parent account associated with it. If true, the parent account should be filled in.",
        help_text: "Represents whether an account is a child account to another account in the system.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 44,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 28,
        is_sortable: true,
        sort_order: 41,
        sort_method: "boolean",
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 5
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: { true: "Sub Account", false: "Not Sub Account" },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Parent Account
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Parent Account",
        identifier: "parent",
        field_type: "lookup_relationship",
        data_type: "bigint",
        special_type: null,
        description: "If this Account is a sub-Account, then this represents a link to the parent Account.",
        help_text: "The parent Account of this sub-Account.",
        default_value: null,
        placeholder_text: "Select a parent Account...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 45,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 29,
        is_sortable: true,
        sort_order: 42,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 5
        },
        lookup_relationship_options: {
            type: "parent",
            object_identifier: "accounts",
            filters: [
                {
                    fields: ["accounts.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            type: "value",
                            label: 'True',
                            input: true,
                            data: true,
                            data_type: 'boolean',
                            status: 'valid'
                        }
                    ]
                }
            ],
            display_field: "name",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Level
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Level",
        identifier: "level",
        field_type: "integer",
        data_type: "integer",
        special_type: null,
        description: "Represents the depth in the overall account structure. (Parent Account, Sub Accounts) (Max Level: 3)",
        help_text: "The depth of the account in the overall parent-child account structure.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 46,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 5
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Time Zone
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Time Zone",
        identifier: "time_zone",
        field_type: "text_dropdown",
        data_type: "text",
        special_type: null,
        description: "The Time Zone that the Account is based in.",
        help_text: "What time zone this account is based in.",
        default_value: { data_type: 'text', data: 'America/Los_Angeles' },
        placeholder_text: "Select a time zone...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 47,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 30,
        is_sortable: true,
        sort_order: 43,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 5
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: {
            identifier: "static.time_zones",
            object_identifier: "static",
            type: "text_dropdown"
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Currency
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Currency",
        identifier: "currency",
        field_type: "text_dropdown",
        data_type: "text",
        special_type: null,
        description: "The currency of this account.",
        help_text: "What currency this account is based in.",
        default_value: { data_type: 'text', data: 'usd' },
        placeholder_text: "Select a currency...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 48,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 31,
        is_sortable: true,
        sort_order: 44,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 5
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: {
            identifier: "static.currencies",
            object_identifier: "static",
            type: "text_dropdown"
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Language
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Language",
        identifier: "language",
        field_type: "text_dropdown",
        data_type: "text",
        special_type: null,
        description: "The language this account speaks.",
        help_text: "What language this account speaks.",
        default_value: { data_type: 'text', data: 'en' },
        placeholder_text: "Select a language...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 49,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: 32,
        is_sortable: false,
        sort_order: 45,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 5
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: {
            identifier: "static.languages",
            object_identifier: "static",
            type: "text_dropdown"
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Account Start Date
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Start Date",
        identifier: "start_date",
        field_type: "date",
        data_type: "timestamptz",
        special_type: null,
        description: "Represents this account's real life start date. This field can be different than the created date which is system generated.",
        help_text: "The start date of an account with your company.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 50,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 33,
        is_sortable: true,
        sort_order: 46,
        sort_method: "chronological",
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 6
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Account End Date
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "End Date",
        identifier: "end_date",
        field_type: "date",
        data_type: "timestamptz",
        special_type: null,
        description: "Represents this account's real life end date. This field can be different than the system generated end date.",
        help_text: "The end date of an account with your company..",

        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 51,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 34,
        is_sortable: true,
        sort_order: 47,
        sort_method: "chronological",
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 6
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // On Hold Start Date
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "On Hold Start Date",
        identifier: "on_hold_start_date",
        field_type: "date_time",
        data_type: "timestamptz",
        special_type: null,
        description: "Represents some optional date to resume \"is_active\" automatically at.",
        help_text: "The date an account starts being on hold.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 53,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 35,
        is_sortable: true,
        sort_order: 48,
        sort_method: "chronological",
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Account Information",
            identifier: "account_information",
            order: 6
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // On Hold By
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "On Hold By",
        identifier: "on_hold_by",
        field_type: "lookup_relationship",
        data_type: "bigint",
        special_type: null,
        description: "Represents the user who put this account on a hold.",
        help_text: "The user who put an account on hold.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 53,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 36,
        is_sortable: true,
        sort_order: 49,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Account Information",
            identifier: "account_information",
            order: 6
        },
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "users",
            filters: [
                {
                    fields: ["accounts.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            type: "value",
                            label: 'True',
                            input: true,
                            data: true,
                            data_type: 'boolean',
                            status: 'valid'
                        }
                    ]
                }
            ],
            display_field: "name",
            strict_filter_lookups: false,
            is_related_record_editable: false,
            is_related_record_creatable: false 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Is Active
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Is Active",
        identifier: "is_active",
        field_type: "checkbox",
        data_type: "boolean",
        special_type: null,
        description: "Represents if the account is currently engaged with your company in some aspect (vendor relationship, customer relationship).",
        help_text: "An account that is being actively engaged with your company.",
        default_value: { data_type: "boolean", data: true },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 54,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 37,
        is_sortable: true,
        sort_order: 50,
        sort_method: "boolean",
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Account Information",
            identifier: "account_information",
            order: 6
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: { true: "Active", false: "Not Active" },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Is Person
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Is Person",
        identifier: "is_person",
        field_type: "checkbox",
        data_type: "boolean",
        special_type: null,
        description: "If true, the account is a person account and uses the full name and personal information whenever possible.",
        help_text: "Represents if this is a Person Account if checked.",
        default_value: { data_type: "boolean", data: true },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 55,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 38,
        is_sortable: true,
        sort_order: 51,
        sort_method: "boolean",
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Account Information",
            identifier: "account_information",
            order: 6
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: { true: "Person", false: "Company" },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Is On Hold
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Is On Hold",
        identifier: "is_on_hold",
        field_type: "checkbox",
        data_type: "boolean",
        special_type: null,
        description: "If true, this account should temporarly become in-active and there should be no more jobs, route stops, or billing items sent or done to this customer.",
        help_text: "If checked, an account is on hold and no further activity should be performed until the hold is removed or the on hold end date passes.",
        default_value: { data_type: "boolean", data: false },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 56,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 39,
        is_sortable: true,
        sort_order: 52,
        sort_method: "boolean",
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Account Information",
            identifier: "account_information",
            order: 6
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: { true: "On Hold", false: "Not On Hold" },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Is Archived
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Is Archived",
        identifier: "is_archived",
        field_type: "checkbox",
        data_type: "boolean",
        special_type: null,
        description: "Represents if an account is archived, hidden but not deleted. Users can still access, view, and restore archived accounts.",
        help_text: "Represents if an account is archived, hidden but not deleted.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: { true: "Archived", false: "Not Archived" },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Is Deleted
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Is Deleted",
        identifier: "is_deleted",
        field_type: "checkbox",
        data_type: "boolean",
        special_type: null,
        description: "Represents if a user has deleted this account. Cannot view or edit the account if deleted is true. Kept in our database connections to not break connected links.",
        help_text: "Represents if an account is deleted, removed from the system.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: { true: "Deleted", false: "Not Deleted" },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Is Live
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Is Live",
        identifier: "is_live",
        field_type: "checkbox",
        data_type: "boolean",
        special_type: null,
        description: "Represents if the account is live for regular use if true, or testing only.",
        help_text: "Represents if an account is live data for regular use, or for testing only.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        sort_field: null,
        sort_join: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: { true: "Live Data", false: "Test Data" },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Is Draft
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Is Draft",
        identifier: "is_draft",
        field_type: "checkbox",
        data_type: "boolean",
        special_type: null,
        description: "Represents if the account is a draft, saved before publishing publically.",
        help_text: "Represents if the account is a draft, saved before publishing publically.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        sort_field: null,
        sort_join: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: { true: "Draft", false: "Public" },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Is Template
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Is Template",
        identifier: "is_template",
        field_type: "checkbox",
        data_type: "boolean",
        special_type: null,
        description: "Represents if the account is a template. Templates save an account's data to be pre-filled in for similar accounts later.",
        help_text: "Save an account's data as a template to be pre-filled in for similar accounts later.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        sort_field: null,
        sort_join: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: { true: "Template", false: "Normal" },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Action Alert
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Action Alert",
        identifier: "action_alert",
        field_type: "alert",
        data_type: "jsonb",
        special_type: null,
        description: "Shows an important action related alert message as a banner or dialog when viewing the Account record in detail.",
        help_text: "An alert message detailing some action that needs to be performed on this Account.",
        default_value: null,
        placeholder_text: "Enter an action alert message...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 57,
        is_required: false,
        is_editable: true,
        is_searchable: true,
        search_priority: 'A',
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        sort_field: null,
        sort_join: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Account Popups",
            identifier: "account_popups",
            order: 7
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Popup Alert
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Popup Alert",
        identifier: "popup_alert",
        field_type: "alert",
        data_type: "jsonb",
        special_type: null,
        description: "Shows an important message as a banner or dialog when viewing the Account record in detail.",
        help_text: "An important alert message shown when someone views this Account.",
        default_value: null,
        placeholder_text: "Enter a popup alert message...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 58,
        is_required: false,
        is_editable: true,
        is_searchable: true,
        search_priority: 'A',
        is_filterable: true,
        filter_order: 41,
        is_sortable: true,
        sort_order: 54,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Account Popups",
            identifier: "account_popups",
            order: 7
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // On Hold Alert
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "On Hold Message",
        identifier: "on_hold_message",
        field_type: "alert",
        data_type: "text",
        special_type: null,
        description: "Shows an alert message why this Account is on hold. Shown as a banner or dialog when viewing the Account record in detail.",
        help_text: "An alert message describing why this Account is on hold.",
        default_value: null,
        placeholder_text: "Enter the On-Hold reason...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 59,
        is_required: false,
        is_editable: true,
        is_searchable: true,
        search_priority: 'C',
        is_filterable: true,
        filter_order: 42,
        is_sortable: true,
        sort_order: 55,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Account Popups",
            identifier: "account_popups",
            order: 7
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Created At
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Created At",
        identifier: "created_at",
        field_type: "date_time",
        data_type: "timestamptz",
        special_type: null,
        description: "Represents the timestamp the Account was created. System generated timestamp.",
        help_text: "The timestamp when this Account was created.",
        default_value: { data_type: 'timestamptz', data: new Date() },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 60,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 43,
        is_sortable: true,
        sort_order: 55,
        sort_method: "chronological",
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Record Information",
            identifier: "record_information",
            order: 8
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Created By
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Created By",
        identifier: "created_by",
        field_type: "lookup_relationship",
        data_type: "bigint",
        special_type: null,
        description: "Represents the user who created the Account. System generated.",
        help_text: "The user who created this Account.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 61,
        is_required: true,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 44,
        is_sortable: true,
        sort_order: 57,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Record Information",
            identifier: "record_information",
            order: 8
        },
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "users",
            filters: [
                {
                    fields: ["users.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            type: "value",
                            label: 'True',
                            input: true,
                            data: true,
                            data_type: 'boolean',
                            status: 'valid'
                        }
                    ]
                },
            ],
            display_field: "name",
            strict_filter_lookups: false,
            is_related_record_editable: false,
            is_related_record_creatable: false 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Last Updated At
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Last Updated At",
        identifier: "last_updated_at",
        field_type: "date_time",
        data_type: "timestamptz",
        special_type: null,
        description: "Represents the timestamp the user last updated this account. System generated timestamp.",
        help_text: "The timestamp when an account was last updated.",
        default_value: { data_type: 'timestamptz', data: new Date() },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 62,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 45,
        is_sortable: true,
        sort_order: 58,
        sort_method: "chronological",
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Record Information",
            identifier: "record_information",
            order: 8
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Last Updated By
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Last Updated By",
        identifier: "last_updated_by",
        field_type: "lookup_relationship",
        data_type: "bigint",
        special_type: null,
        description: "Represents the user who last updated this account. System generated.",
        help_text: "The user who last updated an account.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 63,
        is_required: true,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: null,
        is_sortable: true,
        sort_order: 59,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Record Information",
            identifier: "record_information",
            order: 8
        },
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "users",
            filters: [
                {
                    fields: ["users.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            type: "value",
                            label: 'True',
                            input: true,
                            data: true,
                            data_type: 'boolean',
                            status: 'valid'
                        }
                    ]
                },
            ],
            display_field: "name",
            strict_filter_lookups: false,
            is_related_record_editable: false,
            is_related_record_creatable: false 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Last Activity At
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Last Activity At",
        identifier: "last_activity_at",
        field_type: "date_time",
        data_type: "timestamptz",
        special_type: null,
        description: "Represent the last timestamp when a related item to an account was created, updated, or deleted.",
        help_text: "The timestamp when the last activtiy was on an account.",
        default_value: { data_type: 'timestamptz', data: new Date() },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 64,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 46,
        is_sortable: true,
        sort_order: 60,
        sort_method: "chronological",
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Record Information",
            identifier: "record_information",
            order: 8
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Last Activity By
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Last Activity By",
        identifier: "last_activity_by",
        field_type: "lookup_relationship",
        data_type: "bigint",
        special_type: null,
        description: "Represent the last user id who created, updated, or deleted a related item to an account. ",
        help_text: "The user who last made some activity an account.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 65,
        is_required: true,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: true,
        sort_order: 61,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Record Information",
            identifier: "record_information",
            order: 8
        },
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "users",
            filters: [
                {
                    fields: ["users.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            type: "value",
                            label: 'True',
                            input: true,
                            data: true,
                            data_type: 'boolean',
                            status: 'valid'
                        }
                    ]
                },
            ],
            display_field: "name",
            strict_filter_lookups: false,
            is_related_record_editable: false,
            is_related_record_creatable: false 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Last Viewed At
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Last Viewed At",
        identifier: "last_viewed_at",
        field_type: "date_time",
        data_type: "timestamptz",
        special_type: null,
        description: "Represents the last timestamp when a user last viewed this account's detail page.",
        help_text: "The timestamp when the last user viewed an account.",
        default_value: { data_type: 'timestamptz', data: new Date() },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 66,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 47,
        is_sortable: true,
        sort_order: 62,
        sort_method: "chronological",
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Record Information",
            identifier: "record_information",
            order: 8
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Last Viewed By
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Last Viewed By",
        identifier: "last_viewed_by",
        field_type: "lookup_relationship",
        data_type: "bigint",
        special_type: null,
        description: "Represents the last timestamp when a user last viewed this account's detail page.",
        help_text: "The timestamp when the last user viewed an account.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 67,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: true,
        sort_order: 63,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Record Information",
            identifier: "record_information",
            order: 8
        },
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "users",
            filters: [
                {
                    fields: ["users.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            type: "value",
                            label: 'True',
                            input: true,
                            data: true,
                            data_type: 'boolean',
                            status: 'valid'
                        }
                    ]
                },
            ],
            display_field: "name",
            strict_filter_lookups: false,
            is_related_record_editable: false,
            is_related_record_creatable: false 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Archived Alert
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Archived Alert",
        identifier: "archived_alert",
        field_type: "alert",
        data_type: "jsonb",
        special_type: null,
        description: "Shows an alert message why this Account is archived. Shown as a banner or dialog when viewing the Account record in detail in the Trash.",
        help_text: null,
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Archived At
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Archived At",
        identifier: "archived_at",
        field_type: "date_time",
        data_type: "timestamptz",
        special_type: null,
        description: "Represents the timestamp the user archived this account. System generated timestamp.",
        help_text: "The timestamp when an account was archived.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Archived By
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Archived By",
        identifier: "archived_by",
        field_type: "lookup_relationship",
        data_type: "bigint",
        special_type: null,
        description: "Represents the user who archived this account. System generated.",
        help_text: "The user who archived an account.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "users",
            filters: [
                {
                    fields: ["users.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            type: "value",
                            label: 'True',
                            input: true,
                            data: true,
                            data_type: 'boolean',
                            status: 'valid'
                        }
                    ]
                },
            ],
            display_field: "name",
            strict_filter_lookups: false,
            is_related_record_editable: false,
            is_related_record_creatable: false 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    
    // Suggested Item Action
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Suggested Item Action",
        identifier: "suggested_action",
        field_type: "lookup_relationship",
        data_type: "bigint",
        special_type: null,
        description: "The first shown item action on the ListView screen and around the program. Can be system generated.",
        help_text: "",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "actions",
            filters: [
                {
                    fields: ["actions.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            type: "value",
                            label: 'True',
                            input: true,
                            data: true,
                            data_type: 'boolean',
                            status: 'valid'
                        }
                    ]
                },
                {
                    fields: ["actions.object_identifier"],
                    field_types: ["id"],
                    operator: "equal",
                    values: [
                        {
                            type: "value",
                            label: 'Accounts',
                            input: "accounts",
                            data: "accounts",
                            data_type: 'text',
                            status: 'valid'
                        }
                    ]
                }
            ],
            display_field: "label",
            strict_filter_lookups: false,
            is_related_record_editable: false,
            is_related_record_creatable: false 
        },
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Sync Token
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Sync Token",
        identifier: "sync_token",
        field_type: "integer",
        data_type: "integer",
        special_type: null,
        description: "Represents the current sync token of this object. If a user tries an operation on this account with an invalid sync token, they operation will fail. (i.e. Offline-Conflicting Issue)",
        help_text: "",
        default_value: { data_type: 'integer', data: 1 },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Account Metadata
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Account Metadata",
        identifier: "metadata",
        field_type: "settings",
        data_type: "jsonb",
        special_type: null,
        description: "A JSON Object that contains extra information about the specific account. (Non structured)",
        help_text: "",
        default_value: null,
        placeholder_text: "Enter some extra information for this specific account...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Account Notifications
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Account Notifcations",
        identifier: "notifications",
        field_type: "json",
        data_type: "jsonb",
        special_type: null,
        description: "Notification toggles for the Account.",
        help_text: "",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Settings
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Settings",
        identifier: "settings",
        field_type: "json",
        data_type: "jsonb",
        special_type: null,
        description: "Setting toggles for the Account",
        help_text: "",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Custom Fields
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'accounts',
        sync_token: 1,
        label: "Custom Fields",
        identifier: "custom_fields",
        field_type: "json",
        data_type: "jsonb",
        special_type: null,
        description: "A JSON storing custom fields and values that match the custom account object fields. (Structured nested object fields)",
        help_text: "",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    }
];

export default AccountFieldsData;
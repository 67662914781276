// This constants file is a representation of how data for request fields will be recieved by the ProHelper database once linked.
import uniqid from 'uniqid';
import * as FieldTypes from '../../types/FieldTypes';
import * as DatabaseTypes from '../../types/DatabaseTypes';
import * as SpecialTypes from '../../types/SpecialTypes';
import * as FilterOperatorTypeIdenifiers from '../../filtering/operators/FilterOperatorTypeIdentifiers';
import * as LookupRelationshipTypes from '../../types/LookupRelationshipTypes';
import * as SortingMethods from '../../sorting/SortingMethods';

const RequestFieldsData = [
    // ID
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Database ID",
        identifier: "id",
        field_type: FieldTypes.ID,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "The ID of this Request in the ProHelper Database.",
        help_text: "The ID of this Request in the ProHelper Database.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: true,
        is_editable: false,
        is_searchable: false,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Company
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Company",
        identifier: "company",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "The organization in ProHelper that this Request is apart of.",
        help_text: "The organization for this Request in ProHelper.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: true,
        is_editable: false,
        is_searchable: false,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Subject
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Subject",
        identifier: "subject",
        field_type: FieldTypes.LONG_TEXT,
        data_type: DatabaseTypes.TEXT,
        special_type: SpecialTypes.MAIN_LABEL,
        description: "The primary subject or label for a Request.",
        help_text: "The primary subject or label of a Request.",
        default_value: null,
        placeholder_text: 'Enter a subject for this Request...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 1,
        is_required: true,
        is_editable: true,
        is_searchable: true,
        is_filterable: false,
        filter_order: null,
        is_sortable: true,
        sort_order: 1,
        sort_method: SortingMethods.ALPHANUMERIC,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: 'A',
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Description
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Description",
        identifier: "description",
        field_type: FieldTypes.LONG_TEXT,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        description: "A description for the Request.",
        help_text: "Information about this Request.",
        default_value: null,
        placeholder_text: 'Enter some notes...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 2,
        is_required: false,
        is_editable: true,
        is_searchable: true,
        search_priority: 'B',
        is_filterable: false,
        filter_order: null,
        is_sortable: true,
        sort_order: 2,
        sort_method: SortingMethods.ALPHANUMERIC,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Type
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Type",
        identifier: "type",
        field_type: FieldTypes.TYPE_DROPDOWN,
        data_type: DatabaseTypes.BIGINT,
        special_type: SpecialTypes.MAIN_TYPE,
        description: "Represents the object type of this Request.",
        help_text: "A custom type to categorize an Request.",
        default_value: null,
        placeholder_text: 'Enter a type for this Request...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 3,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 3,
        is_sortable: true,
        sort_order: 3,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Status
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Status",
        identifier: "status",
        field_type: FieldTypes.CUSTOM_DROPDOWN,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Represents the current stage of the Request in its lifecycle.",
        help_text: "The stage for this Request in it's lifecycle.",
        default_value: null,
        placeholder_text: 'Enter the current status for this Request...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 4,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 4,
        is_sortable: true,
        sort_order: 4,
        sort_method: SortingMethods.CUSTOM_SORT_ORDER,
        is_multi: false,
        is_async: true,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: {
            identifier: "requests.statuses",
            object_identifier: "requests",
            type: "custom_dropdown"
        },
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Account
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Account",
        identifier: "account",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Reference to the client account that is linked to this Request.",
        help_text: "The account related to this Request.",
        default_value: null,
        placeholder_text: 'Enter an Account...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 5,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 5,
        is_sortable: true,
        sort_order: 5,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "accounts",
            filters: [
                {
                    fields: ["accounts.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            label: "True",
                            type: "value",
                            data: true,
                            data_type: 'boolean',
                            field_type: "checkbox",
                            input: true
                        }
                    ]
                }
            ],
            display_field: "name",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Primary Contact
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Primary Contact",
        identifier: "primary_contact",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Reference to the primary contact to reach out to in relation to the request.",
        help_text: "Reference to the primary contact to reach out to in relation to the request.",
        default_value: null,
        placeholder_text: 'Enter an Contact...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 6,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 6,
        is_sortable: true,
        sort_order: 6,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "contacts",
            filters: [
                {
                    fields: ["contacts.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            label: "True",
                            type: "value",
                            data: true,
                            data_type: 'boolean',
                            field_type: "checkbox",
                            input: true
                        }
                    ]
                }
            ],
            display_field: "name",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Primary Contact Name
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Primary Contact Name",
        identifier: "primary_contact_name",
        field_type: FieldTypes.NAME,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "The primary contact's name for searching purposes.",
        help_text: null,
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: true,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: 'A',
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Primary Contact Phone
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Primary Contact Phone",
        identifier: "primary_contact_phone",
        field_type: FieldTypes.PHONE,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "The primary contact's phone number for searching purposes.",
        help_text: null,
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: true,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: 'A',
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Primary Contact Email
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Primary Contact Email Address",
        identifier: "primary_contact_email",
        field_type: FieldTypes.EMAIL,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "The primary contact's email address for searching purposes.",
        help_text: null,
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: true,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: 'A',
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Property
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Property",
        identifier: "property",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Reference to the property for the request.",
        help_text: "Reference to the property for the request.",
        default_value: null,
        placeholder_text: 'Enter an Property...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 7,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        is_filterable: true,
        filter_order: 7,
        is_sortable: true,
        sort_order: 7,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "properties",
            filters: [
                {
                    fields: ["properties.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            label: "True",
                            type: "value",
                            data: true,
                            data_type: 'boolean',
                            field_type: "checkbox",
                            input: true
                        }
                    ]
                }
            ],
            display_field: "address",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Property Address
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Property Address",
        identifier: "property_address",
        field_type: FieldTypes.ADDRESS,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "The property's address for searching purposes.",
        help_text: null,
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: true,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: 'A',
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Priority
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Priority",
        identifier: "priority",
        field_type: FieldTypes.CUSTOM_DROPDOWN,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Shows how urgent this Request is to the company.",
        help_text: "Shows how urgent this Request is to the company.",
        default_value: null,
        placeholder_text: 'Enter a Priority...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 8,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 8,
        is_sortable: true,
        sort_order: 8,
        sort_method: SortingMethods.CUSTOM_SORT_ORDER,
        is_multi: false,
        is_async: true,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: {
            identifier: "global.priorities",
            object_identifier: "global",
            type: "custom_dropdown"
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Source
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Source",
        identifier: "source",
        field_type: FieldTypes.CUSTOM_DROPDOWN,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Represents where the Request first originated from.",
        help_text: "The source, or origin, of an Request. ",
        default_value: null,
        placeholder_text: 'Enter a Source...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 9,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 9,
        is_sortable: true,
        sort_order: 9,
        sort_method: SortingMethods.ALPHANUMERIC,
        is_multi: false,
        is_async: true,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: {
            identifier: "global.sources",
            object_identifier: "global",
            type: "custom_dropdown"
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Zone
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Zone",
        identifier: "zone",
        field_type: FieldTypes.CUSTOM_DROPDOWN,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Reference to the zone the Request is located in. Use zones to create custom sections in your local area.",
        help_text: "A custom geographical area to identify where a Request is located in.",
        default_value: null,
        placeholder_text: 'Enter a Zone...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 10,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 10,
        is_sortable: true,
        sort_order: 10,
        sort_method: SortingMethods.CUSTOM_SORT_ORDER,
        is_multi: false,
        is_async: true,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: {
            identifier: "global.zones",
            object_identifier: "global",
            type: "custom_dropdown"
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Tags
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Tags",
        identifier: "tags",
        field_type: FieldTypes.CUSTOM_DROPDOWN,
        data_type: DatabaseTypes.TEXT_ARRAY,
        special_type: null,
        description: "Label(s) attached to a Request for extra identification.",
        help_text: "Tag(s) are labels attached to a Request for extra identification.",
        default_value: null,
        placeholder_text: 'Enter tags for this Request...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 11,
        is_required: false,
        is_editable: true,
        is_searchable: true,
        search_priority: 'A',
        is_filterable: true,
        filter_order: 11,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: true,
        is_async: true,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: {
            identifier: "requests.tags",
            object_identifier: "requests",
            type: "text_dropdown"
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Owner
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Owner",
        identifier: "owner",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "References the User responsible for maintaing the information for this Request.",
        help_text: "References the User responsible for maintaing the information for this Request.",
        default_value: null,
        placeholder_text: 'Enter a User...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 12,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 12,
        is_sortable: true,
        sort_order: 11,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "users",
            filters: [
                {
                    fields: ["users.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            label: "True",
                            type: "value",
                            data: true,
                            data_type: 'boolean',
                            field_type: "checkbox",
                            input: true
                        }
                    ]
                }
            ],
            display_field: "name",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Request ID
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Request ID",
        identifier: "request_id",
        field_type: FieldTypes.PUBLIC_ID,
        data_type: DatabaseTypes.TEXT,
        special_type: SpecialTypes.PUBLIC_ID,
        description: "The unique ID for a Request shown to publically to requests. Request Prefix is 'req_'.",
        help_text: "The unique ID for a Request shown to publically to requests.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 13,
        is_required: true,
        is_editable: false,
        is_searchable: true,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: 'A',
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Request Number
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Request Number",
        identifier: "request_number",
        field_type: FieldTypes.INTEGER,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        description: "A sequentially generated integer representing the number of requests this organization has created.",
        help_text: "A sequentially generated integer representing the number of requests this organization has created.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 14,
        is_required: true,
        is_editable: false,
        is_searchable: true,
        is_filterable: false,
        filter_order: null,
        is_sortable: true,
        sort_order: 12,
        sort_method: SortingMethods.NUMERIC,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "General Information",
            identifier: "general_information",
            order: 1
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        search_priority: 'A',
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Intructions
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Instructions",
        identifier: "instructions",
        field_type: FieldTypes.LONG_TEXT,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        description: "Some internal instructions for Team members assigned to a Request.",
        help_text: "Some internal instructions for Team members assigned to a Request.",
        default_value: null,
        placeholder_text: 'Enter some intructions...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 15,
        is_required: false,
        is_editable: true,
        is_searchable: true,
        search_priority: 'B',
        is_filterable: false,
        filter_order: null,
        is_sortable: true,
        sort_order: 13,
        sort_method: SortingMethods.ALPHANUMERIC,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Schedule Information",
            identifier: "schedule_information",
            order: 2
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Client Intructions
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Client Instructions",
        identifier: "client_instructions",
        field_type: FieldTypes.LONG_TEXT,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        description: "Some public instructions from the client for Team members assigned to a Request.",
        help_text: "Some public instructions from the client for Team members assigned to a Request.",
        default_value: null,
        placeholder_text: 'Enter the client\'s intructions...',
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 16,
        is_required: false,
        is_editable: true,
        is_searchable: true,
        search_priority: 'B',
        is_filterable: false,
        filter_order: null,
        is_sortable: true,
        sort_order: 14,
        sort_method: SortingMethods.ALPHANUMERIC,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Schedule Information",
            identifier: "schedule_information",
            order: 2
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Minimum Crew Size
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Minimum Crew Size",
        identifier: "minimum_crew_size",
        field_type: FieldTypes.INTEGER,
        data_type: DatabaseTypes.INTEGER,
        special_type: null,
        description: "Min crew size",
        help_text: "Min crew size",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 17,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 13,
        is_sortable: true,
        sort_order: 15,
        sort_method: SortingMethods.NUMERIC,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Schedule Information",
            identifier: "schedule_information",
            order: 2
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Recommended Crew Size
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Recommended Crew Size",
        identifier: "recommended_crew_size",
        field_type: FieldTypes.INTEGER,
        data_type: DatabaseTypes.INTEGER,
        special_type: null,
        description: "Recommended crew size",
        help_text: "Recommended crew size",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 18,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 14,
        is_sortable: true,
        sort_order: 16,
        sort_method: SortingMethods.NUMERIC,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Schedule Information",
            identifier: "schedule_information",
            order: 2
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Assignment Status
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Assignment Status",
        identifier: "assignment_status",
        field_type: FieldTypes.CUSTOM_DROPDOWN,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Represents if this request is currently assigned",
        help_text: "Represents if this request is currently assigned",
        default_value: {
            data_type: DatabaseTypes.BIGINT,
            data: 'waiting_to_be_assigned'
        },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 19,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 15,
        is_sortable: false,
        sort_order: null,
        sort_method: SortingMethods.CUSTOM_SORT_ORDER,
        is_multi: false,
        is_async: true,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Schedule Information",
            identifier: "schedule_information",
            order: 2
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: { 
            identifier: "global.assignment_statuses", 
            object_identifier: "global", 
            type: "custom_dropdown" 
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Is Assigned
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Is Assigned",
        identifier: "is_assigned",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "Represents if the Request is currently assigned",
        help_text: "Represents if the Request is currently assigned",
        default_value: {
            data_type: DatabaseTypes.BOOLEAN,
            data: false
        },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 20,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 16,
        is_sortable: true,
        sort_order: 17,
        sort_method: SortingMethods.BOOLEAN,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Schedule Information",
            identifier: "schedule_information",
            order: 2
        },
        checkbox_labels: { true: "Assigned", false: "Not Assigned" },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Schedule Status
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Schedule Status",
        identifier: "schedule_status",
        field_type: FieldTypes.CUSTOM_DROPDOWN,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Represents the current schedule status for a Job.",
        help_text: "Represents the current schedule status for a Job.",
        default_value: {
            data_type: DatabaseTypes.BIGINT,
            data: 'waiting_to_be_scheduled'
        },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 21,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 17,
        is_sortable: false,
        sort_order: null,
        sort_method: SortingMethods.CUSTOM_SORT_ORDER,
        is_multi: false,
        is_async: true,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Schedule Information",
            identifier: "schedule_information",
            order: 2
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: { 
            identifier: "global.schedule_statuses", 
            object_identifier: "global", 
            type: "custom_dropdown" 
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Is Scheduled
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Is Scheduled",
        identifier: "is_scheduled",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "Represents if this request is currently scheduled",
        help_text: "Represents if this request is currently scheduled",
        default_value: {
            data_type: DatabaseTypes.BOOLEAN,
            data: false
        },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 22,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 18,
        is_sortable: true,
        sort_order: 18,
        sort_method: SortingMethods.BOOLEAN,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Schedule Information",
            identifier: "schedule_information",
            order: 2
        },
        checkbox_labels: { true: "Scheduled", false: "Not Scheduled" },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Is Assessment Required
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Is Assessment Required",
        identifier: "is_assessment_required",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "Represents if this request needs an assessment required",
        help_text: "Represents if this request needs an assessment required",
        default_value: {
            data_type: DatabaseTypes.BOOLEAN,
            data: false
        },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 23,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 19,
        is_sortable: true,
        sort_order: 19,
        sort_method: SortingMethods.BOOLEAN,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Schedule Information",
            identifier: "schedule_information",
            order: 2
        },
        checkbox_labels: { true: "Assessment Required", false: "No Assessment Required" },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Is Active
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Is Active",
        identifier: "is_active",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "Represents if this request is currently active; able for users to use it.",
        help_text: "Represents if this request is currently active; able for users to use it.",
        default_value: {
            data_type: DatabaseTypes.BOOLEAN,
            data: true
        },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 24,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 20,
        is_sortable: true,
        sort_order: 20,
        sort_method: SortingMethods.BOOLEAN,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 3
        },
        checkbox_labels: { true: "Active", false: "Not Active" },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Is Completed
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Is Completed",
        identifier: "is_completed",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "Represents if this request is completed",
        help_text: "Represents if this request is completed",
        default_value: {
            data_type: DatabaseTypes.BOOLEAN,
            data: true
        },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 25,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 21,
        is_sortable: true,
        sort_order: 21,
        sort_method: SortingMethods.BOOLEAN,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 3
        },
        checkbox_labels: { true: "Completed", false: "Not Completed" },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Is Converted
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Is Converted",
        identifier: "is_converted",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "Represents if this request is converted",
        help_text: "Represents if this request is converted",
        default_value: {
            data_type: DatabaseTypes.BOOLEAN,
            data: true
        },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 26,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 22,
        is_sortable: true,
        sort_order: 22,
        sort_method: SortingMethods.BOOLEAN,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "Request Checks",
            identifier: "request_checks",
            order: 2
        },
        checkbox_labels: { true: "Converted", false: "Not Converted" },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Is Archived
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Is Archived",
        identifier: "is_archived",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "If true, this Request is archived and not shown in normal use; only able to recover in the trash.",
        help_text: "If true, this Request is archived and not shown in normal use; only able to recover in the trash.",
        default_value: {
            data_type: DatabaseTypes.BOOLEAN,
            data: false
        },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: { true: "Archived", false: "Not Archived" },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Is Deleted
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Is Deleted",
        identifier: "is_deleted",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "If true, this Request is deleted and non-recoverable after 180 days.",
        help_text: "If true, this Request is deleted and non-recoverable after 180 days.",
        default_value: {
            data_type: DatabaseTypes.BOOLEAN,
            data: false
        },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: { true: "Deleted", false: "Not Deleted" },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Is Live
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Is Live",
        identifier: "is_live",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "If true, this Request is live for the organization. Not a record in testing mode.",
        help_text: "If true, this Request is live for the organization. Not a record in testing mode.",
        default_value: {
            data_type: DatabaseTypes.BOOLEAN,
            data: false
        },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: { true: "Live Data", false: "Test Data" },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Is Draft
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Is Draft",
        identifier: "is_draft",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "If true, this Request is a draft Request; Saved details, but not ready for release yet.",
        help_text: "If true, this Request is a draft Request; Saved details, but not ready for release yet.",
        default_value: {
            data_type: DatabaseTypes.BOOLEAN,
            data: false
        },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: { true: "Draft", false: "Public" },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Is Template
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Is Template",
        identifier: "is_template",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "If true, this Request is a template for creating future Requests faster.",
        help_text: "A template is used for creating future Requests faster.",
        default_value: {
            data_type: DatabaseTypes.BOOLEAN,
            data: false
        },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: { true: "Template", false: "Not Template" },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Is On Hold
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Is On Hold",
        identifier: "is_on_hold",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "Represents if this request is On Hold",
        help_text: "Represents if this request is On Hold",
        default_value: {
            data_type: DatabaseTypes.BOOLEAN,
            data: true
        },
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 27,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 23,
        is_sortable: true,
        sort_order: 23,
        sort_method: SortingMethods.BOOLEAN,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 3
        },
        checkbox_labels: { true: "On Hold", false: "Not On Hold" },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // On Hold Alert
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "On Hold Alert",
        identifier: "on_hold_alert",
        field_type: FieldTypes.ALERT,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "Shows an alert why the Request is currently On Hold",
        help_text: "Shows an alert why the Request is currently On Hold",
        default_value: null,
        placeholder_text: "Enter a reason why this Request is On Hold...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 28,
        is_required: false,
        is_editable: true,
        is_searchable: true,
        search_priority: 'B',
        is_filterable: true,
        filter_order: 24,
        is_sortable: true,
        sort_order: 24,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 3
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Popup Alert
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Popup Alert",
        identifier: "popup_alert",
        field_type: FieldTypes.ALERT,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "Shows an important message as a banner or dialog when viewing this record in detail.",
        help_text: "An important alert message shown when someone views an request.",
        default_value: null,
        placeholder_text: "Enter a popup message to show to requests...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 29,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 25,
        is_sortable: true,
        sort_order: 25,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 3
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Created At
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Created At",
        identifier: "created_at",
        field_type: FieldTypes.DATE_TIME,
        data_type: DatabaseTypes.TIMESTAMPTZ,
        special_type: null,
        description: "Represents the date/time the Request was created. System generated timestamp.",
        help_text: "The date/time when the Request was created.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 30,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 26,
        is_sortable: true,
        sort_order: 26,
        sort_method: SortingMethods.CHRONOLOGICAL,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 3
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Created By
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Created By",
        identifier: "created_by",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Represents the User who created the Request. System generated.",
        help_text: "The User who created an Request.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 31,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 27,
        is_sortable: true,
        sort_order: 27,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 3
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "users",
            filters: [
                {
                    fields: ["users.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            label: "True",
                            type: "value",
                            data: true,
                            data_type: 'boolean',
                            field_type: "checkbox",
                            input: true
                        }
                    ]
                }
            ],
            display_field: "name",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Last Activity At
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Last Activity At",
        identifier: "last_activity_at",
        field_type: FieldTypes.DATE_TIME,
        data_type: DatabaseTypes.TIMESTAMPTZ,
        special_type: null,
        description: "Represent the last timestamp when a related item to this request was created, updated, or deleted.",
        help_text: "Represent the last timestamp when a related item to this request was created, updated, or deleted.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 32,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 28,
        is_sortable: true,
        sort_order: 28,
        sort_method: SortingMethods.CHRONOLOGICAL,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 3
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Last Activity By
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Last Activity By",
        identifier: "last_activity_by",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Represent the last user who created, updated, or deleted a related item to this request.",
        help_text: "Represent the last user who created, updated, or deleted a related item to this request.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 33,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 29,
        is_sortable: true,
        sort_order: 29,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 3
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "users",
            filters: [
                {
                    fields: ["users.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            label: "True",
                            type: "value",
                            data: true,
                            data_type: 'boolean',
                            field_type: "checkbox",
                            input: true
                        }
                    ]
                }
            ],
            display_field: "name",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Last Updated At
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Last Updated At",
        identifier: "last_updated_at",
        field_type: FieldTypes.DATE_TIME,
        data_type: DatabaseTypes.TIMESTAMPTZ,
        special_type: null,
        description: "Represents the date/time when the Request was last updated. System generated timestamp.",
        help_text: "The date/time when the Request was last updated.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 34,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 30,
        is_sortable: true,
        sort_order: 30,
        sort_method: SortingMethods.CHRONOLOGICAL,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 3
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Last Updated By
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Last Updated By",
        identifier: "last_updated_by",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Represents the User who last updated the Request. System generated.",
        help_text: "The User who last updated the Request.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 35,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 31,
        is_sortable: true,
        sort_order: 31,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 3
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "users",
            filters: [
                {
                    fields: ["users.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            label: "True",
                            type: "value",
                            data: true,
                            data_type: 'boolean',
                            field_type: "checkbox",
                            input: true
                        }
                    ]
                }
            ],
            display_field: "name",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Last Viewed At
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Last Viewed At",
        identifier: "last_viewed_at",
        field_type: FieldTypes.DATE_TIME,
        data_type: DatabaseTypes.TIMESTAMPTZ,
        special_type: null,
        description: "Represents the date/time the Request was last viewed. System generated timestamp.",
        help_text: "The date/time when the Request was last viewed.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 36,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 32,
        is_sortable: true,
        sort_order: 32,
        sort_method: SortingMethods.CHRONOLOGICAL,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 3
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Last Viewed By
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Last Viewed By",
        identifier: "last_viewed_by",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Represents the User who last viewed the Request. System generated.",
        help_text: "The User who last viewed the Request.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 37,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 33,
        is_sortable: true,
        sort_order: 33,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 3
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "users",
            filters: [
                {
                    fields: ["users.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            label: "True",
                            type: "value",
                            data: true,
                            data_type: 'boolean',
                            field_type: "checkbox",
                            input: true
                        }
                    ]
                }
            ],
            display_field: "name",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Parent Request
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Parent Request",
        identifier: "parent",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "If this Request is a sub-Request, then this represents a link to the parent Request.",
        help_text: "The parent Request of this sub-Request.",
        default_value: null,
        placeholder_text: "Select a parent Request...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 38,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 34,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 3
        },
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "requests",
            filters: [
                {
                    fields: ["requests.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            label: "True",
                            type: "value",
                            data: true,
                            data_type: 'boolean',
                            field_type: "checkbox",
                            input: true
                        }
                    ]
                }
            ],
            display_field: "subject",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Is Sub Request
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Is Sub Request",
        identifier: "is_sub_request",
        field_type: FieldTypes.CHECKBOX,
        data_type: DatabaseTypes.BOOLEAN,
        special_type: null,
        description: "If true, this Request is a sub Request and as a parent Request associated with it. If true, the parent Request should be filled in.",
        help_text: "Represents whether an Request is a child Request to another Request in the system.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 39,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 35,
        is_sortable: true,
        sort_order: 34,
        sort_method: SortingMethods.BOOLEAN,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 3
        },
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        checkbox_labels: { true: "Sub Request", false: "Not Sub Request" },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Level
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Level",
        identifier: "level",
        field_type: FieldTypes.INTEGER,
        data_type: DatabaseTypes.INTEGER,
        special_type: null,
        description: "Represents the depth in the overall Request structure. (Parent Request, Sub Requests) (Max Level: 3)",
        help_text: "The depth of the Request in the overall parent-child Request structure.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 40,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 36,
        is_sortable: true,
        sort_order: 35,
        sort_method: SortingMethods.NUMERIC,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 5
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Currency
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Currency",
        identifier: "currency",
        field_type: FieldTypes.TEXT_DROPDOWN,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        description: "The currency of this Request.",
        help_text: "What currency this Request is based in.",
        default_value: { data_type: 'text', data: 'usd' },
        placeholder_text: "Select a currency...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 41,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 37,
        is_sortable: true,
        sort_order: 36,
        sort_method: SortingMethods.ALPHANUMERIC,
        is_multi: false,
        is_async: true,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 3
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: {
            identifier: "static.currencies",
            object_identifier: "static",
            type: "text_dropdown"
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Language
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Language",
        identifier: "language",
        field_type: FieldTypes.TEXT_DROPDOWN,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        description: "The language this request speaks.",
        help_text: "What language this request speaks.",
        default_value: { data_type: 'text', data: 'en' },
        placeholder_text: "Select a language...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 42,
        is_required: false,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: 38,
        is_sortable: false,
        sort_order: 37,
        sort_method: SortingMethods.ALPHANUMERIC,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 3
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: {
            identifier: "static.languages",
            object_identifier: "static",
            type: "text_dropdown"
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Time Zone
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Time Zone",
        identifier: "time_zone",
        field_type: FieldTypes.TEXT_DROPDOWN,
        data_type: DatabaseTypes.TEXT,
        special_type: null,
        description: "The Time Zone that the Account is based in.",
        help_text: "What time zone this request is based in.",
        default_value: { data_type: 'text', data: 'America/Los_Angeles' },
        placeholder_text: "Select a time zone...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 43,
        is_required: true,
        is_editable: true,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 39,
        is_sortable: true,
        sort_order: 38,
        sort_method: SortingMethods.ALPHANUMERIC,
        is_multi: false,
        is_async: true,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 3
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: {
            identifier: "static.time_zones",
            object_identifier: "static",
            type: "text_dropdown"
        },
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Completed Alert
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Completed Alert",
        identifier: "completed_alert",
        field_type: FieldTypes.ALERT,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "Shows the completed alert message as a banner or dialog when viewing the Request record in detail.",
        help_text: "An alert message detailing the completion of this Request.",
        default_value: null,
        placeholder_text: "Enter an completed alert message...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 44,
        is_required: false,
        is_editable: true,
        is_searchable: true,
        search_priority: 'B',
        is_filterable: true,
        filter_order: 40,
        is_sortable: true,
        sort_order: 39,
        sort_method: null,
        sort_field: null,
        sort_join: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 3
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Completed By
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Completed By",
        identifier: "completed_by",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Represents the User who completed the Request. System generated.",
        help_text: "The User who completed an Request.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 45,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 41,
        is_sortable: true,
        sort_order: 40,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 3
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "users",
            filters: [
                {
                    fields: ["users.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            label: "True",
                            type: "value",
                            data: true,
                            data_type: 'boolean',
                            field_type: "checkbox",
                            input: true
                        }
                    ]
                }
            ],
            display_field: "name",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Completed Date
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Completed Date",
        identifier: "completed_date",
        field_type: FieldTypes.DATE_TIME,
        data_type: DatabaseTypes.TIMESTAMPTZ,
        special_type: null,
        description: "Represents the date/time the Request was completed. System generated timestamp.",
        help_text: "The date/time when the Request was completed.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 46,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 42,
        is_sortable: true,
        sort_order: 41,
        sort_method: SortingMethods.CHRONOLOGICAL,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 3
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Closed Alert
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Closed Alert",
        identifier: "closed_alert",
        field_type: FieldTypes.ALERT,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "Shows the closed alert message as a banner or dialog when viewing the Request record in detail.",
        help_text: "An alert message detailing why this Request is closed.",
        default_value: null,
        placeholder_text: "Enter an closed alert message...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 47,
        is_required: false,
        is_editable: true,
        is_searchable: true,
        search_priority: 'B',
        is_filterable: true,
        filter_order: 43,
        is_sortable: true,
        sort_order: 42,
        sort_method: null,
        sort_field: null,
        sort_join: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 3
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Closed By
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Closed By",
        identifier: "closed_by",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Represents the User who closed the Request. System generated.",
        help_text: "The User who closed an Request.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 48,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 44,
        is_sortable: true,
        sort_order: 43,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 3
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "users",
            filters: [
                {
                    fields: ["users.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            label: "True",
                            type: "value",
                            data: true,
                            data_type: 'boolean',
                            field_type: "checkbox",
                            input: true
                        }
                    ]
                }
            ],
            display_field: "name",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Closed Date
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Closed Date",
        identifier: "closed_date",
        field_type: FieldTypes.DATE_TIME,
        data_type: DatabaseTypes.TIMESTAMPTZ,
        special_type: null,
        description: "Represents the date/time the Request was closed. System generated timestamp.",
        help_text: "The date/time when the Request was closed.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 49,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 45,
        is_sortable: true,
        sort_order: 44,
        sort_method: SortingMethods.CHRONOLOGICAL,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 3
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Converted Alert
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Converted Alert",
        identifier: "converted_alert",
        field_type: FieldTypes.ALERT,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "Shows the converted alert message as a banner or dialog when viewing the Request record in detail.",
        help_text: "An alert message detailing why this Request is converted.",
        default_value: null,
        placeholder_text: "Enter an converted alert message...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 50,
        is_required: false,
        is_editable: true,
        is_searchable: true,
        search_priority: 'B',
        is_filterable: true,
        filter_order: 46,
        is_sortable: true,
        sort_order: 45,
        sort_method: null,
        sort_field: null,
        sort_join: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 3
        },
        lookup_relationship_options: null,
        checkbox_labels: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Converted By
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Converted By",
        identifier: "converted_by",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "Represents the User who converted the Request. System generated.",
        help_text: "The User who converted an Request.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 51,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 47,
        is_sortable: true,
        sort_order: 46,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 3
        },
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "users",
            filters: [
                {
                    fields: ["users.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            label: "True",
                            type: "value",
                            data: true,
                            data_type: 'boolean',
                            field_type: "checkbox",
                            input: true
                        }
                    ]
                }
            ],
            display_field: "name",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Converted Date
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Converted Date",
        identifier: "converted_date",
        field_type: FieldTypes.DATE_TIME,
        data_type: DatabaseTypes.TIMESTAMPTZ,
        special_type: null,
        description: "Represents the date/time the Request was converted. System generated timestamp.",
        help_text: "The date/time when the Request was converted.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: true,
        visible_order: 52,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: true,
        filter_order: 48,
        is_sortable: true,
        sort_order: 47,
        sort_method: SortingMethods.CHRONOLOGICAL,
        is_multi: false,
        is_async: false,
        is_creatable: true,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: {
            label: "System Information",
            identifier: "system_information",
            order: 3
        },
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Archived Alert
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Archived Alert",
        identifier: "archived_alert",
        field_type: FieldTypes.ALERT,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "Shows an alert why the Request was archived when someone views an archived record.",
        help_text: "Shows an alert why the Request was archived when someone views an archived record.",
        default_value: null,
        placeholder_text: "Enter a reason for this Request's archival...",
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Archived At
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Archived At",
        identifier: "archived_at",
        field_type: FieldTypes.DATE_TIME,
        data_type: DatabaseTypes.TIMESTAMPTZ,
        special_type: null,
        description: "Represents the timestamp the User archived this Request. System generated timestamp.",
        help_text: "Represents the timestamp the User archived this Request. System generated timestamp.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Archived By
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Archived By",
        identifier: "archived_by",
        field_type: FieldTypes.DATE_TIME,
        data_type: DatabaseTypes.TIMESTAMPTZ,
        special_type: null,
        description: "Represents the User who archived this Request. System generated timestamp.",
        help_text: "Represents the User who archived this Request. System generated timestamp.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: null,
        lookup_relationship_options: {
            type: "independent",
            object_identifier: "users",
            filters: [
                {
                    fields: ["users.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            label: "True",
                            type: "value",
                            data: true,
                            data_type: 'boolean',
                            field_type: "checkbox",
                            input: true
                        }
                    ]
                }
            ],
            display_field: "name",
            strict_filter_lookups: false,
            is_related_record_editable: true,
            is_related_record_creatable: true 
        },
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Suggested Item Action
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Suggested Item Action",
        identifier: "suggested_action",
        field_type: FieldTypes.LOOKUP_RELATIONSHIP,
        data_type: DatabaseTypes.BIGINT,
        special_type: null,
        description: "The first shown item action for this Request.",
        help_text: "The first shown item action for this Request.",
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: null,
        lookup_relationship_options: {
            type: LookupRelationshipTypes.INDEPENDENT,
            object_identifier: "actions",
            filters: [
                {
                    fields: ["actions.is_active"],
                    field_types: ["checkbox"],
                    operator: "equal",
                    values: [
                        {
                            type: "value",
                            label: 'True',
                            input: true,
                            data: true,
                            data_type: 'boolean',
                            status: 'valid'
                        }
                    ]
                },
                {
                    fields: ["actions.object_identifier"],
                    field_types: ["id"],
                    operator: "equal",
                    values: [
                        {
                            type: "value",
                            label: 'Requests',
                            input: 'requests',
                            data: 'requests',
                            data_type: 'text',
                            status: 'valid'
                        }
                    ]
                }
            ],
            display_field: "label",
            strict_filter_lookups: false,
            is_related_record_editable: false,
            is_related_record_creatable: false
        },
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Sync Token
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Sync Token",
        identifier: "sync_token",
        field_type: FieldTypes.INTEGER,
        data_type: DatabaseTypes.INTEGER,
        special_type: null,
        description: "The current sync token for this Request; Only the most current sync token can be used to write data to avoid conflits.",
        help_text: null,
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Metadata
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Metadata",
        identifier: "metadata",
        field_type: FieldTypes.JSON,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "Some extra metadata that can be added to a Request.",
        help_text: null,
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Notifications
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Notifications",
        identifier: "notifications",
        field_type: FieldTypes.JSON,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "Represents some notifcations for the Request.",
        help_text: null,
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Custom Fields
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Custom Fields",
        identifier: "custom_fields",
        field_type: FieldTypes.JSON,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "Custom fields for the Request.",
        help_text: null,
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: false,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
    // Settings
    {
        id: null,
        company: null,
        public_id: uniqid('field_'),
        object: null,
        object_identifier: 'requests',
        sync_token: 1,
        label: "Settings",
        identifier: "settings",
        field_type: FieldTypes.JSON,
        data_type: DatabaseTypes.JSONB,
        special_type: null,
        description: "Represents some settings for the Request.",
        help_text: null,
        default_value: null,
        placeholder_text: null,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_visible: false,
        visible_order: null,
        is_required: false,
        is_editable: false,
        is_searchable: false,
        search_priority: null,
        is_filterable: false,
        filter_order: null,
        is_sortable: false,
        sort_order: null,
        sort_method: null,
        is_multi: false,
        is_async: true,
        is_creatable: false,
        is_custom: false,
        is_locked: true,
        is_encrypted: false,
        is_sensitive: false,
        group: null,
        checkbox_labels: null,
        lookup_relationship_options: null,
        dropdown_set: null,
        dropdown_set_options: null,
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
        last_viewed_by: null,
        last_viewed_at: new Date(),
    },
];

export default RequestFieldsData;
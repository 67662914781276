
// Import Modules
import uniqid from 'uniqid';
import { identifier } from 'safe-identifier';

// Import Custom Modules
import * as SortingMethods from '../../sorting/SortingMethods';
import * as SortingDirectionIdentifiers from '../../sorting/SortDataTypeIdentifiers';


const VehicleListViewsData = [
    // All Vehicles
    {
        object: null,
        object_identifier: 'vehicles',
        public_id: uniqid('view_'),
        sync_token: 1,
        type: "list_view",
        identifier: identifier('All Vehicles').toLowerCase(),
        name: 'All Vehicles',
        description: 'All vehicles with the company.',
        sort_order: 1,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        read_access: "all_users",
        edit_access: "private",
        created_at: new Date(),
        created_by: null,
        last_updated_at: new Date(),
        last_updated_by: null,
        last_viewed_at: new Date(),
        last_viewed_by: null,
        sort_field: "vehicles.fleet_number",
        sql_sort_field: ["vehicles.fleet_number"],
        sort_direction: SortingDirectionIdentifiers.ASCENDING,
        sort_join: null,
        sort_method: SortingMethods.NUMERIC,
        filter_by_viewer: false,
        filters: [ ],
        search_value: "",
        visible_fields: [
            {
                label: 'Title',
                identifier: 'vehicles.title',
                display_fields: ['vehicles.title'],
                display_format: null,
                width: 180,
                is_locked: true
            },
            {
                label: 'Type',
                identifier: 'vehicles.type',
                display_fields: ['vehicles.type', 'object_types.label'],
                display_format: null,
                width: 140,
                is_locked: false
            },
            {
                label: 'Status',
                identifier: 'vehicles.status',
                display_fields: ['vehicles.status', 'status.label'],
                display_format: null,
                width: 180,
                is_locked: false
            },
            {
                label: 'Fleet Number',
                identifier: 'vehicles.fleet_number',
                display_fields: ['vehicles.fleet_number'],
                display_format: null,
                width: 80,
                is_locked: true
            },
            {
                label: 'Driver',
                identifier: 'vehicles.driver',
                display_fields: ['vehicles.driver', 'users.name', 'name.display'],
                display_format: null,
                width: 140,
                is_locked: true
            },
        ],
        hidden_fields: [
            "vehicles.id",
            "vehicles.vehicle_id",
            "vehicles.type",
            "vehicles.sync_token",
            "vehicles.company",
            "vehicles.suggested_action"
        ],
        batch_actions: [
            "vehicles.batch_edit",
            "vehicles.batch_archive"
        ],
        item_actions: [
            "vehicles.archive"
        ],
        more_actions: [
            "vehicles.delete"
        ],
        pagination_amount: 25
    },
    
];

export default VehicleListViewsData;
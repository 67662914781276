// This constants file is a representation of how data for object types will be recieved by the ProHelper database once linked.
import uniqid from 'uniqid';


const PurchaseOrderObjectTypesData = [

    // ----------------------------------------
    // Purchase Order Object Types
    // ----------------------------------------

    // Parts
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "purchase_orders",
        sync_token: 1,
        label: "Parts",
        identifier: "parts",
        description: "A part purchase order",
        sort_order: 1,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Maintenence
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "purchase_orders",
        sync_token: 1,
        label: "Maintenance",
        identifier: "mainenance",
        description: "A maintenance purchase order",
        sort_order: 2,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Equipment
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "purchase_orders",
        sync_token: 1,
        label: "Equipment",
        identifier: "equipment",
        description: "A Equipment purchase order",
        sort_order: 3,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Equipment
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "purchase_orders",
        sync_token: 1,
        label: "Equipment",
        identifier: "equipment",
        description: "A Equipment purchase order",
        sort_order: 4,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Repair
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "purchase_orders",
        sync_token: 1,
        label: "Repair",
        identifier: "repair",
        description: "A Repair purchase order",
        sort_order: 5,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Inventory Stock
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "purchase_orders",
        sync_token: 1,
        label: "Inventory Stock",
        identifier: "inventory_stock",
        description: "A Inventory Stock purchase order",
        sort_order: 6,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
    // Special Order
    {
        public_id: uniqid("type_"),
        object: null,
        object_identifier: "purchase_orders",
        sync_token: 1,
        label: "Special Order",
        identifier: "special_order",
        description: "A Special Order purchase order",
        sort_order: 7,
        is_active: true,
        is_archived: false,
        is_deleted: false,
        is_live: true,
        is_custom: false,
        related_types: {},
        created_by: null,
        created_at: new Date(),
        last_updated_by: null,
        last_updated_at: new Date(),
    },
];

export default PurchaseOrderObjectTypesData;
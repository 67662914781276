
// Import NPM Modules
import React, { Component, Suspense, lazy } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { styled, createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { Auth } from 'aws-amplify';
import Media from 'react-media';
import _ from 'lodash';

// Import Custom Components
import * as NAV_ROUTES from './constants/navigation/routes';
import { GET_AUTH } from './graphql/queries/program/AuthQueries';
import { NO_HOVER_QUERY, MOBILE_PORTRAIT_MEDIA_QUERY, MOBILE_LANDSCAPE_MEDIA_QUERY, TABLET_PORTRAIT_MEDIA_QUERY, TABLET_LANDSCAPE_MEDIA_QUERY, DESKTOP_PORTRAIT_MEDIA_QUERY, DESKTOP_LANDSCAPE_MEDIA_QUERY, COLOR_THEME_PREFERENCE_DARK } from './constants/ui/css/UIMediaQueries';
import AuthenticationHelper from './helpers/program/AuthenticationHelper';
import NavigationHelper from './helpers/program/NavigationHelper';
import PermissionsHelper from './helpers/program/PermissionsHelper';
import CustomizationsHelper from './helpers/customizations/CustomizationsHelper';
import FakeDataHelper from './helpers/data/FakeDataHelper';
import LayoutContainer from './components/surfaces/app_layout/layout_container/LayoutContainer';
import ContainerPulseLoader from './components/feedback/progress/pulse_loader/ContainerPulseLoader';

// Import Constant Types
import * as AuthenticationActionTypes from './redux/action_types/program/AuthenticationActionTypes';
import * as PermissionsActionTypes from './redux/action_types/program/PermissionsActionTypes';
import * as NavigationActionTypes from './redux/action_types/program/NavigationActionTypes';
import * as ActionsActionTypes from './redux/action_types/customizations/ActionsActionTypes';
import * as NavigationItemsActionTypes from './redux/action_types/customizations/NavigationItemsActionTypes';
import * as ObjectsActionTypes from './redux/action_types/customizations/ObjectsActionTypes';
import * as ObjectTypesActionTypes from './redux/action_types/customizations/ObjectTypesActionTypes';
import * as ObjectFieldsActionTypes from './redux/action_types/customizations/ObjectFieldsActionTypes';
import * as DropdownSetsActionTypes from './redux/action_types/customizations/DropdownSetsActionTypes';
import * as ListViewsActionTypes from './redux/action_types/customizations/ListViewsActionTypes';
import * as PageLayoutsActionTypes from './redux/action_types/customizations/PageLayoutsActionTypes';
import * as FakeDBDataActionTypes from './redux/action_types/data/FakeDBDataActionTypes';
import * as LayoutActionTypes from './redux/action_types/ui/LayoutActionTypes';
import * as ThemeActionTypes from './redux/action_types/ui/ThemeActionTypes';
import * as ObjectIdentifiers from './constants/static_data/ObjectIdentifiers';
import LightTheme from './constants/ui/themes/light/LightTheme';
import DarkTheme from './constants/ui/themes/dark/DarkTheme';

// Lazy Loaded UI and Screens using React Suspense and Lazy
// Pre-Auth Screens
const ForgotPasswordScreen = lazy(() => import('./screens/pre_auth/forgot_password/ForgotPasswordScreen'));
const PrivateRoutes = lazy(() => import('./session/PrivateRoutes'));
const LoginScreen = lazy(() => import('./screens/pre_auth/login/LoginScreen'));
const SignUpScreen = lazy(() => import('./screens/pre_auth/sign_up/SignUpScreen'));
const NewPasswordScreen = lazy(() => import('./screens/pre_auth/new_password/NewPasswordScreen'));
const TermsOfServiceScreen = lazy(() => import('./screens/app/tos/TermsOfServiceScreen'));
const PrivacyPolicyScreen = lazy(() => import('./screens/app/pp/PrivacyPolicyScreen'));

// Core Program Screens
const ObjectListViewScreen = lazy(() => import('./screens/post_auth/object_list_view/ObjectListViewScreen'));
const ObjectPageLayoutScreen = lazy(() => import('./screens/post_auth/object_page_layout/ObjectPageLayoutScreen'));
const AppPageLayoutScreen = lazy(() => import('./screens/post_auth/app_page_layout/AppPageLayoutScreen'));

// Settings Screens
const SettingsScreen = lazy(() => import('./screens/post_auth/settings/SettingsScreen'));
// Settings - Customizations
const CustomizeObjectsScreen = lazy(() => import('./screens/post_auth/settings/customization/objects/CustomizeObjectsScreen'));
const CustomizationsManagerScreen = lazy(() => import('./screens/post_auth/settings/customization/manager/CustomizationsManagerScreen'));
const CustomizePageLayoutScreen = lazy(() => import('./screens/post_auth/settings/customization/page_layout/CustomizePageLayoutScreen'));
// Settings - Company (Org)
const CompanyInformationScreen = lazy(() => import('./screens/post_auth/settings/company/internal/CompanyInformationScreen'));
const CompanyBrandingScreen = lazy(() => import('./screens/post_auth/settings/company/branding/CompanyBrandingScreen'));
const CompanyFinancialsScreen = lazy(() => import('./screens/post_auth/settings/company/financial/CompanyFinancialsScreen'));
// Settings - ProHelper Account
const ProHelperOverviewScreen = lazy(() => import('./screens/post_auth/settings/prohelper/overview/ProHelperOverviewScreen'));
const ProHelperSubscriptionScreen = lazy(() => import('./screens/post_auth/settings/prohelper/subscription/ProHelperSubscriptionScreen'));
const ProHelperPlansScreen = lazy(() => import('./screens/post_auth/settings/prohelper/plans/ProHelperPlansScreen'));
const ProHelperPaymentsScreen = lazy(() => import('./screens/post_auth/settings/prohelper/payment_methods/ProHelperPaymentsScreen'));
// Settings - Data
const DataImportScreen = lazy(() => import('./screens/post_auth/settings/data/import/DataImportScreen'));
const DataExportScreen = lazy(() => import('./screens/post_auth/settings/data/export/DataExportScreen'));
const ManageIntegrationsScreen = lazy(() => import('./screens/post_auth/settings/data/manage_integrations/ManageIntegrationsScreen'));
const ArchiveScreen = lazy(() => import('./screens/post_auth/settings/data/archive/ArchiveScreen'));

// Other Screens
const UserSettingsScreen = lazy(() => import('./screens/post_auth/settings/user/user_settings/UserSettingsScreen'));
const SupportScreen = lazy(() => import('./screens/post_auth/settings/user/SupportSettingsScreen'));
const ChangeEmailScreen = lazy(() => import('./screens/post_auth/settings/user/ChangeEmailScreen'));
const ChangePasswordScreen = lazy(() => import('./screens/post_auth/settings/user/ChangePasswordScreen'));
const NotFoundScreen = lazy(() => import('./screens/app/not_found/NotFoundScreen'));



// External const files
const authenticationHelper = new AuthenticationHelper();
const navigationHelper = new NavigationHelper();
const permissionsHelper = new PermissionsHelper();
const customizationsHelper = new CustomizationsHelper();
const fakeDataHelper = new FakeDataHelper();

const AppComponent = styled('div')`
    width: 100%;
    height: -webkit-fill-available;
    overflow: visible;
    display: flex;
    flex-direction: row;
`;


class App extends Component {

    constructor(props) {

        // Super Props (required)
        super(props);

        // Bind Functions
        this.authenticateCognito = this.authenticateCognito.bind(this);
        this.authenticateProHelperDB = this.authenticateProHelperDB.bind(this);
        this.removeCustomizations = this.removeCustomizations.bind(this);

        // Set state
        this.state = {
            isAuthLoaded1: false,
            isAuthLoaded2: false,
            areCustomizationsLoaded: false
        };

    }

    /**
     * When the ProHelper Webapp first mounts, start the AWS cognito authentication process
     */
    componentDidMount() {
        // On mount, start process of authentication for the user
        this.authenticateCognito();
    }

    /**
     * When the ProHelper Webapp is authenticated, but doesn't have a ProHelper auth object, 
     * Start the ProHelper DB authentication process.
     */
    componentDidUpdate(prevProps) {

        // Wait until the cognito authentication is completed and valid
        if (
            _.isEmpty(this.props.auth) && 
            !_.isEmpty(this.props.cognito_user) && 
            this.props.login_status
        ) {
            // Once valid, start the ProHelper database authentication
            this.authenticateProHelperDB();
        }

    }


    /**
     * Get the current authenticated user from AWS Auth, then update the state & redux to 
     * complete the first step of authentication. 
     */
    async authenticateCognito() {
        try {
            // Get cognito current authenticated user
            let currentUser = await Auth.currentAuthenticatedUser();
            // If there is a current authenticated user, then update redux & state
            if (currentUser !== null && currentUser !== undefined && currentUser !== {}) {
                // Complete first part of authentication checks
                this.setState({ ...this.state, isAuthLoaded1: true });
                // Update the cognito user in redux
                this.props.onUpdateAuth({
                    cognito_user: currentUser,
                    login_status: currentUser.challengeName === undefined
                });
            }
        } catch (err) {
            console.error(err);
            // Stop loading and remove all authenticated items (sends the user to the login screen)
            this.props.onRemoveAuth();
            this.setState({ 
                ...this.state, 
                isAuthLoaded1: true, 
                isAuthLoaded2: true 
            });
        }
    }

    /**
     * If there is a valid AWS Cognito user, then query for a ProHelper Auth object to complete the second
     * step of authentication. If both valid, then also get the company, user, role, permissions, & customizations
     */
    async authenticateProHelperDB() {
        try {
            // Check if we have a valid cognito user
            if (!_.isEmpty(this.props.cognito_user)) {
                
                // Async GraphQL function to get custom database auth object
                const queryResult = await this.props.apolloClient.query({
                    query: GET_AUTH,
                    variables: { id: this.props.cognito_user.username || '' }
                });

                // Check if there is data (user has a valid account)
                if (!_.isEmpty(queryResult.data) && !_.isEmpty(queryResult.data.getAuth)) {
                    // The the auth object from the awaited data
                    let authObj = queryResult.data.getAuth;
                    // Create the fake updated new auth object (V0.5) (Remove for updated graphql function later)
                    let newAuthObj = authenticationHelper.CreateAuthV2(authObj);
                    // Get Customizations Object (contains all customizations in JSON format from data source)
                    // Fake data source for testing - change to GraphQL later
                    let customizationsData = customizationsHelper.GetCustomizationsQueryData(newAuthObj);
                    
                    // Fake like the redux stores updated the customizations object
                    let reduxCustomizationsData = customizationsHelper.ConvertCustomizationsQueryDataToReduxStoreFormat(customizationsData);
                    const ProHelperPermissions = permissionsHelper.CreateProgramPermissionsMap(newAuthObj, customizationsData);
                    
                    const adminPermissionSet = permissionsHelper.CreatePermissionSet('admin_permission_set', newAuthObj, ProHelperPermissions, reduxCustomizationsData);
                    // Create fake admin role
                    const adminRole = permissionsHelper.CreateRole('admin', newAuthObj, ProHelperPermissions, reduxCustomizationsData, adminPermissionSet);
                    newAuthObj.role = adminRole;
                    // Process auth, role, & permissions sets into one merged main permissions object for the user.
                    const userPermissions = permissionsHelper.CreatePermissions(newAuthObj, [ adminPermissionSet ]);
                    // Redux update the prohelper auth object for this user
                    this.props.onUpdateAuth({
                        auth: newAuthObj
                    });
                    // Redux update the permissions object for this user
                    this.props.setPermissions({ 
                        role: adminRole,
                        permission_sets: [ adminPermissionSet ],
                        all_permissions: userPermissions
                    });
                    // Create new authenticated navigation categories for the sidebar area
                    let newAuthCategories = navigationHelper.CreateAuthNavigationItemsLayout(
                        newAuthObj, 
                        customizationsData.navigation_items, 
                        userPermissions.navigation_item_permissions
                    );
                    // Create new authenticated navigation categories for the sidebar area
                    let newSettingsCategories = navigationHelper.CreateSettingsCategories(
                        newAuthObj, 
                        userPermissions
                    );
                    // Redux update auth navigator categories for dynamic auth menus
                    this.props.setNavigation({
                        authNavigatorCategories: newAuthCategories,
                        settingsCategories: newSettingsCategories
                    });
                    // // Set Redux isMobile
                    // this.props.setIsMobile(isMobile);

                    // Load Customizations
                    // Update All Customization Reducers
                    // Update Actions Reducer Store
                    this.props.setAllActions(customizationsData.actions);
                    // Update Navigation Items Reducer Store
                    this.props.setAllNavigationItems(customizationsData.navigation_items);
                    // Update Objects Reducer Store
                    this.props.setAllObjects(customizationsData.objects);
                    // Update Object Types Reducer Store
                    this.props.setAllObjectTypes(customizationsData.object_types);
                    // Update Object Fields Reducer Store
                    this.props.setAllObjectFields(customizationsData.object_fields);
                    // Update Dropdown Sets Reducer Store
                    this.props.setAllDropdownSets(customizationsData.dropdown_sets);
                    // Update List Views Reducer Store
                    this.props.setAllListViews(customizationsData.list_views);
                    // Update Page Layouts Reducer Store
                    this.props.setAllPageLayouts(customizationsData.page_layouts);

                    this.setState({ 
                        ...this.state,
                        isAuthLoaded1: true, 
                        isAuthLoaded2: true,
                        areCustomizationsLoaded: true
                    });

                    // // Load all fake data
                    const new_fake_db = fakeDataHelper.CreateFakeDatabaseData(
                        newAuthObj, 
                        ProHelperPermissions,
                        userPermissions,
                        reduxCustomizationsData
                    );

                    this.props.setFakeDB(new_fake_db);
                } else {
                    console.log("Data not found")
                }
            }
        } catch (err) {
            // Stop loading and remove all authenticated items
            console.log(err);
            this.props.onRemoveAuth();
            this.removeCustomizations();
            this.setState({ isAuthLoaded1: true, isAuthLoaded2: true });
        }
    }

    removeCustomizations() {
        // Update Actions Reducer Store
        this.props.removeAllActions();
        // Update Navigation Items Reducer Store
        this.props.removeAllNavigationItems();
        // Update Objects Reducer Store
        this.props.removeAllObjects();
        // Update Object Types Reducer Store
        this.props.removeAllObjectTypes();
        // Update Object Fields Reducer Store
        this.props.removeAllObjectFields();
        // Update Dropdown Sets Reducer Store
        this.props.removeAllDropdownSets();
        // Update List Views Reducer Store
        this.props.removeAllListViews();
        // Update Page Layouts Reducer Store
        this.props.removeAllPageLayouts();
    }


    render() {

        // Return React Router Screen in LayoutContainer. Authorized routes should be inside of AuthenticationCheck. 
        return (
            <AppComponent>
                <Media
                    queries={{
                        no_hover: NO_HOVER_QUERY,
                        mobile_portrait: MOBILE_PORTRAIT_MEDIA_QUERY,
                        mobile_landscape: MOBILE_LANDSCAPE_MEDIA_QUERY,
                        tablet_portrait: TABLET_PORTRAIT_MEDIA_QUERY,
                        tablet_landscape:TABLET_LANDSCAPE_MEDIA_QUERY,
                        desktop_portrait: DESKTOP_PORTRAIT_MEDIA_QUERY,
                        desktop_landscape: DESKTOP_LANDSCAPE_MEDIA_QUERY,
                        dark_theme: COLOR_THEME_PREFERENCE_DARK
                    }}
                    onChange={matches => {

                        // Handle when the viewport size changes
                        // Update the LayoutReducer's display_type to be either "mobile", "tablet", or "desktop"
                        // Start with the Layout Reducer's previous Display Type, if doesn't exist then set to "not-set"
                        let newDisplayType = this.props.display_type ? this.props.display_type : 'not-set';

                        // Check if any of the media queries match and change the new display type
                        if (matches.mobile_portrait || matches.mobile_landscape) {
                            newDisplayType = 'mobile';
                        } else if (matches.tablet_portrait || matches.tablet_landscape) {
                            newDisplayType = 'tablet';
                        } else if (matches.desktop_portrait || matches.desktop_landscape) {
                            newDisplayType = 'desktop';
                        }

                        // Check if the new layout display type or isMobile does not equal the previous value
                        if ((this.props.display_type !== newDisplayType) || (this.props.is_mobile !== isMobile) ) {
                            // Update the Layout Reducer
                            this.props.setLayout({
                                display_type: newDisplayType,
                                is_mobile: isMobile,
                                theme: matches.dark_theme ? DarkTheme : LightTheme
                            });
                        }

                        // // Update the color theme reducer based on system theme preference
                        // this.props.setTheme(matches.dark_theme ? DarkTheme : LightTheme);
                        // Update the local storage 
                        localStorage.setItem('theme', matches.dark_theme ? 'dark' : 'light');
                    }}
                >
                    {(matches) => {
                        
                        return (
                            <ThemeProvider theme={responsiveFontSizes(createTheme(matches.dark_theme ? DarkTheme : LightTheme), { breakpoints: ['xs', 'sm', 'md', 'lg'], factor: 2.5 })}>
                                <BrowserRouter>
                                    {/* Manages header & sidebar */}
                                    <LayoutContainer>
                                        <Suspense fallback={<ContainerPulseLoader />} >
                                            {/* Switch to the correct route for the current path. */}
                                            <Routes>

                                                <Route element={
                                                    <PrivateRoutes
                                                        auth={this.props.auth}
                                                        cognito_user={this.props.cognito_user}
                                                        login_status={this.props.login_status}
                                                        permissions={this.props.permissions}
                                                    />
                                                } >
                                                    <Route
                                                        exact
                                                        path={NAV_ROUTES.HOME}
                                                        element={<AppPageLayoutScreen />}
                                                    />

                                                    {/* Schedule Area */}
                                                    <Route
                                                        exact
                                                        path={NAV_ROUTES.SCHEDULE}
                                                        element={<AppPageLayoutScreen />}
                                                    />

                                                    {/* Events */}
                                                    <Route
                                                        exact
                                                        path={NAV_ROUTES.EVENTS}
                                                        element={<ObjectListViewScreen object_identifier={ObjectIdentifiers.EVENTS} />}
                                                    />
                                                    {/* Event Detail */}
                                                    <Route
                                                        exact
                                                        path={NAV_ROUTES.EVENT_DETAIL_PREFIX}
                                                        element={<ObjectPageLayoutScreen object_identifier={ObjectIdentifiers.EVENTS} />}
                                                    />

                                                    {/* Visits */}
                                                    <Route
                                                        exact
                                                        path={NAV_ROUTES.VISITS}
                                                        element={<ObjectListViewScreen object_identifier={ObjectIdentifiers.VISITS} />}
                                                    />
                                                    {/* Visit Detail */}
                                                    <Route
                                                        exact
                                                        path={NAV_ROUTES.VISIT_DETAIL_PREFIX}
                                                        element={<ObjectPageLayoutScreen object_identifier={ObjectIdentifiers.VISITS} />}
                                                    />

                                                    {/* Work Area */}
                                                    <Route
                                                        exact
                                                        path={NAV_ROUTES.WORK}
                                                        element={<AppPageLayoutScreen />}
                                                    />

                                                    {/* Requests */}
                                                    <Route
                                                        exact
                                                        path={NAV_ROUTES.REQUESTS}
                                                        element={<ObjectListViewScreen object_identifier={ObjectIdentifiers.REQUESTS} />}
                                                    />
                                                    {/* Request Detail */}
                                                    <Route
                                                        exact
                                                        path={NAV_ROUTES.REQUEST_DETAIL_PREFIX}
                                                        element={<ObjectPageLayoutScreen object_identifier={ObjectIdentifiers.REQUESTS} />}
                                                    />

                                                    {/* Estimates */}
                                                    <Route
                                                        exact
                                                        path={NAV_ROUTES.ESTIMATES}
                                                        element={<ObjectListViewScreen object_identifier={ObjectIdentifiers.ESTIMATES} />}
                                                    />
                                                    {/* Estimate Detail */}
                                                    <Route
                                                        exact
                                                        path={NAV_ROUTES.ESTIMATE_DETAIL_PREFIX}
                                                        element={<ObjectPageLayoutScreen object_identifier={ObjectIdentifiers.ESTIMATES} />}
                                                    />

                                                    {/* Tasks */}
                                                    <Route
                                                        exact
                                                        path={NAV_ROUTES.TASKS}
                                                        element={<ObjectListViewScreen object_identifier={ObjectIdentifiers.TASKS} />}
                                                    />
                                                    {/* Task Detail */}
                                                    <Route
                                                        exact
                                                        path={NAV_ROUTES.TASK_DETAIL_PREFIX}
                                                        element={<ObjectPageLayoutScreen object_identifier={ObjectIdentifiers.TASKS} />}
                                                    />

                                                    {/* Jobs */}
                                                    <Route
                                                        exact
                                                        path={NAV_ROUTES.JOBS}
                                                        element={<ObjectListViewScreen object_identifier={ObjectIdentifiers.JOBS} />}
                                                    />
                                                    {/* Job Detail */}
                                                    <Route
                                                        exact
                                                        path={NAV_ROUTES.JOB_DETAIL_PREFIX}
                                                        element={<ObjectPageLayoutScreen object_identifier={ObjectIdentifiers.JOBS} />}
                                                    />

                                                    {/* Routes */}
                                                    <Route
                                                        exact
                                                        path={NAV_ROUTES.ROUTES}
                                                        element={<ObjectListViewScreen object_identifier={ObjectIdentifiers.ROUTES} />}
                                                    />
                                                    {/* Route Detail */}
                                                    <Route
                                                        exact
                                                        path={NAV_ROUTES.ROUTE_DETAIL_PREFIX}
                                                        element={<ObjectPageLayoutScreen object_identifier={ObjectIdentifiers.ROUTES} />}
                                                    />

                                                    {/* Clients Area */}
                                                    <Route
                                                        exact
                                                        path={NAV_ROUTES.CLIENTS}
                                                        element={<AppPageLayoutScreen />}
                                                    />

                                                    {/* Accounts */}
                                                    <Route
                                                        exact
                                                        path={NAV_ROUTES.ACCOUNTS}
                                                        element={<ObjectListViewScreen object_identifier={ObjectIdentifiers.ACCOUNTS} />}
                                                    />
                                                    {/* Account Detail */}
                                                    <Route
                                                        exact
                                                        path={NAV_ROUTES.ACCOUNT_DETAIL_PREFIX}
                                                        element={<ObjectPageLayoutScreen object_identifier={ObjectIdentifiers.ACCOUNTS} />}
                                                    />

                                                    {/* Contacts */}
                                                    <Route
                                                        exact
                                                        path={NAV_ROUTES.CONTACTS}
                                                        element={<ObjectListViewScreen object_identifier={ObjectIdentifiers.CONTACTS} />}
                                                    />
                                                    {/* Contact Detail */}
                                                    <Route
                                                        exact
                                                        path={NAV_ROUTES.CONTACT_DETAIL_PREFIX}
                                                        element={<ObjectPageLayoutScreen object_identifier={ObjectIdentifiers.CONTACTS} />}
                                                    />

                                                    {/* Properties */}
                                                    <Route
                                                        exact
                                                        path={NAV_ROUTES.PROPERTIES}
                                                        element={<ObjectListViewScreen object_identifier={ObjectIdentifiers.PROPERTIES} />}
                                                    />
                                                    {/* Property Detail */}
                                                    <Route
                                                        exact
                                                        path={NAV_ROUTES.PROPERTY_DETAIL_PREFIX}
                                                        element={<ObjectPageLayoutScreen object_identifier={ObjectIdentifiers.PROPERTIES} />}
                                                    />

                                                    {/* Billing Area */}
                                                    <Route
                                                        exact
                                                        path={NAV_ROUTES.BILLING}
                                                        element={<AppPageLayoutScreen />}
                                                    />

                                                    {/* Invoices */}
                                                    <Route
                                                        exact
                                                        path={NAV_ROUTES.INVOICES}
                                                        element={<ObjectListViewScreen object_identifier={ObjectIdentifiers.INVOICES} />}
                                                    />
                                                    {/* Invoice Detail */}
                                                    <Route
                                                        exact
                                                        path={NAV_ROUTES.INVOICE_DETAIL_PREFIX}
                                                        element={<ObjectPageLayoutScreen object_identifier={ObjectIdentifiers.INVOICES} />}
                                                    />

                                                    {/* Payments */}
                                                    <Route
                                                        exact
                                                        path={NAV_ROUTES.PAYMENTS}
                                                        element={<ObjectListViewScreen object_identifier={ObjectIdentifiers.PAYMENTS} />}
                                                    />
                                                    {/* Payment Detail */}
                                                    <Route
                                                        exact
                                                        path={NAV_ROUTES.PAYMENT_DETAIL_PREFIX}
                                                        element={<ObjectPageLayoutScreen object_identifier={ObjectIdentifiers.PAYMENTS} />}
                                                    />

                                                    {/* Items */}
                                                    <Route
                                                        exact
                                                        path={NAV_ROUTES.ITEMS}
                                                        element={<ObjectListViewScreen object_identifier={ObjectIdentifiers.ITEMS} />}
                                                    />
                                                    {/* Item Detail */}
                                                    <Route
                                                        exact
                                                        path={NAV_ROUTES.ITEM_DETAIL_PREFIX}
                                                        element={<ObjectPageLayoutScreen object_identifier={ObjectIdentifiers.ITEMS} />}
                                                    />

                                                    {/* Subscriptions */}
                                                    <Route
                                                        exact
                                                        path={NAV_ROUTES.SUBSCRIPTIONS}
                                                        element={<ObjectListViewScreen object_identifier={ObjectIdentifiers.SUBSCRIPTIONS} />}
                                                    />
                                                    {/* Subscription Detail */}
                                                    <Route
                                                        exact
                                                        path={NAV_ROUTES.SUBSCRIPTION_DETAIL_PREFIX}
                                                        element={<ObjectPageLayoutScreen object_identifier={ObjectIdentifiers.SUBSCRIPTIONS} />}
                                                    />

                                                    {/* Purchase Orders */}
                                                    <Route
                                                        exact
                                                        path={NAV_ROUTES.PURCHASE_ORDERS}
                                                        element={<ObjectListViewScreen object_identifier={ObjectIdentifiers.PURCHASE_ORDERS} />}
                                                    />
                                                    {/* Item Detail */}
                                                    <Route
                                                        exact
                                                        path={NAV_ROUTES.PURCHASE_ORDER_DETAIL_PREFIX}
                                                        element={<ObjectPageLayoutScreen object_identifier={ObjectIdentifiers.PURCHASE_ORDERS} />}
                                                    />

                                                    {/* Audit Area */}
                                                    <Route
                                                        exact
                                                        path={NAV_ROUTES.AUDIT}
                                                        element={<AppPageLayoutScreen />}
                                                    />

                                                    {/* Ledgers */}
                                                    <Route
                                                        exact
                                                        path={NAV_ROUTES.LEDGERS}
                                                        element={<ObjectListViewScreen object_identifier={ObjectIdentifiers.LEDGERS} />}
                                                    />
                                                    {/* Ledger Detail */}
                                                    <Route
                                                        exact
                                                        path={NAV_ROUTES.LEDGER_DETAIL_PREFIX}
                                                        element={<ObjectPageLayoutScreen object_identifier={ObjectIdentifiers.LEDGERS} />}
                                                    />

                                                    {/* Reports */}
                                                    <Route
                                                        exact
                                                        path={NAV_ROUTES.REPORTS}
                                                        element={<ObjectListViewScreen object_identifier={ObjectIdentifiers.REPORTS} />}
                                                    />
                                                    {/* Report Detail */}
                                                    <Route
                                                        exact
                                                        path={NAV_ROUTES.REPORT_DETAIL_PREFIX}
                                                        element={<ObjectPageLayoutScreen object_identifier={ObjectIdentifiers.REPORTS} />}
                                                    />


                                                    {/* Team Area */}
                                                    <Route
                                                        exact
                                                        path={NAV_ROUTES.TEAM}
                                                        element={<AppPageLayoutScreen />}
                                                    />

                                                    {/* Users */}
                                                    <Route
                                                        exact
                                                        path={NAV_ROUTES.USERS}
                                                        element={<ObjectListViewScreen object_identifier={ObjectIdentifiers.USERS} />}
                                                    />
                                                    {/* User Detail */}
                                                    <Route
                                                        exact
                                                        path={NAV_ROUTES.USER_DETAIL_PREFIX}
                                                        element={<ObjectPageLayoutScreen object_identifier={ObjectIdentifiers.USERS} />}
                                                    />
                                                    {/* Vehicles */}
                                                    <Route
                                                        exact
                                                        path={NAV_ROUTES.VEHICLES}
                                                        element={<ObjectListViewScreen object_identifier={ObjectIdentifiers.VEHICLES} />}
                                                    />
                                                    {/* Vehicle Detail */}
                                                    <Route
                                                        exact
                                                        path={NAV_ROUTES.VEHICLES_DETAIL_PREFIX}
                                                        element={<ObjectPageLayoutScreen object_identifier={ObjectIdentifiers.VEHICLES} />}
                                                    />

                                                    {/* Security Area */}
                                                    <Route
                                                        exact
                                                        path={NAV_ROUTES.SECURITY}
                                                        element={<AppPageLayoutScreen />}
                                                    />

                                                    {/* Roles */}
                                                    <Route
                                                        exact
                                                        path={NAV_ROUTES.ROLES}
                                                        element={<ObjectListViewScreen object_identifier={ObjectIdentifiers.ROLES} />}
                                                    />
                                                    {/* Role Detail */}
                                                    <Route
                                                        exact
                                                        path={NAV_ROUTES.ROLE_DETAIL_PREFIX}
                                                        element={<ObjectPageLayoutScreen object_identifier={ObjectIdentifiers.ROLES} />}
                                                    />

                                                    {/* Permission Sets */}
                                                    <Route
                                                        exact
                                                        path={NAV_ROUTES.PERMISSION_SETS}
                                                        element={<ObjectListViewScreen object_identifier={ObjectIdentifiers.PERMISSION_SETS} />}
                                                    />
                                                    {/* Permission Set Detail */}
                                                    <Route
                                                        exact
                                                        path={NAV_ROUTES.PERMISSION_SET_DETAIL_PREFIX}
                                                        element={<ObjectPageLayoutScreen object_identifier={ObjectIdentifiers.PERMISSION_SETS} />}
                                                    />


                                                    {/* Settings */}
                                                    <Route
                                                        exact
                                                        path={NAV_ROUTES.SETTINGS_USER}
                                                        element={<UserSettingsScreen />}
                                                    />
                                                    <Route
                                                        exact
                                                        path={NAV_ROUTES.SETTINGS_HELP}
                                                        element={<SupportScreen />}
                                                    />
                                                    <Route
                                                        exact
                                                        path={NAV_ROUTES.CHANGE_EMAIL}
                                                        element={<ChangeEmailScreen />}
                                                    />
                                                    <Route
                                                        exact
                                                        path={NAV_ROUTES.CHANGE_PASSWORD}
                                                        element={<ChangePasswordScreen />}
                                                    />


                                                    {/* Company Settings */}
                                                    <Route
                                                        exact
                                                        path={NAV_ROUTES.SETTINGS_COMPANY}
                                                        element={<CompanyInformationScreen />}
                                                    />
                                                    <Route
                                                        exact
                                                        path={NAV_ROUTES.SETTINGS_COMPANY_BRANDING}
                                                        element={<CompanyBrandingScreen />}
                                                    />
                                                    <Route
                                                        exact
                                                        path={NAV_ROUTES.SETTINGS_COMPANY_FINANCIAL_INFORMATION}
                                                        element={<CompanyFinancialsScreen />}
                                                    />

                                                    {/* ProHelper Settings */}
                                                    <Route
                                                        exact
                                                        path={NAV_ROUTES.SETTINGS_PROHELPER_OVERVIEW}
                                                        element={<ProHelperOverviewScreen />}
                                                    />
                                                    <Route
                                                        exact
                                                        path={NAV_ROUTES.SETTINGS_PROHELPER_SUBSCRIPTION}
                                                        element={<ProHelperSubscriptionScreen />}
                                                    />
                                                    <Route
                                                        exact
                                                        path={NAV_ROUTES.SETTINGS_PROHELPER_PLANS}
                                                        element={<ProHelperPlansScreen />}
                                                    />
                                                    <Route
                                                        exact
                                                        path={NAV_ROUTES.SETTINGS_PROHELPER_PAYMENTS}
                                                        element={<ProHelperPaymentsScreen />}
                                                    />

                                                    {/* Customization Settings */}
                                                    <Route
                                                        exact
                                                        path={NAV_ROUTES.SETTINGS_CUSTOMIZE_OBJECTS}
                                                        element={<CustomizeObjectsScreen />}
                                                    />
                                                    <Route
                                                        exact
                                                        path={NAV_ROUTES.SETTINGS_CUSTOMIZATIONS_MANAGER}
                                                        element={<CustomizationsManagerScreen />}
                                                    />
                                                    <Route
                                                        exact
                                                        path={NAV_ROUTES.SETTINGS_CUSTOMIZE_PAGE_LAYOUT}
                                                        element={<CustomizePageLayoutScreen />}
                                                    />



                                                    {/* Data Settings */}
                                                    
                                                    <Route
                                                        exact
                                                        path={NAV_ROUTES.SETTINGS_DATA_IMPORT}
                                                        element={<DataImportScreen />}
                                                    />
                                                    <Route
                                                        exact
                                                        path={NAV_ROUTES.SETTINGS_DATA_EXPORT}
                                                        element={<DataExportScreen />}
                                                    />
                                                    <Route
                                                        exact
                                                        path={NAV_ROUTES.SETTINGS_DATA_ARCHIVE}
                                                        element={<ArchiveScreen />}
                                                    />
                                                    <Route
                                                        exact
                                                        path={NAV_ROUTES.SETTINGS_DATA_MANAGE_INTEGRATIONS}
                                                        element={<ManageIntegrationsScreen />}
                                                    />

                                                    {/* Settings - All Settings Screen */}
                                                    <Route
                                                        exact
                                                        path={NAV_ROUTES.SETTINGS}
                                                        element={<SettingsScreen />}
                                                    />
                                                </Route>


                                                {/* Sign Up Screen */}
                                                <Route
                                                    exact
                                                    path={NAV_ROUTES.SIGN_UP}
                                                    element={<SignUpScreen />}
                                                />
                                                {/* Log In Screen */}
                                                <Route 
                                                    exact
                                                    path={NAV_ROUTES.LOG_IN}
                                                    element={<LoginScreen />}
                                                />
                                                {/* Password Management Screens */}
                                                <Route
                                                    exact
                                                    path={NAV_ROUTES.FORGOT_PASSWORD}
                                                    element={<ForgotPasswordScreen />}
                                                />
                                                <Route
                                                    exact
                                                    path={NAV_ROUTES.NEW_PASSWORD}
                                                    element={<NewPasswordScreen />}
                                                />
                                                {/* Terms of Service Screen */}
                                                <Route
                                                    exact
                                                    path={NAV_ROUTES.TOS}
                                                    element={<TermsOfServiceScreen />}
                                                />
                                                {/* Privacy Policy Screen */}
                                                <Route
                                                    exact
                                                    path={NAV_ROUTES.PP}
                                                    element={<PrivacyPolicyScreen />}
                                                />

                                                {/* Not Found Screen, should be the last route. */}
                                                <Route
                                                    path="*"
                                                    element={<NotFoundScreen />} 
                                                />
                                            </Routes>
                                        </Suspense>
                                    </LayoutContainer>
                                </BrowserRouter>
                            </ThemeProvider>
                        )
                    }}
                </Media>
            </AppComponent>
        );
    }
}

// Redux Information to Props Map
const mapStateToProps = state => {
    return {
        is_mobile: state.ui.layout.is_mobile,
        display_type: state.ui.layout.display_type,
        auth: state.program.authentication.auth,
        customizations: state.customizations,
        permissions: state.program.permissions.all_permissions,
        cognito_user: state.program.authentication.cognito_user,
        login_status: state.program.authentication.login_status,
    };
};

// Redux Actions to Props Map
const mapDispatchToProps = dispatch => {
    return {
        onUpdateAuth: (payload) => dispatch({ type: AuthenticationActionTypes.UPDATE_AUTH, payload: payload }),
        onRemoveAuth: () => dispatch({ type: AuthenticationActionTypes.REMOVE_AUTH }),
        setPermissions: (payload) => dispatch({ type: PermissionsActionTypes.SET_PERMISSIONS, payload: payload }),
        setNavigation: (payload) => dispatch({ type: NavigationActionTypes.SET_NAVIGATION, payload: payload }),
        setAllNavigationItems: (navigation_items) => dispatch({ type: NavigationItemsActionTypes.SET_ALL_NAVIGATION_ITEMS, payload: { navigation_items: navigation_items } }),
        removeAllNavigationItems: () => dispatch({ type: NavigationItemsActionTypes.REMOVE_ALL_NAVIGATION_ITEMS }),
        setAllActions: (actions) => dispatch({ type: ActionsActionTypes.SET_ALL_ACTIONS, payload: { actions: actions } }),
        removeAllActions: () => dispatch({ type: ActionsActionTypes.REMOVE_ALL_ACTIONS }),
        setAllObjects: (objects) => dispatch({ type: ObjectsActionTypes.SET_ALL_OBJECTS, payload: { objects: objects } }),
        removeAllObjects: () => dispatch({ type: ObjectsActionTypes.REMOVE_ALL_OBJECTS }),
        setAllObjectTypes: (object_types) => dispatch({ type: ObjectTypesActionTypes.SET_ALL_OBJECT_TYPES, payload: { object_types: object_types } }),
        removeAllObjectTypes: () => dispatch({ type: ObjectTypesActionTypes.REMOVE_ALL_OBJECT_TYPES }),
        setAllObjectFields: (object_fields) => dispatch({ type: ObjectFieldsActionTypes.SET_ALL_OBJECT_FIELDS, payload: { object_fields: object_fields } }),
        removeAllObjectFields: () => dispatch({ type: ObjectFieldsActionTypes.REMOVE_ALL_OBJECT_FIELDS }),
        setAllDropdownSets: (dropdown_sets) => dispatch({ type: DropdownSetsActionTypes.SET_ALL_DROPDOWN_SETS, payload: { dropdown_sets: dropdown_sets } }),
        removeAllDropdownSets: () => dispatch({ type: DropdownSetsActionTypes.REMOVE_ALL_DROPDOWN_SETS }),
        setAllListViews: (list_views) => dispatch({ type: ListViewsActionTypes.SET_ALL_LIST_VIEWS, payload: { list_views: list_views } }),
        removeAllListViews: () => dispatch({ type: ListViewsActionTypes.REMOVE_ALL_LIST_VIEWS }),
        setAllPageLayouts: (page_layouts) => dispatch({ type: PageLayoutsActionTypes.SET_ALL_PAGE_LAYOUTS, payload: { page_layouts: page_layouts } }),
        removeAllPageLayouts: () => dispatch({ type: PageLayoutsActionTypes.REMOVE_ALL_PAGE_LAYOUTS }),
        setLayout: (payload) => dispatch({ type: LayoutActionTypes.SET_LAYOUT, payload: payload }),
        setIsMobile: (is_mobile) => dispatch({ type: LayoutActionTypes.SET_IS_MOBILE, payload: {is_mobile: is_mobile} }),
        setTheme: (theme) => dispatch({ type: ThemeActionTypes.SET_THEME, payload: {theme: theme} }),
        setFakeDB: (fake_db) => dispatch({ type: FakeDBDataActionTypes.SET_FAKE_DB_STATE, payload: { fake_db : fake_db } }),
    };
};

// Export App with Redux Wrapper
export default connect(mapStateToProps, mapDispatchToProps)(App);